import axios from 'axios'
const moduleTopic = {
  namespaced: true,
  state: () => {
    return {
      listTopic: [],
      pagination: {},
      detailTopic: {},
      isError: false,
      errMessage: '',
      allTopic: '',
      topicSNPMB: [],
      isErrorSNPMB: false,
      errMsgSNPMB: ''
    }
  },
  mutations: {
    mutationTopic (state, payload) {
      state.listTopic = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailTopic = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setAllTopic (state, payload) {
      state.allTopic = payload
    },
    setTopicSNPMB (state, payload) {
      state.topicSNPMB = payload
    },
    setIsErrorSNPMB (state, payload) {
      state.isErrorSNPMB = payload
    },
    setErrMsgSNPMB (state, payload) {
      state.errMsgSNPMB = payload
    }
  },
  actions: {
    actionTopic (context, data) {
      axios.get(`${context.rootState.setURL}/v1/topic?page=${data.page}&limit=${data.limit}&topic_name=${data.topic_name}&subject_id=${data.subject_id}&category_id=${data.category_id}&class_id=${data.class_id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationTopic', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionTopicSNPMB (context, data) {
      axios.get(`${context.rootState.setURL}/v1/topic?product_type=2&category_id=${data.category_id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setTopicSNPMB', response.data.data)
        context.commit('setIsErrorSNPMB', false)
      }).catch(() => {
        context.commit('setIsErrorSNPMB', true)
        context.commit('setErrMsgSNPMB', 'Data tidak ditemukan.')
      })
    },
    actionAllTopic (context, data) {
      axios.get(`${context.rootState.setURL}/v1/topic`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllTopic', response.data.data)
      }).catch(() => {
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/topic/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        console.log(data)
        axios.post(`${context.rootState.setURL}/v1/topic`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    // Upload image to S3
    uploadImageTopic (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/topicImage`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/topic/${data.id_topic}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/topic/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    },
    deleteImage (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${context.rootState.setURL}/v1/topicImage/${id}`, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    },
    deleteImageURL (context, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/topicImage`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getTopic (state) {
      return state.listTopic
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailTopic
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    },
    getAllTopic (state) {
      return state.allTopic
    },
    getTopicSNPMB (state) {
      return state.topicSNPMB
    },
    isErrorSNPMB (state) {
      return state.isErrorSNPMB
    },
    errMsgSNPMB (state) {
      return state.errMsgSNPMB
    }
  }
}

export default moduleTopic
