<template>
  <div>
    <b-modal
      id="modal-editSubtopic"
      ref="modal-editSubtopic"
      size="lg"
      hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Edit Subtopik</h3>
        <h5 class="fw-bold">Sub-materi</h5>
        <input type="text" class="inputInsert" v-model="getDetail.name">
        <h5 class="fw-bold mt-3">Detail</h5>
        <vue-editor :key="getDetail.id" useCustomImageHandler @image-added="handleImageEdit" v-model="getDetail.detail"></vue-editor>
        <div class="text-center mt-3">
          <button class="btn cancelBtn" @click="cancelEdit()">Batalkan</button>
          <button class="btn editBtn" @click="btnSave()">Simpan</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import imgConvert from '../helper/base64'
import mathJax from '../helper/mathjax'
import alert from '../helper/alert'
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      imgEdit: [],
      serverURL: process.env.VUE_APP_URL
    }
  },
  props: ['idEdit'],
  mixins: [alert, mathJax, imgConvert],
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      getDetail: 'subtopic/getDetail',
      getToken: 'auth/getToken',
      getId: 'auth/getId'
    })
  },
  methods: {
    ...mapActions({
      uploadImageSubtopic: 'subtopic/uploadImageSubtopic',
      actionEdit: 'subtopic/actionEdit',
      actionGetSubtopic: 'subtopic/actionGetSubtopic'
    }),
    subtopic () {
      const data = {
        topic_id: this.idEdit ? this.idEdit : ''
      }
      this.actionGetSubtopic(data)
    },
    async handleImageEdit (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      this.uploadImageSubtopic(data).then(result => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgEdit.push(url)
      }).catch({})
    },
    async cancelEdit () {
      if (this.imgEdit) {
        const images = this.imgEdit
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/subtopicImage`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgEdit = []
      }
      this.hideModal()
    },
    async btnSave () {
      const editImg = this.imgEdit
      const dataImageEdit = editImg.filter((el) => {
        return this.getDetail.detail.includes(el)
      })
      const detailImg = this.getDetail.image.split(',')
      const detailEditImg = detailImg.filter((el) => {
        return this.getDetail.detail.includes(el)
      })
      const data = {
        id: this.getDetail.id,
        name: this.getDetail.name,
        detail: this.getDetail.detail,
        id_updater: this.getId,
        image: dataImageEdit.concat(detailEditImg).join()
      }
      await this.actionEdit(data).then(async (response) => {
        if (this.getDetail.image || this.getDetail.image !== 'image') {
          const qtn = this.getDetail.image.split(',')
          const Imagefiltered = qtn.filter((el) => {
            return !this.getDetail.detail.includes(el)
          })
          Imagefiltered.map(async (el) => {
            if (el) {
              const dataDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/subtopicImage`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        } else {}
        // handleImageEdit
        if (this.imgEdit) {
          const images = this.imgEdit
          const dataFiltered = images.filter((el) => {
            return !this.getDetail.detail.includes(el)
          })
          dataFiltered.map(async (el) => {
            if (el) {
              const imgDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/subtopicImage`,
                method: 'DELETE',
                data: imgDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        }
        this.imgEdit = []
        this.hideModal()
        this.subtopic()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editSubtopic'].hide()
    }
  },
  mounted () {
    this.subtopic()
  }
}
</script>
