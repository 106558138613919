<template>
    <div>
        <b-modal
        id="modal-editShortAnswer"
        ref="modal-editShortAnswer"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Edit Jawaban</h3>
            <h5 class="fw-bold">Jawaban</h5>
            <input v-model="getDetail.short_answer_text" class="inputInsert" cols="52" rows="3" placeholder="Inputkan jawaban" />
            <h5 class="mt-3 fw-bold">Batas Bilangan Atas</h5>
            <input v-model="getDetail.first_range" class="inputInsert" placeholder="Inputkan Batas Bilangan Atas" />
            <h5 class="mt-3 fw-bold">Batas Bilangan Bawah</h5>
            <input v-model="getDetail.second_range" class="inputInsert" placeholder="Inputkan Batas Bilangan Bawah" />
            <div class="text-center mt-3">
                <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                <button class="btn editBtn" @click="btnSave()">Simpan</button>
            </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id'],
  data () {
    return {
    }
  },
  mixins: [alert],
  computed: {
    ...mapGetters({
      getDetail: 'shortAnswer/getDetail'
    })
  },
  methods: {
    ...mapActions({
      actionShortAnswer: 'shortAnswer/actionShortAnswer',
      actionEdit: 'shortAnswer/actionEdit'
    }),
    btnSave () {
      const data = {
        id_short_answer: this.id,
        short_answer_text: this.getDetail.short_answer_text,
        first_range: this.getDetail.first_range,
        second_range: this.getDetail.second_range
      }

      this.actionEdit(data).then((response) => {
        this.hideModal()
        this.actionShortAnswer()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editShortAnswer'].hide()
    }
  }
}
</script>
