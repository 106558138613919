<template>
    <div>
      <div class="container-fluid">
        <div class="row mt-2">
          <div class="col-lg-9 col-md-9 col-sm-9 col-9 mt-1">
            <input class="search" v-model="class_name" @keyup="btnSearch()" type="search" placeholder="Cari Kelas ...">
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-3">
              <componentProfile></componentProfile>
          </div>
        </div>
        <div class="row">
          <h1 class="col-lg-3 col-md-3 col-sm-3 text-white fw-bold mt-3">Kelas</h1>
          <div class="col-lg-5 col-md-4 col-sm-5"></div>
          <div class="col-lg-3 col-md-4 col-sm-3 mt-3 text-end mx-3">
            <button v-b-modal.modal-addClass class="btn btn-add">
              <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Kelas
            </button>
          </div>
        </div>
        <div class="row">
            <div class="col-lg-11 white mt-2">
                <div class="row">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-7 col-md-7 mt-5">
                        <h4 class="fw-bolder grey-font">Semua Kelas</h4>
                        <h5 class="grey-font mt-4">Silahkan memilih Kelas</h5>
                    </div>
                    <div class="col-lg-4 col-md-4 d-none d-xl-block">
                        <img class="img-subjek img-responsive" src="../assets/img/girl.svg" alt="Eureka">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid mt-5 pt-3 text-start">
                <div class="row top-table bg-white" style="width: 100%;">
                    <div class="col-lg-1 col-md-2 col-sm-2 col-3">Kelas</div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-3">Tingkat</div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-3">Dibuat Oleh</div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-4">Diperbaharui Oleh</div>
                    <div class="col-lg-3 col-md-2 col-sm-2 col-2">Aksi</div>
                </div>
            </div>
            <div class="container-fluid text-start mt-3">
                <div class="row bg-white bottom" style="width: 100%;">
                    <div v-if="isErrorClass">{{errMessageClass}}</div>
                    <div v-else>
                        <div v-for="(item, i) in getClass" :key="i">
                            <div class="row my-2">
                                <div class="col-lg-1 col-md-2 col-sm-3 col-3">{{item.class_name}}</div>
                                <div class="col-lg-2 col-md-2 col-sm-3 col-3">{{item.stage}}</div>
                                <div class="col-lg-3 col-md-3 col-sm-2 col-3">{{item.name_creator}}</div>
                                <div class="col-lg-3 col-md-2 col-sm-2 col-3" v-if="!item.name_updater">
                                    -
                                </div>
                                <div v-else class="col-lg-3 col-md-2 col-sm-2 col-2">
                                    {{item.name_updater}}
                                </div>
                                <div class="col-lg-3 col-md-5 col-sm-5">
                                  <button @click="btnEdit(item.id_class)" v-b-modal.modal-editClass class="px-3 btn btn-edit">Edit</button>
                                  <button @click="btnDelete(item.id_class)" v-if="getDetailAcc.id_level === 1" class="px-2 btn mx-3 btn-delete">Hapus</button>
                                  <div v-if="getDetailAcc.id_level === 2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isErrorClass">
              <b-pagination
                align="right"
                class="mt-3 mx-2 pagination"
                @input="kelas()"
                v-model="getPaginationClass.page"
                pills
                :total-rows="getPaginationClass.totalData"
                :per-page="getPaginationClass.limit"
                size="sm"
              >
              </b-pagination>
            </div>
            <div v-else>
                <b-pagination
                    align="right"
                    class="mt-3 pagination"
                    pills
                    total-rows="1"
                    per-page="1"
                    size="sm"
                >
                </b-pagination>
            </div>
        </div>
      </div>
      <!-- Modal Insert Class -->
      <b-modal
      id="modal-addClass"
      ref="modal-addClass"
      hide-header-close hide-footer hide-header centered>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Kelas</h3>
        <h5 class="fw-bold">Kelas</h5>
        <input class="inputText" v-model="form.class_name" type="text" placeholder="Tambahkan Kelas">
        <h5 class="fw-bold mt-4">Tingkat Sekolah</h5>
        <select
          v-model="form.stage"
          class="form-select form-select-md insertSelect" required
          >
          <option value="SD">SD</option>
          <option value="SMP">SMP</option>
          <option value="SMA">SMA</option>
        </select>
        <button class="btn cancelBtn" @click="hideModal()">Cancel</button>
        <button class="btn editBtn" @click="btnInsert()">Save</button>
      </div>
      </b-modal>
      <!-- Modal Edit Class -->
      <b-modal id="modal-editClass"
      ref="modal-editClass"
      hide-header-close hide-footer hide-header centered>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Edit Kelas</h3>
        <h5 class="fw-bold">Kelas</h5>
        <input class="inputText" v-model="getDetail.class_name" type="text">
        <button class="btn cancelBtn" @click="hideModal()">Cancel</button>
        <button class="btn editBtn" @click="btnSave()">Save</button>
      </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import componentProfile from './Profile.vue'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
export default {
  data () {
    return {
      class_name: '',
      form: {
        class_name: ''
      }
    }
  },
  mixins: [alert],
  components: {
    componentProfile
  },
  computed: {
    ...mapGetters({
      getClass: 'class/getClass',
      getPaginationClass: 'class/getPaginationClass',
      isErrorClass: 'class/isErrorClass',
      errMessageClass: 'class/errMessageClass',
      getDetailAcc: 'account/getDetailAcc',
      getId: 'auth/getId',
      getDetail: 'class/getDetail'
    })
  },
  methods: {
    ...mapActions({
      actionClass: 'class/actionClass',
      actionDetailAcc: 'account/actionDetail',
      actionInsert: 'class/actionInsert',
      actionDelete: 'class/actionDelete',
      actionDetail: 'class/actionDetail',
      actionEdit: 'class/actionEdit'
    }),
    kelas () {
      const data = {
        page: this.getPaginationClass.page ? this.getPaginationClass.page : 1,
        class_name: this.class_name ? this.class_name : '',
        limit: 5
      }
      this.actionClass(data)
    },
    btnSearch () {
      const data = {
        page: this.getPaginationClass.page ? this.getPaginationClass.page : 1,
        class_name: this.class_name ? this.class_name : '',
        limit: 5
      }
      this.actionClass(data)
    },
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    btnInsert () {
      const data = {
        class_name: this.form.class_name,
        stage: this.form.stage,
        id_class_creator: this.getId
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.kelas()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch(() => {})
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.kelas()
          }).catch(() => {})
        }
      })
    },
    btnEdit (id) {
      this.actionDetail(id)
    },
    btnSave () {
      const data = {
        id_class: this.getDetail.id_class,
        class_name: this.getDetail.class_name,
        id_class_updater: this.getId
      }
      this.actionEdit(data).then((response) => {
        this.ToastSuccess('Data berhasil diperbaharui.')
        this.hideModal()
        this.kelas()
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editClass'].hide()
      this.$refs['modal-addClass'].hide()
      this.form.class_name = ''
      this.form.stage = ''
    }
  },
  mounted () {
    this.kelas()
  }
}
</script>

<style scoped>
.bottom{
    border-radius: 10px;
}
select{
  background: url('../assets/img/polygon.png') no-repeat right white;
  background-size: 10px 8px;
  -webkit-appearance: none;
  background-position-x: 90%;
  border: 2px solid rgba(0, 0, 0, 0.65);
  border-radius: 7px;
  padding: 5px 5px 5px 10px;
  width: 90%;
  margin: 5px 0px;
  height: 39px;
}
</style>
