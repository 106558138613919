import Vue from 'vue'
import Vuex from 'vuex'
import moduleAuth from './modules/auth'
import moduleAccount from './modules/account'
import moduleTopic from './modules/topic'
import moduleSubject from './modules/subject'
import moduleClass from './modules/class'
import moduleCategory from './modules/category'
import moduleQuestion from './modules/question'
import moduleDiscussion from './modules/discussion'
import moduleKeyword from './modules/keyword'
import moduleSelection from './modules/selection'
import moduleAnswer from './modules/answer'
import moduleShortAnswer from './modules/shortAnswer'
import moduleQuestionImage from './modules/questionImage'
import moduleQuestionLevel from './modules/questionLevel'
import moduleGroupChild from './modules/groupChild'
import moduleGroupParent from './modules/groupParent'
import moduleBanner from './modules/banner'
import moduleIndicator from './modules/indicator'
import moduleSubtopic from './modules/subtopic'
import moduleFreeTrial from './modules/freeTrial'
import moduleQuestionSNPMB from './modules/SNPMB-question'
import moduleStatementAnswer from './modules/statement-answer'
import moduleStatementQuestion from './modules/statement-question'

Vue.use(Vuex)

const store = new Vuex.Store({
  state () {
    return {
      setURL: process.env.VUE_APP_URL
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: moduleAuth,
    account: moduleAccount,
    topic: moduleTopic,
    subject: moduleSubject,
    class: moduleClass,
    category: moduleCategory,
    question: moduleQuestion,
    discussion: moduleDiscussion,
    keyword: moduleKeyword,
    selection: moduleSelection,
    answer: moduleAnswer,
    shortAnswer: moduleShortAnswer,
    questionImage: moduleQuestionImage,
    questionLevel: moduleQuestionLevel,
    groupChild: moduleGroupChild,
    groupParent: moduleGroupParent,
    banner: moduleBanner,
    indicator: moduleIndicator,
    subtopic: moduleSubtopic,
    freeTrial: moduleFreeTrial,
    questionSNPMB: moduleQuestionSNPMB,
    statementAnswer: moduleStatementAnswer,
    statementQuestion: moduleStatementQuestion
  }
})

export default store
