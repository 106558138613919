<template>
  <div>
    <b-modal
      id="modal-editBanner"
      ref="modal-editBanner"
      hide-header-close
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Edit Banner</h3>
        <h5 class="fw-bold mt-3 mb-1">Deskripsi</h5>
        <textarea
          v-model="getDetail.description"
          cols="25"
          rows="3"
          class="inputInsert"
          ></textarea>
        <h5 class="fw-bold mt-2 mb-1">Link</h5>
        <input
          v-model="getDetail.target"
          class="inputInsert"
          type="text" />
        <h5 class="fw-bold mt-3 mb-2">Gambar</h5>
        <input
          @change="updateFile($event)"
          type="file"
          class="form-control fileBtn"
          accept="image/png, image/jpg, image/jpeg"
        />
        <div class="text-center mt-3">
          <button @click="hideModal()" class="btn cancelBtn">Batalkan</button>
          <button @click="btnSave()" class="btn editBtn">Simpan</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  mixins: [alert],
  props: ['id'],
  data () {
    return {
      updateImage: '',
      pathValue: ''
    }
  },
  computed: {
    ...mapGetters({
      getDetail: 'banner/getDetail',
      getPagination: 'banner/getPagination'
    })
  },
  methods: {
    ...mapActions({
      actionBanner: 'banner/actionBanner',
      actionEdit: 'banner/actionEdit'
    }),
    updateFile (el) {
      this.pathValue = el.target.value
      this.updateImage = el.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.updateImage)
      reader.onload = () => {
        this.updateImage = reader.result
      }
    },
    banner () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1
      }
      this.actionBanner(data)
    },
    btnSave () {
      const data = {
        id: this.id,
        description: this.getDetail.description,
        target: this.getDetail.target
      }
      if (!this.pathValue) {
      } else {
        data.image = this.updateImage
        this.pathValue = ''
      }
      this.actionEdit(data).then(() => {
        this.hideModal()
        this.banner()
        this.ToastSuccess('Data berhasil diperbaharui')
      }).catch({})
    },
    hideModal () {
      this.$refs['modal-editBanner'].hide()
    }
  }
}
</script>
