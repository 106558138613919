<template>
  <div>
    <componentNavbar/>
  </div>
</template>

<script>
import componentNavbar from '../components/Navbar.vue'
export default {
  components: {
    componentNavbar
  }
}
</script>
