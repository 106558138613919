<template>
    <div>
        <b-modal
        id="modal-addQuestionImageChild"
        ref="modal-addQuestionImageChild"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Gambar</h3>
            <h5 class="fw-bold mt-3 mb-3">Gambar</h5>
                <input
                  @change="uploadFile($event)"
                  type="file"
                  class="form-control fileBtn"
                  accept="image/png, image/jpg, image/jpeg"
                />
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id'],
  mixins: [alert],
  data () {
    return {
      form: {
        image: ''
      }
    }
  },
  methods: {
    ...mapActions({
      actionInsert: 'questionImage/actionInsert',
      actionQuestionImage: 'questionImage/actionQuestionImage'
    }),
    uploadFile (el) {
      this.form.image = el.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.form.image = reader.result
      }
    },
    btnInsert () {
      const data = {
        image: this.form.image,
        question_id: this.id
      }

      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.actionQuestionImage()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    hideModal () {
      this.$refs['modal-addQuestionImageChild'].hide()
      this.form = {
        image: ''
      }
    }
  },
  mounted () {
    this.actionQuestionImage()
  }
}
</script>
