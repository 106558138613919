<template>
    <div>
        <b-modal
        id="modal-editAnswerEssayChild"
        ref="modal-editAnswerEssayChild"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Edit Jawaban</h3>
            <h5 class="fw-bold">Jawaban</h5>
            <textarea class="inputInsert" v-model="getDetail.answerEssay" cols="52" rows="5" placeholder="Inputkan jawaban"></textarea>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id', 'idEssayEdit'],
  mixins: [alert],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      getPagination: 'question/getPagination',
      getDetail: 'question/getDetail',
      getId: 'auth/getId'
    })
  },
  methods: {
    ...mapActions({
      actionEdit: 'question/actionEdit',
      actionQuestionChild: 'question/actionQuestionChild'
    }),
    btnInsert () {
      const data = {
        id_question: this.id,
        qt_id: this.getDetail.qt_id,
        question_text: this.getDetail.question_text,
        subject_id: this.getDetail.subject_id,
        class_id: this.getDetail.class_id,
        category_id: this.getDetail.category_id,
        topic_id: this.getDetail.topic_id,
        ql_id: this.getDetail.id_qtn_level,
        answer: this.getDetail.answerEssay,
        id_question_updater: this.getId
      }
      this.actionEdit(data).then((response) => {
        this.hideModal()
        this.question()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    question () {
      this.actionQuestionChild(this.idEssayEdit)
    },
    hideModal () {
      this.$refs['modal-editAnswerEssayChild'].hide()
    }
  }
}
</script>
