<template>
    <div>
        <b-modal
        id="modal-addAnswerEssayChild"
        ref="modal-addAnswerEssayChild"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Jawaban</h3>
            <h5 class="fw-bold">Jawaban</h5>
            <textarea class="inputInsert" v-model="form.answer" cols="52" rows="5" placeholder="Inputkan jawaban"></textarea>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id', 'idQuestionEssay'],
  mixins: [alert],
  data () {
    return {
      form: {
        answer: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      getPagination: 'question/getPagination',
      getDetail: 'question/getDetail'
    })
  },
  methods: {
    ...mapActions({
      actionEdit: 'question/actionEdit',
      actionQuestion: 'question/actionQuestion',
      actionQuestionChild: 'question/actionQuestionChild'
    }),
    btnInsert () {
      const data = {
        id_question: this.id,
        qt_id: this.getDetail.qt_id,
        question_text: this.getDetail.question_text,
        subject_id: this.getDetail.subject_id,
        class_id: this.getDetail.class_id,
        category_id: this.getDetail.category_id,
        topic_id: this.getDetail.topic_id,
        ql_id: this.getDetail.id_qtn_level,
        answer: this.form.answer
      }
      this.actionEdit(data).then((response) => {
        this.hideModal()
        this.question()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    question () {
      this.actionQuestionChild(this.idQuestionEssay)
    },
    hideModal () {
      this.$refs['modal-addAnswerEssayChild'].hide()
      this.form = {
        answer: ''
      }
    }
  }
}
</script>
