<template>
    <div class="text-end">
        <div class="fw-bold">{{getName}}</div>{{getDetailAcc.level_name}}
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      getName: 'auth/getName',
      getDetailAcc: 'account/getDetailAcc',
      getId: 'auth/getId'
    })
  },
  methods: {
    ...mapActions({
      actionAccount: 'account/actionAccount',
      actionEdit: 'account/actionEdit',
      actionDetail: 'account/actionDetail'
    }),
    detail () {
      this.actionDetail(this.getId)
    }
  },
  mounted () {
    this.detail()
  }
}
</script>
