import axios from 'axios'
const moduleQuestionLevel = {
  namespaced: true,
  state: () => {
    return {
      listQtnLevel: []
    }
  },
  mutations: {
    mutationQtnLevel (state, payload) {
      state.listQtnLevel = payload
    }
  },
  actions: {
    actionQtnLevel (context, data) {
      axios.get(`${context.rootState.setURL}/v1/question-level`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationQtnLevel', response.data.data)
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/question-level`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    }
  },
  getters: {
    getQtnLevel (state) {
      return state.listQtnLevel
    }
  }
}

export default moduleQuestionLevel
