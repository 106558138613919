<template>
    <div>
      <div class="container-fluid text-end">
        <button @click="$router.go(-1)" class="btn btn-back mt-4">
          <b-icon icon="arrow-left-circle-fill" style="margin-right: 7px;"></b-icon>Kembali
        </button><br>
        <button @click="btnDuplicate()" v-b-modal.modal-duplicate class="btn btn-add mx-3" style="width: auto;">Duplikat</button>
    </div>
    <div class="bg-white rounded p-2 grey m-4" v-if="isErrorQuestionChild">Tambahkan / duplikat soal</div>
    <!-- Show Content -->
    <div v-else>
      <div v-for="(item, index) in getQuestionChild" :key="'getQuestionChild'+index" class="container-fluid">
        <!-- Grouping Questions -->
        <div class="row mt-3 mb-5 bg-white content" style="margin: 0px;">
            <div class="row mb-5 pb-4">
              <div class="row col-lg-10">
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Kode Soal :</div> {{item.code}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Indikator :</div> {{item.indicator_name}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Subjek :</div> {{item.subject_name}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Kelas :</div> {{item.class_name}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Kategori :</div> {{item.category_name}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Materi :</div> {{item.topic_name}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Sub-materi :</div> {{item.subtopic_name}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Level :</div> {{item.qtn_level_name}}
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                    <div class="mb-2">Label :</div>
                    <div v-if="item.label === 'tryout'">Tryout</div>
                    <div v-if="item.label === 'exam'">Exam</div>
                    <div v-if="item.label === 'daily_question'">Daily Question</div>
                  </div>
                  <div class="col-lg-2 col-md-2 grey">
                      <div class="mb-2">Status :</div>
                      <div class="text-danger" v-if="item.status === 'on-progress'">On Progress</div>
                      <div class="text-success" v-if="item.status === 'approved'">Approved</div>
                      <div class="text-primary" v-if="item.status === 'published'">Published</div>
                  </div>
              </div>
              <div class="row col-lg-2">
                  <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                      <button @click="btnEdit(item.id_question)" v-b-modal.modal-editChild class="btn btn-edit my-2" style="width: 75px;">Edit</button><br>
                      <!-- <button @click="btnDelete(item.id_question)" v-if="getDetailAcc.id_level === 1" class="btn btn-delete" style="width: 75px;">Hapus</button> -->
                      <a :href="$router.resolve({ name: 'Pembahasan', params: {idSoal: item.id_question } }).href">
                        <button class=" btn btn-discuss" style="width: 120px;">Pembahasan</button>
                      </a>
                  </div>
              </div>
                <div class="row col-lg-9">
                    <hr class="line">
                </div>
                <h5 class="text-justify my-3 grey whitespace htmlText" v-html="item.question_text">
                  <!-- <vue-mathjax :formula="item.question_text"></vue-mathjax> -->
                </h5>
                <!-- <div class="text-end my-2">
                  <button @click="addQuestionImage(item.id_question)" v-b-modal.modal-addQuestionImageChild style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                    <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Gambar
                  </button>
                </div> -->
                <!-- Image Content -->
                <!-- <div v-for="(data, i) in getQuestionImage" :key="'questionImage'+i">
                  <div v-if="item.id_question === data.question_id">
                    <div v-if="isErrorQuestionImage"></div>
                    <div v-else>
                      <div class="text-center">
                          <img :src="`${data.image}`" class="img-fluid image my-2" /><br>
                          <button @click="btnDeleteQuestionImage(data.id)" class="btn deleteImg mb-4">
                            Hapus Gambar</button>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- Selection (Multiple Choice) -->
                <div v-if="item.qt_id === 2">
                  <div class="row mb-4">
                    <h5 class="col-lg-9 col-md-7 col-sm-5 col-5 mt-3 grey">Pilihan :</h5>
                    <div class="col-lg-3 col-md-5 col-sm-3 col-3 mt-2 text-end">
                      <button @click="addSelection(item.id_question)" v-b-modal.modal-addSelectionChild style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                        <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Pilihan
                      </button>
                    </div>
                  </div>
                  <div v-if="isErrorSelection"></div>
                  <div v-else>
                    <div v-for="(el, i) in getSelection" :key="i" class="text-justify grey">
                      <div v-if="item.id_question === el.question_id" class="row">
                        <div class="col-lg-10 col-md-10 col-sm-9 col-9 whitespace">
                          <ul>
                          <li>
                            <vue-mathjax :formula="el.selection_text"></vue-mathjax>
                            <div v-if="!el.image || el.image === 'image'"></div>
                            <div v-else class="text-center">
                              <img :src="`${el.image}`" class="img-fluid image my-2" /><br>
                              <button @click="btnDeleteSelectionImage(el.id_selection)" class="btn deleteImg mb-4">
                                Hapus Gambar</button>
                            </div>
                          </li>
                          </ul>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-3 col-1 text-end">
                          <b-icon @click="editSelection(el.id_selection)" v-b-modal.modal-editSelectionChild class="pencilIcon" icon="pencil-square"></b-icon>
                          <b-icon @click="btnDeleteSelection(el.id_selection)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <h5 class="col-lg-8 col-md-8 col-sm-8 col-8 mt-3 grey">Jawaban :</h5>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-4 mt-2 text-end">
                      <button @click="addAnswer(item.id_question)" v-b-modal.modal-addAnswerChild style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                        <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Jawaban
                      </button>
                    </div>
                  </div>
                  <!-- Answer from Multiple Choice Question -->
                    <div v-if="isErrorAnswer"></div>
                    <div v-else>
                      <div v-for="(ans, i) in getAnswer" :key="'answer'+i">
                        <div v-if="item.id_question === ans.question_id" class="row">
                          <div class="col-lg-10 whitespace grey">
                          <ul>
                          <li><vue-mathjax :formula="ans.answer_text"></vue-mathjax>
                            <div v-if="!ans.image || ans.image === 'image'"></div>
                            <div v-else class="text-center">
                              <img :src="`${ans.image}`" class="img-fluid image my-2" />
                            </div>
                          </li>
                          </ul>
                        </div>
                        <div class="col-lg-2 text-end">
                          <b-icon @click="btnDeleteAnswer(ans.id_answer)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>
                <!-- Keyword (Essay) -->
                <div v-if="item.qt_id === 1">
                <div class="row mb-4">
                    <h5 class="col-lg-8 mt-3 grey">Jawaban :</h5>
                    <div class="col-lg-4 mt-2 text-end">
                      <div v-if="!item.answer">
                          <button @click="addAnswerEssay(item.id_question)" v-b-modal.modal-addAnswerEssayChild style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                            <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Jawaban
                          </button>
                        </div>
                        <div v-else>
                          <button @click="editAnswerEssay(item.id_question)" v-b-modal.modal-editAnswerEssayChild style="border: 1px solid rgb(72, 167, 245); color: rgb(72, 167, 245);" class="btn btn-add">
                            <b-icon class="pencilIcon mx-2" icon="pencil-square"></b-icon>Edit Jawaban
                          </button>
                        </div>
                    </div>
                  </div>
                <div class="grey whitespace my-3"><vue-mathjax :formula="item.answer"></vue-mathjax></div>
                  <div class="row mb-4">
                    <h5 class="col-lg-8 mt-3 grey">Keyword :</h5>
                    <div class="col-lg-4 mt-2 text-end">
                      <button @click="addKeyword(item.id_question)" v-b-modal.modal-addKeywordChild style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                        <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Keyword
                      </button>
                    </div>
                  </div>
                  <div v-if="isErrorKeyword"></div>
                  <div v-else>
                    <div v-for="(el, i) in getKeyword" :key="i" class="text-justify grey">
                      <div v-if="item.id_question === el.question_id" class="row">
                        <div class="col-lg-10 whitespace">
                          <ul>
                          <li><vue-mathjax :formula="el.keyword_text"></vue-mathjax></li>
                          </ul>
                        </div>
                        <div class="col-lg-2">
                          <b-icon @click="editKeyword(el.id_keyword)" v-b-modal.modal-editKeywordChild class="pencilIcon" icon="pencil-square"></b-icon>
                          <b-icon @click="btnDeleteKeyword(el.id_keyword)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Short Answer -->
                <div v-if="item.qt_id === 3">
                  <div class="row mb-4">
                    <h5 class="col-lg-8 mt-3 grey">Jawaban :</h5>
                    <div class="col-lg-4 mt-2 text-end">
                      <button @click="addShortAnswer(item.id_question)" v-b-modal.modal-addShortAnswerChild style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                        <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Jawaban
                      </button>
                    </div>
                  </div>
                  <div v-if="isErrorShortAnswer"></div>
                  <div v-else>
                    <div v-for="(el, i) in getShortAnswer" :key="'shortAnswer'+i" class="text-justify grey">
                      <div v-if="item.id_question === el.question_id" class="row">
                        <div class="col-lg-10 whitespace"><vue-mathjax :formula="el.short_answer_text"></vue-mathjax></div>
                        <div class="col-lg-2">
                          <b-icon @click="editShortAnswer(el.id_short_answer)" v-b-modal.modal-editShortAnswerChild class="pencilIcon" icon="pencil-square"></b-icon>
                          <b-icon @click="btnDeleteShortAnswer(el.id_short_answer)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                        </div>
                        <h5 class="grey my-4">Batas Bilangan Atas</h5>
                        <div><vue-mathjax :formula="el.first_range"></vue-mathjax></div>
                        <h5 class="grey my-4">Batas Bilangan Bawah</h5>
                        <div><vue-mathjax :formula="el.second_range"></vue-mathjax></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End -->
                <div class="grey mt-3 mb-2">Dibuat Oleh : {{item.name_creator}}</div>
                <div v-if="!item.name_updater" class="grey">Diperbaharui Oleh : -</div>
                <div v-else class="grey">Diperbaharui Oleh : {{item.name_updater}}</div>
            </div>
        </div>
      </div>
    </div>
    <!-- Components -->
      <modalAddDuplicate :idParamsURL="id" />
      <modalEditSoalChild :idQuestionEdit="id" :idDetail="idEditQuestion"/>
      <modalAddAnswerEssayChild :id="idQuestionEssay" :idQuestionEssay="id" />
      <modalEditAnswerEssayChild :id="idAnswerEssay" :idEssayEdit="id"/>
      <modelAddKeywordChild :id="idQuestionKeyword" />
      <modalEditKeywordChild />
      <modalAddSelectionChild :id="idQuestion" />
      <modalEditSelectionChild />
      <modalAddAnswerChild :id="idQuestionAnswer" />
      <modalAddShortAnswerChild :id="idShortAnswer" />
      <modalEditShortAnswerChild :id="idShortAnswerEdit" />
      <modalAddQuestionImageChild :id="idQuestionImage" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalAddDuplicate from './m-addDuplicate.vue'
import modalEditSoalChild from './m-editSoalChild.vue'
import modalAddAnswerEssayChild from './m-addAnswerEssayChild.vue'
import modalEditAnswerEssayChild from './m-editAnswerEssayChild.vue'
import modelAddKeywordChild from './m-addKeywordChild.vue'
import modalEditKeywordChild from './m-editKeywordChild.vue'
import modalAddSelectionChild from './m-addSelectionChild.vue'
import modalEditSelectionChild from './m-editSelectionChild.vue'
import modalAddAnswerChild from './m-addAnswerChild.vue'
import modalAddShortAnswerChild from './m-addShortAnswerChild.vue'
import modalEditShortAnswerChild from './m-editShortAnswerChild.vue'
import modalAddQuestionImageChild from './m-addQuestionImageChild.vue'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
export default {
  data () {
    return {
      id: '',
      idQuestionEssay: '',
      idAnswerEssay: '',
      idQuestionKeyword: '',
      idQuestion: '',
      idQuestionAnswer: '',
      idShortAnswer: '',
      idShortAnswerEdit: '',
      idQuestionImage: '',
      idQuestionEdit: '',
      idEssayEdit: '',
      idEditQuestion: ''
    }
  },
  mixins: [alert, mathJax],
  components: {
    modalAddDuplicate,
    modalEditSoalChild,
    modalAddAnswerEssayChild,
    modalEditAnswerEssayChild,
    modelAddKeywordChild,
    modalEditKeywordChild,
    modalAddSelectionChild,
    modalEditSelectionChild,
    modalAddAnswerChild,
    modalAddShortAnswerChild,
    modalEditShortAnswerChild,
    modalAddQuestionImageChild
  },
  computed: {
    ...mapGetters({
      isErrorQuestionChild: 'question/isErrorQuestionChild',
      getId: 'auth/getId',
      getDetailAcc: 'account/getDetailAcc',
      getAllSubject: 'subject/getAllSubject',
      getAllClass: 'class/getAllClass',
      getAllCategory: 'category/getAllCategory',
      getAllTopic: 'topic/getAllTopic',
      getSelection: 'selection/getSelection',
      isErrorSelection: 'selection/isError',
      getKeyword: 'keyword/getKeyword',
      isErrorKeyword: 'keyword/isError',
      getAnswer: 'answer/getAnswer',
      isErrorAnswer: 'answer/isError',
      getShortAnswer: 'shortAnswer/getShortAnswer',
      isErrorShortAnswer: 'shortAnswer/isError',
      getQuestionImage: 'questionImage/getQuestionImage',
      isErrorQuestionImage: 'questionImage/isError',
      getQtnLevel: 'questionLevel/getQtnLevel',
      getQuestionChild: 'question/getQuestionChild'
    })
  },
  methods: {
    ...mapActions({
      actionDetail: 'question/actionDetail',
      actionQuestionImageId: 'questionImage/actionQuestionImageId',
      actionQuestionImage: 'questionImage/actionQuestionImage',
      actionDetailAcc: 'account/actionDetail',
      allSubject: 'subject/allSubject',
      actAllClass: 'class/actAllClass',
      actAllCategory: 'category/actAllCategory',
      actionAllTopic: 'topic/actionAllTopic',
      actionDeleteQuestionImage: 'questionImage/actionDelete',
      actionKeyword: 'keyword/actionKeyword',
      actionDetailKeyword: 'keyword/actionDetail',
      actionDeleteKeyword: 'keyword/actionDelete',
      actionSelection: 'selection/actionSelection',
      actionDetailSelection: 'selection/actionDetail',
      actionDeleteSelection: 'selection/actionDelete',
      actionDeleteSelectionImage: 'selection/deleteImage',
      actionAnswer: 'answer/actionAnswer',
      actionSearchSelection: 'selection/actionSearchSelection',
      actionDeleteAnswer: 'answer/actionDelete',
      actionShortAnswer: 'shortAnswer/actionShortAnswer',
      actionDetailShortAnswer: 'shortAnswer/actionDetail',
      actionDeleteShortAnswer: 'shortAnswer/actionDelete',
      actionQuestionChild: 'question/actionQuestionChild',
      actionSubtopic: 'subtopic/actionSubtopic'
    }),
    subtopic () {
      const data = {
        topic_id: ''
      }
      this.actionSubtopic(data)
    },
    btnDuplicate () {
      this.actionDetail(this.id)
      this.actionQuestionImageId(this.id)
      this.idParamsURL = this.id
      this.subtopic()
    },
    questionChild () {
      this.actionQuestionChild(this.id)
    },
    keyword () {
      this.actionKeyword()
    },
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    btnEdit (id) {
      this.actionDetail(id)
      this.idEditQuestion = id
      this.actionQuestionImageId(id)
      this.subtopic()
    },
    questionImage () {
      this.actionQuestionImage()
    },
    addAnswerEssay (id) {
      this.idQuestionEssay = id
      this.actionDetail(id)
    },
    editAnswerEssay (id) {
      this.actionDetail(id)
      this.idAnswerEssay = id
    },
    addKeyword (id) {
      this.idQuestionKeyword = id
    },
    editKeyword (id) {
      this.actionDetailKeyword(id)
    },
    selection () {
      this.actionSelection()
    },
    addSelection (id) {
      this.idQuestion = id
    },
    editSelection (id) {
      this.actionDetailSelection(id)
    },
    answer () {
      this.actionAnswer()
    },
    addAnswer (id) {
      this.idQuestionAnswer = id
      this.actionSearchSelection(id)
    },
    shortAnswer () {
      this.actionShortAnswer()
    },
    addShortAnswer (id) {
      this.idShortAnswer = id
    },
    editShortAnswer (id) {
      this.actionDetailShortAnswer(id)
      this.idShortAnswerEdit = id
    },
    addQuestionImage (id) {
      this.idQuestionImage = id
    },
    btnDeleteShortAnswer (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteShortAnswer(id).then((response) => {
            this.shortAnswer()
          }).catch(() => {})
        }
      })
    },
    btnDeleteAnswer (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteAnswer(id).then((response) => {
            this.answer()
          }).catch(() => {})
        }
      })
    },
    btnDeleteSelection (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteSelection(id).then((response) => {
            this.answer()
            this.selection()
          }).catch(() => {})
          this.answer()
        }
      })
    },
    btnDeleteSelectionImage (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus gambar ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Gambar anda telah dihapus.')
          this.actionDeleteSelectionImage(id).then((response) => {
            this.selection()
            this.answer()
          }).catch(() => {})
        }
      })
    },
    btnDeleteKeyword (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteKeyword(id).then((response) => {
            this.keyword()
          }).catch(() => {})
        }
      })
    },
    btnDeleteQuestionImage (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus gambar ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Gambar anda telah dihapus.')
          this.actionDeleteQuestionImage(id).then((response) => {
            this.questionImage()
          }).catch(() => {})
        }
      })
    }
  },
  watch: {
    getQuestionChild () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getKeyword () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getSelection () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getAnswer () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getShortAnswer () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    this.id = Number(this.$route.params.id)
    this.questionChild()
    this.allSubject()
    this.actAllClass()
    this.actAllCategory()
    this.actionAllTopic()
    this.keyword()
    this.selection()
    this.shortAnswer()
    this.subtopic()
  }
}
</script>

<style scoped>
.htmlText >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
  height: auto;
  border-radius: 7px;
}
.htmlText >>> {
  white-space: pre-wrap;
}
</style>
