<template>
  <div>
    <div class="container-fluid">
      <!-- Profile -->
      <div class="row text-end mt-3">
        <componentProfile></componentProfile>
      </div>
      <!-- Insert Banner -->
      <div class="row mt-1">
        <div class="d-flex justify-content-between">
          <h1 class="text-white fw-bold">Banner</h1>
          <button v-b-modal.modal-addBanner class="btn btn-add mt-3 mx-4">
            <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Banner
          </button>
        </div>
      </div>
      <!-- Banner Content -->
      <div class="row my-4">
        <div class="bg-white rounded p-2 my-2 grey" v-if="isError">Data tidak ditemukan.</div>
        <div v-else v-for="(item, i) in getBanner" :key="i" class="col-lg-4 col-md-4 col-sm-6 col-6">
            <b-card
                :title="item.description"
                :img-src="item.image"
                img-alt="Banner"
                img-top
                img-height="200px"
                img-width="200px"
                style="max-width: 20rem;"
                class="mb-5"
            >
            <b-card-text>
              <div class="row">
                <div class="col-lg-9">{{item.target}}</div>
                <div class="col-lg-3 d-flex justify-content-between">
                  <b-icon @click="editBanner(item.id)" v-b-modal.modal-editBanner class="pencilIcon" icon="pencil-square"></b-icon>
                  <b-icon @click="btnDeleteBanner(item.id)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                </div>
              </div>
            </b-card-text>
            </b-card>
        </div>
        <div v-if="!isError">
          <b-pagination
            align="right"
            class="mt-3 mx-2 pagination"
            @input="banner()"
            v-model="getPagination.page"
            pills
            :total-rows="getPagination.totalData"
            :per-page="getPagination.limit"
            size="sm"
          >
          </b-pagination>
        </div>
        <div v-else>
          <b-pagination
            align="right"
            class="mt-3 pagination"
            pills
            total-rows="1"
            per-page="1"
            size="sm"
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <modalAddBanner />
    <modalEditBanner :id="idEdit" />
  </div>
</template>

<script>
import componentProfile from './Profile.vue'
import modalAddBanner from './m-addBanner.vue'
import modalEditBanner from './m-editBanner.vue'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
export default {
  data () {
    return {
      idEdit: ''
    }
  },
  mixins: [alert],
  components: {
    componentProfile,
    modalAddBanner,
    modalEditBanner
  },
  computed: {
    ...mapGetters({
      getBanner: 'banner/getBanner',
      isError: 'banner/isError',
      getPagination: 'banner/getPagination'
    })
  },
  methods: {
    ...mapActions({
      actionBanner: 'banner/actionBanner',
      actionDetail: 'banner/actionDetail',
      actionDelete: 'banner/actionDelete'
    }),
    banner () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1
      }
      this.actionBanner(data)
    },
    editBanner (id) {
      this.actionDetail(id)
      this.idEdit = id
    },
    btnDeleteBanner (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.banner()
          }).catch({})
        }
      })
    }
  },
  mounted () {
    this.banner()
  }
}
</script>
