<template>
    <div>
        <img class="img-fluid title mt-3 mx-3" src="../assets/img/logo2.png" alt="Eureka">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 col-md-5 col-sm-5">
                    <img class="img-responsive img-fluid frontTeam mt-5 pt-4 mx-4" src="../assets/img/analyticsTeam.svg" alt="Register">
                </div>
                <div class="col-lg-2 col-md-1 col-sm-1"></div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="sub-title mb-3">Hello, <br> Welcome back</div>
                    <!-- Form Register -->
                    <form @submit.prevent="onSubmitRegister()">
                        <input
                        v-model="ac_name"
                        type="name"
                        class="form-control my-4"
                        placeholder="Name" required
                        />
                        <select
                            v-model="ac_level"
                            required
                        >
                            <option disabled value="">Role</option>
                            <option value="2">Team Content</option> <!-- 3 karena harus tunggu konfirmasi admin -->
                        </select>
                        <input
                        v-model="ac_email"
                        type="email"
                        class="form-control my-4"
                        placeholder="Email" required
                        />
                        <input
                        v-model="ac_password"
                        type="password"
                        class="form-control my-3"
                        placeholder="Password" required
                        />
                        <button type="submit" class="btn register-btn mt-3">Sign Up</button>
                        <div class="text-center my-3">
                            <router-link to="/login" class="link">
                            Do you have an account? <u>Click here!</u>
                            </router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import alert from '../helper/alert'
export default {
  mixins: [alert],
  data () {
    return {
      ac_name: '',
      ac_level: '',
      ac_email: '',
      ac_password: ''
    }
  },
  methods: {
    ...mapActions({
      actionRegister: 'auth/register'
    }),
    onSubmitRegister () {
      const data = {
        ac_name: this.ac_name,
        ac_level: this.ac_level,
        ac_email: this.ac_email,
        ac_password: this.ac_password
      }
      this.actionRegister(data).then((response) => {
        this.$router.push('/login')
      }).catch((err) => {
        this.AlertError(err)
      })
    }
  }
}
</script>

<style scoped>
img.title{
    height: 90px;
    width: 200px;
}
.title{
    font-size: 38px;
    font-weight: bold;
}
.sub-title{
    font-weight: bold;
    font-size: 30px;
}
.register-btn{
    background: #2CA3F5;
    width: 100%;
    height: 60px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 25px;
    border-radius: 10px;
}
.link{
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    font-size: 15px;
}
.link:hover{
    font-size: 16px;
}
select{
    border: 2px solid #000000;
    border-radius: 10px;
    font-weight: bold;
    width: 100%;
    height: 40px;
    padding-left: 8px;
    /*  */
    background: url('../assets/img/polygon.png') no-repeat right white;
    background-size: 12px 12px;
    -webkit-appearance: none;
    background-position-x: 96%;
}
input{
    border: 2px solid #000000;
    border-radius: 10px;
    font-weight: bold;
}
input::-webkit-input-placeholder{
    font-weight: lighter;
}
</style>
