import { render, staticRenderFns } from "./c-Pembahasan.vue?vue&type=template&id=13dab96a&scoped=true&"
import script from "./c-Pembahasan.vue?vue&type=script&lang=js&"
export * from "./c-Pembahasan.vue?vue&type=script&lang=js&"
import style0 from "./c-Pembahasan.vue?vue&type=style&index=0&id=13dab96a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13dab96a",
  null
  
)

export default component.exports