<template>
    <div>
        <b-modal
        id="modal-editStatementAnswer"
        ref="modal-editStatementAnswer"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Edit Pernyataan</h3>
            <h5 class="fw-bold mt-2">Pernyataan</h5>
              <textarea class="inputInsert" v-model="getDetailStatementQuestion.text" cols="52" rows="5" placeholder="Inputkan Pernyataan"></textarea>
            <h5 class="fw-bold mt-4">Jawaban</h5>
              <select
                v-model="getDetailStatementAnswer.text"
                class="form-select form-select-sm mb-3 insertSelect" required
                >
                <option value="" disabled>Pilihan</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            <div class="text-center mt-3">
              <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
              <button class="btn editBtn" @click="btnSave()">Simpan</button>
            </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['idAnswer', 'idQuestion'],
  data () {
    return {
    }
  },
  mixins: [alert],
  computed: {
    ...mapGetters({
      getDetailStatementQuestion: 'statementQuestion/getDetail',
      getDetailStatementAnswer: 'statementAnswer/getDetail'
    })
  },
  methods: {
    ...mapActions({
      // get all
      actionAllStatementAnswer: 'statementAnswer/actionAllStatementAnswer',
      actionStatementQuestion: 'statementQuestion/actionStatementQuestion',
      // edit
      actionEditStatementAnswer: 'statementAnswer/actionEdit',
      actionEditStatementQuestion: 'statementQuestion/actionEdit'
    }),
    btnSave () {
      const dataStatementQuestion = {
        id: this.idQuestion,
        text: this.getDetailStatementQuestion.text
      }

      this.actionEditStatementQuestion(dataStatementQuestion).then(async (response) => {
        const dataStatementAnswer = {
          id: this.idAnswer,
          text: this.getDetailStatementAnswer.text
        }
        await this.actionEditStatementAnswer(dataStatementAnswer)
        this.hideModal()
        this.actionStatementQuestion()
        this.actionAllStatementAnswer()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editStatementAnswer'].hide()
    }
  }
}
</script>
