<template>
    <div>
        <b-modal
        id="modal-addStatementAnswer"
        ref="modal-addStatementAnswer"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Pernyataan</h3>
            <!-- Pernyataan -->
            <h5 class="fw-bold mt-2">Pernyataan</h5>
            <textarea class="inputInsert" v-model="form.text_question" cols="52" rows="5" placeholder="Inputkan Pernyataan"></textarea>
            <h5 class="fw-bold mt-4">Jawaban</h5>
            <select
              v-model="form.text_answer"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
              <option value="" disabled>Pilihan</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
export default {
  props: ['id'],
  mixins: [alert, mathJax],
  data () {
    return {
      form: {
        text_question: '',
        text_answer: '',
        question_id: '',
        statement_question_id: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      getStatementQuestion: 'statementQuestion/getStatementQuestion',
      getAllStatementAnswer: 'statementAnswer/getAllStatementAnswer'
    })
  },
  methods: {
    ...mapActions({
      actionInsertStatementQuestion: 'statementQuestion/actionInsert',
      actionInsert: 'statementAnswer/actionInsert',
      actionStatementQuestion: 'statementQuestion/actionStatementQuestion',
      // actionStatementAnswer: 'statementAnswer/actionStatementAnswer',
      actionAllStatementAnswer: 'statementAnswer/actionAllStatementAnswer'
    }),
    btnInsert () {
      // Statement Question
      const data = {
        text: this.form.text_question,
        question_id: Number(this.id)
      }
      this.actionInsertStatementQuestion(data).then(async (response) => {
        const dataStatementAnswer = {
          text: this.form.text_answer,
          statement_question_id: response.data.id
        }
        await this.actionInsert(dataStatementAnswer)
        this.actionStatementQuestion()
        this.actionAllStatementAnswer()
        this.hideModal()
        // this.actionStatementAnswer(data.question_id)
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    hideModal () {
      this.$refs['modal-addStatementAnswer'].hide()
      this.form = {
        text_question: '',
        text_answer: '',
        question_id: '',
        statement_question_id: ''
      }
    }
  },
  watch: {
    getStatementQuestion () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getAllStatementAnswer () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    // this.actionStatementAnswer(this.id)
    this.actionStatementQuestion()
    this.actionAllStatementAnswer()
  }
}
</script>
