<template>
  <div class="container-fluid">
    <div class="row ms-0">
      <h1 class="col-lg-4 col-md-4 col-sm-4 col-4 text-white fw-bold mt-3">Soal</h1>
      <div class="col-lg-4 col-md-3 col-sm-1 col-1"></div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-6 mx-4 mt-3 text-end">
          <button v-b-modal.modal-addQuestionSNPMB class="btn btn-add mt-3">
            <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Soal
          </button>
      </div>
    </div>
    <!-- Sorting -->
    <div class="row ms-0">
        <!-- Category -->
        <div class="col-lg-2 col-md-3 col-sm-4 col-4">
          <select
            @change="question()"
            v-model="inputSearch.category_id"
            class="form-select form-select-sm my-3 fw-bold"
            >
              <option value="">Pilih Kategori</option>
              <option v-for="(el, i) in getCategorySNPMB" :key="i" :value="el.id_category">
                {{el.category_name}}
              </option>
          </select>
        </div>
        <!-- Status -->
        <div class="col-lg-2 col-md-3 col-sm-4 col-4">
          <select
            @change="question()"
            v-model="inputSearch.status"
            class="form-select form-select-sm my-3 fw-bold"
            >
                <option value="">Pilih Status</option>
                <option value="on-progress">On Progress</option>
                <option value="approved">Approved</option>
                <option value="published">Published</option>
            </select>
        </div>
        <!-- Label -->
        <div class="col-lg-2 col-md-3 col-sm-4 col-4">
          <select
            @change="question()"
            v-model="inputSearch.label"
            class="form-select form-select-sm my-3 fw-bold"
            >
                <option value="">Pilih Label</option>
                <option value="tryout">Tryout</option>
                <option value="exam">Exam</option>
                <option value="daily_question">Daily Question</option>
            </select>
        </div>
        <!-- Creator -->
        <div class="col-lg-2 col-md-3 col-sm-4 col-4">
          <select
            @change="question()"
            v-model="inputSearch.id_question_creator"
            class="form-select form-select-sm my-3 fw-bold"
            >
                <option value="">Pilih Author</option>
                <option v-for="(el, i) in getAllAccount" :key="i" :value="el.ac_id">
                  {{el.ac_name}}
                </option>
            </select>
        </div>
    </div>
    <!--  -->
    <div class="row">
      <div class="bg-white rounded p-2 grey m-4" v-if="isErrorQuestionSNPMB">{{errMessageQuestionSNPMB}}</div>
      <!-- Show Content -->
      <div v-else>
        <div v-for="(item, index) in getQuestionSNPMB" :key="'question'+index" class="container-fluid">
          <div class="row mt-3 mb-5 bg-white content">
            <div class="row col">
              <div class="col-lg-2 col-md-2 grey">
                <div class="mb-2">Kode Soal: </div>{{item.code}}
              </div>
              <div class="col-lg-2 col-md-2 grey">
                <div class="mb-2">Subjek: </div>{{item.subject_name}}
              </div>
              <div class="col-lg-2 col-md-2 grey">
                <div class="mb-2">Kategori: </div>{{item.category_name}}
              </div>
              <div class="col-lg-2 col-md-2 grey">
                <div class="mb-2">Label :</div>
                <div v-if="item.label === 'tryout'">Tryout</div>
                <div v-if="item.label === 'exam'">Exam</div>
                <div v-if="item.label === 'daily_question'">Daily Question</div>
                <div v-if="item.label === 'example_question'">Example Question</div>
              </div>
              <div class="col-lg-2 col-md-2 grey">
                  <div class="mb-2">Status :</div>
                  <div class="text-danger" v-if="item.status === 'on-progress'">On Progress</div>
                  <div class="text-success" v-if="item.status === 'approved'">Approved</div>
                  <div class="text-primary" v-if="item.status === 'published'">Published</div>
              </div>
              <div class="col-lg-2 col-md-2 grey">
                <div class="mb-2">Fitur :</div>
                <div class="text-primary" v-if="item.free_trial === 'true'">Free Trial</div>
                <div class="text-success" v-if="item.free_trial === 'false'">Premium</div>
              </div>

            </div>
            <div class="row col-lg-2">
              <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                  <button @click="btnEdit(item.id_question)" v-b-modal.modal-editSoalSNPMB class="btn btn-edit my-2" style="width: 75px;">Edit</button><br>
                  <!-- Pembahasan -->
                  <router-link :to="{ name: 'Pembahasan', params: {idSoal: item.id_question } }"><button class="my-2 btn btn-discuss" style="width: 120px;">Pembahasan</button></router-link>
              </div>
            </div>
            <div class="row col-lg-10">
              <hr class="line">
            </div>
            <!-- Question Text -->
            <h5 class="text-justify my-3 grey whitespace htmlText" v-html="item.question_text"></h5>
            <!-- Selection (Multiple Choice) -->
            <div v-if="item.qt_id == 2">
              <div class="row mb-4">
                <h5 class="col-lg-9 col-md-7 col-sm-5 col-5 mt-3 grey">Pilihan :</h5>
                <div class="col-lg-3 col-md-5 col-sm-3 col-3 mt-2 text-end">
                  <button @click="addSelection(item.id_question)" v-b-modal.modal-addSelectionSNPMB style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                    <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Pilihan
                  </button>
                </div>
              </div>
              <div v-if="isErrorSelection"></div>
              <div v-else>
                <!-- Else -->
                <div v-for="(el, i) in getSelection" :key="i" class="text-justify grey">
                  <div v-if="item.id_question === el.question_id" class="row">
                    <div class="col-lg-10 col-md-10 col-sm-9 col-9 whitespace">
                      <ul>
                      <li>
                        <vue-mathjax :formula="el.selection_text"></vue-mathjax>
                        <div v-if="!el.image || el.image === 'image'"></div>
                        <div v-else class="text-center">
                          <img :src="`${el.image}`" class="img-fluid image my-2" /><br>
                          <button @click="btnDeleteSelectionImage(el.id_selection)" class="btn deleteImg mb-4">
                            Hapus Gambar</button>
                        </div>
                      </li>
                      </ul>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-3 col-1 text-end">
                      <b-icon @click="editSelection(el.id_selection)" v-b-modal.modal-editSelectionSNPMB class="pencilIcon" icon="pencil-square"></b-icon>
                      <b-icon @click="btnDeleteSelection(el.id_selection)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                    </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <h5 class="col-lg-8 col-md-8 col-sm-8 col-8 mt-3 grey">Jawaban :</h5>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-4 mt-2 text-end">
                    <button @click="addAnswer(item.id_question)" v-b-modal.modal-addAnswerSNPMB style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                      <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Jawaban
                    </button>
                  </div>
                </div>
                <div v-if="isErrorAnswer"></div>
                <div v-else>
                  <div v-for="(ans, i) in getAnswer" :key="'answer'+i">
                    <div v-if="item.id_question === ans.question_id" class="row">
                      <div class="col-lg-10 whitespace grey">
                      <ul>
                      <li>
                        <vue-mathjax :formula="ans.answer_text"></vue-mathjax>
                        <div v-if="!ans.image || ans.image === 'image'"></div>
                        <div v-else class="text-center">
                          <img :src="`${ans.image}`" class="img-fluid image my-2" />
                        </div>
                      </li>
                      </ul>
                    </div>
                    <div class="col-lg-2 text-end">
                      <b-icon @click="btnDeleteAnswer(ans.id_answer)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Statement Question -->
            <div v-else-if="item.qt_id == 5">
              <div class="row mb-4">
                <h5 class="col-lg-8 col-md-8 col-sm-8 col-8 mt-3 grey">Pernyataan :</h5>
                <div class="col-lg-4 col-md-4 col-sm-4 col-4 mt-2 text-end">
                  <button @click="addStatementAnswer(item.id_question)" v-b-modal.modal-addStatementAnswer style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                    <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Pernyataan
                  </button>
                </div>
              </div>
              <div v-if="getIsErrorAll"></div>
                <div v-else>
                  <table>
                    <!-- Statement Question -->
                    <div v-for="(statementQuestion, j) in getStatementQuestion" :key="'statementQuestion' + j">
                      <div v-if="statementQuestion.question_id == item.id_question">
                        <!-- Statement Answer -->
                        <div v-for="(statement, i) in getAllStatementAnswer" :key="'statement'+i">
                          <div v-if="statement.statement_question_id == statementQuestion.id">
                            <tr>
                              <td style="width: 700px"><vue-mathjax :formula="statementQuestion.text"></vue-mathjax></td>
                              <!-- <td style="width: 700px">{{statementQuestion.text}}</td> -->
                              <td class="text-center" style="width: 30px">{{statement.text}}</td>
                              <td class="text-center" style="width: 80px">
                                <b-icon @click="editStatementAnswer(statement.id, statementQuestion.id)" v-b-modal.modal-editStatementAnswer class="pencilIcon mx-2" icon="pencil-square"></b-icon>
                                <b-icon @click="btnDeleteStatementAnswer(statement.id, statementQuestion.id)" class="icon-trash" icon="trash-fill"></b-icon>
                              </td>
                            </tr>
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
            </div>
            <!-- Creator -->
            <div class="grey mt-3 mb-2">Dibuat Oleh : {{item.name_creator}}</div>
            <div v-if="!item.name_updater" class="grey">Diperbaharui Oleh : -</div>
            <div v-else class="grey">Diperbaharui Oleh : {{item.name_updater}}</div>
          </div>
        </div>
      </div>
    </div>
    <b-pagination
      align="right"
      class="mt-3 mx-2 pagination"
      @input="question()"
      v-model="getPaginationQuestionSNPMB.page"
      pills
      :total-rows="getPaginationQuestionSNPMB.totalData"
      :per-page="getPaginationQuestionSNPMB.limit"
      size="sm"
    >
    </b-pagination>
    <modalAddSelectionSNPMB :id="idQuestion" />
    <modalEditSelectionSNPMB />
    <modalAddAnswerSNPMB :id="idQuestionAnswer" />
    <modalAddQuestionSNPMB />
    <modalEditQuestionSNPMB :id="idEdit" />
    <modalAddStatementAnswer :id="idStatementAnswer" />
    <modalEditStatementAnswer :idAnswer="idEditStatementAnswer" :idQuestion="idEditStatementQuestion" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalAddSelectionSNPMB from './m-addSelectionSNPMB.vue'
import modalEditSelectionSNPMB from './m-editSelectionSNPMB.vue'
import modalAddAnswerSNPMB from './m-addAnswerSNPMB.vue'
import modalAddQuestionSNPMB from './m-addQuestionSNPMB.vue'
import modalEditQuestionSNPMB from './m-editQuestionSNPMB.vue'
import modalAddStatementAnswer from './m-addStatementAnswer.vue'
import modalEditStatementAnswer from './m-editStatementAnswer.vue'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
// import { VueEditor } from 'vue2-editor'
import mathJax from '../helper/mathjax'
import imgConvert from '../helper/base64'

export default {
  data () {
    return {
      idQuestion: '',
      idQuestionAnswer: '',
      idEdit: '',
      idStatementAnswer: '',
      idEditStatementAnswer: '',
      idEditStatementQuestion: '',
      inputSearch: {
        category_id: '',
        status: '',
        label: '',
        id_question_creator: ''
      }
    }
  },
  mixins: [alert, mathJax, imgConvert],
  components: {
    modalAddSelectionSNPMB,
    modalEditSelectionSNPMB,
    modalAddAnswerSNPMB,
    modalAddQuestionSNPMB,
    modalEditQuestionSNPMB,
    modalAddStatementAnswer,
    modalEditStatementAnswer
    // VueEditor
  },
  computed: {
    ...mapGetters({
      getQuestionSNPMB: 'questionSNPMB/getQuestionSNPMB',
      getPaginationQuestionSNPMB: 'questionSNPMB/getPaginationQuestionSNPMB',
      isErrorQuestionSNPMB: 'questionSNPMB/isErrorQuestionSNPMB',
      errMessageQuestionSNPMB: 'questionSNPMB/errMessageQuestionSNPMB',
      getInputSearch: 'questionSNPMB/getInputSearch',
      // * Selection
      getSelection: 'selection/getSelection',
      isErrorSelection: 'selection/isError',
      getAnswer: 'answer/getAnswer',
      isErrorAnswer: 'answer/isError',
      // * Statement
      getAllStatementAnswer: 'statementAnswer/getAllStatementAnswer',
      getIsErrorAll: 'statementAnswer/getIsErrorAll',
      getStatementQuestion: 'statementQuestion/getStatementQuestion',
      getCategorySNPMB: 'category/getCategorySNPMB',
      getAllAccount: 'account/getAllAccount'
    })
  },
  methods: {
    ...mapActions({
      actionQuestionSNPMB: 'questionSNPMB/actionQuestionSNPMB',
      actionInputSearch: 'questionSNPMB/actionInputSearch',
      // * Selection
      actionSelection: 'selection/actionSelection',
      actionDetailSelection: 'selection/actionDetail',
      actionDeleteSelection: 'selection/actionDelete',
      actionDeleteSelectionImage: 'selection/deleteImage',
      actionAnswer: 'answer/actionAnswer',
      actionSearchSelection: 'selection/actionSearchSelection',
      actionDeleteAnswer: 'answer/actionDelete',
      actionDetail: 'questionSNPMB/actionDetail',
      actionQuestionImageId: 'questionImage/actionQuestionImageId',
      actionAllStatementAnswer: 'statementAnswer/actionAllStatementAnswer',
      actionDetailStatementAnswer: 'statementAnswer/actionDetail',
      actionDeleteStatementAnswer: 'statementAnswer/actionDelete',
      actionStatementQuestion: 'statementQuestion/actionStatementQuestion',
      actionDeleteStatementQuestion: 'statementQuestion/actionDelete',
      actionDetailStatementQuestion: 'statementQuestion/actionDetail',
      actionCategorySNPMB: 'category/actionCategorySNPMB',
      actionAllAccount: 'account/actionAllAccount',
      actionFreeTrial: 'freeTrial/actionFreeTrial'
    }),
    search () {
      let data = []
      data = this.inputSearch
      this.actionInputSearch(data)
    },
    question () {
      const data = {
        page: this.getPaginationQuestionSNPMB.page ? this.getPaginationQuestionSNPMB.page : 1,
        limit: 5,
        category_id: this.getInputSearch.category_id ? this.getInputSearch.category_id : '',
        status: this.getInputSearch.status ? this.getInputSearch.status : '',
        label: this.getInputSearch.label ? this.getInputSearch.label : '',
        id_question_creator: this.getInputSearch.id_question_creator ? this.getInputSearch.id_question_creator : ''
      }
      this.actionQuestionSNPMB(data)
    },
    selection () {
      this.actionSelection()
    },
    answer () {
      this.actionAnswer()
    },
    statementAnswer () {
      this.actionAllStatementAnswer()
    },
    statementQuestion () {
      this.actionStatementQuestion()
    },
    addSelection (id) {
      this.idQuestion = id
    },
    btnDeleteSelection (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteSelection(id).then((response) => {
            this.answer()
            this.selection()
          }).catch(() => {})
          this.answer()
        }
      })
    },
    btnDeleteSelectionImage (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus gambar ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Gambar anda telah dihapus.')
          this.actionDeleteSelectionImage(id).then((response) => {
            this.selection()
            this.answer()
          }).catch(() => {})
        }
      })
    },
    btnDeleteAnswer (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteAnswer(id).then((response) => {
            this.answer()
          }).catch(() => {})
        }
      })
    },
    btnDeleteStatementAnswer (idStatementAnswer, idStatementQuestion) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteStatementAnswer(idStatementAnswer).then(async (response) => {
            await this.actionDeleteStatementQuestion(idStatementQuestion)
            this.statementAnswer()
            this.statementQuestion()
          }).catch(() => {})
        }
      })
    },
    editSelection (id) {
      this.actionDetailSelection(id)
    },
    addAnswer (id) {
      this.idQuestionAnswer = id
      this.actionSearchSelection(id)
    },
    addStatementAnswer (id) {
      this.idStatementAnswer = id
    },
    btnEdit (id) {
      this.actionDetail(id)
      this.idEdit = id
      this.actionQuestionImageId(id)
      this.actionFreeTrial(id)
    },
    editStatementAnswer (idStatementAnswer, idStatementQuestion) {
      this.actionDetailStatementAnswer(idStatementAnswer)
      this.actionDetailStatementQuestion(idStatementQuestion)
      this.idEditStatementAnswer = idStatementAnswer
      this.idEditStatementQuestion = idStatementQuestion
    }
  },
  watch: {
    getQuestion () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getSelection () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getAnswer () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getStatementQuestion () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    this.question()
    this.selection()
    this.answer()
    this.statementAnswer()
    this.statementQuestion()
    this.actionCategorySNPMB()
    this.search()
    this.actionAllAccount()
  }
}
</script>

<style scoped>
.htmlText >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
  height: auto;
  border-radius: 7px;
}
.htmlText >>> {
  white-space: pre-wrap;
}
select{
  background: url('../assets/img/polygon.png') no-repeat right white;
  background-size: 10px 8px;
  -webkit-appearance: none;
  background-position-x: 90%;
}
.polygon{
  margin-top: 3px;
  width: 30px;
  height: 30px;
}
table {
  border-collapse: collapse;
}

table td {
  border: 1px solid #333;
}

table th {
  border: 1px solid #333;
}
</style>
