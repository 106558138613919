<template>
    <div>
        <div class="container-fluid">
            <!-- Search -->
            <div class="row mt-2">
                <div class="col-lg-9 col-md-9 col-sm-9 col-9 mt-1">
                    <input class="search" type="search" v-model="inputSearch.topic_name" @keyup="topic()" placeholder="Cari Materi ...">
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <componentProfile></componentProfile>
                </div>
            </div>
            <!-- Insert Topic -->
            <div class="row">
                <h1 class="col-lg-4 col-md-2 col-sm-2 text-white fw-bold mt-3">Materi</h1>
                <div class="col-lg-4 col-md-4 col-sm-2"></div>
                <div class="col-lg-3 col-md-5 col-sm-6 mt-3 mx-4 text-end">
                    <button v-b-modal.modal-addTopic class="btn btn-add mt-3">
                    <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Materi
                    </button>
                </div>
            </div>
            <!-- Sorting -->
            <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-4 col-4">
                    <select
                    @change="topic()"
                    v-model="inputSearch.subject_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Subjek</option>
                        <option v-for="(el, i) in getAllSubject" :key="i" :value="el.id_subject">
                          {{el.subject_name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 col-4">
                  <select
                    @change="topic()"
                    v-model="inputSearch.class_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Kelas</option>
                        <option v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">
                          {{el.class_name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-4 col-4">
                  <select
                    @change="topic()"
                    v-model="inputSearch.category_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Kategori</option>
                        <option v-for="(el, i) in getAllCategory" :key="i" :value="el.id_category">
                          {{el.category_name}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="bg-white rounded p-2 grey m-4" v-if="isError">{{errMessage}}</div>
        <div v-else>
            <div v-for="(item, i) in getTopic" :key="i" class="container-fluid">
                <div class="row mt-3 mb-5 bg-white content" style="margin: 0px;">
                    <div class="row mb-5 pb-4">
                        <div class="col-lg-2 col-md-3 col-sm-4 col-6 grey">
                            <div class="mb-2">Subjek :</div> {{item.subject_name}}
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-4 col-6 grey">
                            <div class="mb-2">Kelas :</div> {{item.class_name}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-6 grey">
                            <div class="mb-2">Kategori :</div> {{item.category_name}}
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-4 col-6 grey">
                            <div class="mb-2">Kode :</div> {{item.code}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                            <router-link :to="{ name: 'Subtopic', params: {id: item.id_topic } }"><button class="btn btn-discuss" style="width: 120px; height: 40px;">Sub - materi</button></router-link><br>
                            <button @click="btnEdit(item.id_topic)" v-b-modal.modal-editTopic class="btn btn-edit mt-2" style="width: 75px;">Edit</button><br>
                            <button @click="btnDelete(item.id_topic)" v-if="getDetailAcc.id_level === 1" class="btn btn-delete mt-2" style="width: 75px;">Hapus</button>
                            <div v-if="getDetailAcc.id_level === 2"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-9"><hr class="line"></div>
                        </div>
                        <h1 class="text-center my-3">{{item.topic_name}}</h1>
                        <!-- <div v-if="item.image === 'image' || !item.image || isError"></div>
                        <div v-else class="text-center">
                            <img :src="`${item.image}`" class="img-fluid image my-2" /><br>
                            <button @click="deleteImageTopic(item.id_topic)" class="btn deleteImg">
                            Hapus Gambar</button>
                        </div> -->
                        <!-- <div class="text-justify whitespace grey my-3"><vue-mathjax :formula="item.detail_topic"></vue-mathjax></div> -->
                        <div class="text-justify whitespace grey my-3 htmlText" v-html="item.detail_topic"></div>
                        <div class="grey mt-3 mb-2">Dibuat Oleh : {{item.name_creator}}</div>
                        <div v-if="!item.name_updater" class="grey">Diperbaharui Oleh : -</div>
                        <div v-else class="grey">Diperbaharui Oleh : {{item.name_updater}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isError">
              <b-pagination
                align="right"
                class="mt-3 mx-2 pagination"
                @input="topic()"
                v-model="getPagination.page"
                pills
                :total-rows="getPagination.totalData"
                :per-page="getPagination.limit"
                size="sm"
              >
              </b-pagination>
            </div>
            <div v-else>
              <b-pagination
                align="right"
                class="mt-3 pagination"
                pills
                total-rows="1"
                per-page="1"
                size="sm"
              >
              </b-pagination>
            </div>
        <!-- Modal Insert Topic -->
        <b-modal
        id="modal-addTopic"
        ref="modal-addTopic"
        size="lg"
        hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Materi</h3>
            <!-- <h5 class="fw-bold">Subjek</h5> -->
            <!-- <select
              v-model="form.subject_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Subjek</option>
                  <option v-for="(el, i) in getAllSubject" :key="i" :value="el.id_subject">
                    {{el.subject_name}}
                  </option>
              </select> -->
              <h5 class="fw-bold">Kelas</h5>
              <select
              v-model="form.class_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Kelas</option>
                  <option v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">
                    {{el.class_name}}
                  </option>
              </select>
              <h5 class="fw-bold">Kategori</h5>
              <select
                v-model="form.category_id"
                class="form-select form-select-sm mb-3 insertSelect" required
                >
                    <option value="" disabled>Pilih Kategori</option>
                    <option v-for="(el, i) in getAllCategory" :key="i" :value="el.id_category">
                      {{el.category_name}}
                    </option>
                </select>
                <h5 class="fw-bold">Topik</h5>
                <input class="inputInsert" v-model="form.topic_name" type="text" placeholder="Input judul topik">
                <h5 class="fw-bold mt-3">Detail</h5>
                <!-- <textarea class="inputInsert" v-model="form.detail_topic" cols="52" rows="5" placeholder="Input detail topik"></textarea> -->
                <vue-editor :key="form.class_id" useCustomImageHandler @image-added="handleImageAdded" v-model="form.detail_topic"></vue-editor>
                <!-- <h5 class="fw-bold mt-3">Gambar</h5> -->
                <!-- <input
                  @change="uploadFile($event)"
                  type="file"
                  class="form-control fileBtn"
                  accept="image/png, image/jpg, image/jpeg"
                /> -->
                <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="cancelInsert()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
                </div>
        </div>
      </b-modal>
      <!-- Modal Edit Topic -->
      <b-modal
        id="modal-editTopic"
        ref="modal-editTopic"
        size="lg"
        hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Edit Materi</h3>
            <!-- <h5 class="fw-bold">Subjek</h5>
            <select
              v-model="getDetail.subject_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option v-for="(el, i) in getAllSubject" :key="i" :value="el.id_subject">
                    {{el.subject_name}}
                  </option>
              </select> -->
              <h5 class="fw-bold">Kelas</h5>
              <select
              v-model="getDetail.class_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">
                    {{el.class_name}}
                  </option>
              </select>
              <h5 class="fw-bold">Kategori</h5>
              <select
                v-model="getDetail.category_id"
                class="form-select form-select-sm mb-3 insertSelect" required
                >
                    <option v-for="(el, i) in getAllCategory" :key="i" :value="el.id_category">
                      {{el.category_name}}
                    </option>
                </select>
                <h5 class="fw-bold">Topik</h5>
                <input class="inputInsert" v-model="getDetail.topic_name" type="text" placeholder="Input judul topik">
                <h5 class="fw-bold mt-3">Detail</h5>
                <!-- <textarea class="inputInsert" v-model="getDetail.detail_topic" cols="52" rows="5" placeholder="Input detail topik"></textarea> -->
                <vue-editor :key="getDetail.class_id" useCustomImageHandler @image-added="handleImageEdit" v-model="getDetail.detail_topic"></vue-editor>
                <!-- <h5 class="fw-bold mt-3">Gambar</h5> -->
                <!-- <input
                  @change="updateFile($event)"
                  id = "updateImage"
                  type="file"
                  class="form-control fileBtn"
                  accept="image/png, image/jpg, image/jpeg"
                /> -->
                <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="cancelEdit()">Batalkan</button>
                  <button class="btn editBtn" @click="btnSave()">Simpan</button>
                </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import componentProfile from './Profile.vue'
import { VueEditor } from 'vue2-editor'
import imgConvert from '../helper/base64'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
export default {
  data () {
    return {
      inputSearch: {
        topic_name: '',
        subject_id: '',
        category_id: '',
        class_id: ''
      },
      form: {
        class_id: '',
        category_id: '',
        topic_name: '',
        detail_topic: '',
        image: ''
      },
      // updateImage: '',
      pathValue: '',
      serverURL: process.env.VUE_APP_URL,
      imgArr: [],
      imgEdit: []
    }
  },
  mixins: [alert, mathJax, imgConvert],
  components: {
    componentProfile,
    VueEditor
  },
  computed: {
    ...mapGetters({
      getTopic: 'topic/getTopic',
      getPagination: 'topic/getPagination',
      getId: 'auth/getId',
      getDetailAcc: 'account/getDetailAcc',
      isError: 'topic/isError',
      errMessage: 'topic/errMessage',
      getAllSubject: 'subject/getAllSubject',
      getAllClass: 'class/getAllClass',
      getAllCategory: 'category/getAllCategory',
      getDetail: 'topic/getDetail',
      getToken: 'auth/getToken'
    })
  },
  methods: {
    ...mapActions({
      actionDetailAcc: 'account/actionDetail',
      actionTopic: 'topic/actionTopic',
      allSubject: 'subject/allSubject',
      actAllClass: 'class/actAllClass',
      actAllCategory: 'category/actAllCategory',
      actionInsert: 'topic/actionInsert',
      actionDetail: 'topic/actionDetail',
      actionEdit: 'topic/actionEdit',
      actionDelete: 'topic/actionDelete',
      deleteImage: 'topic/deleteImage',
      uploadImageTopic: 'topic/uploadImageTopic'
    }),
    topic () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1,
        topic_name: this.inputSearch.topic_name ? this.inputSearch.topic_name : '',
        subject_id: this.inputSearch.subject_id ? this.inputSearch.subject_id : '',
        category_id: this.inputSearch.category_id ? this.inputSearch.category_id : '',
        class_id: this.inputSearch.class_id ? this.inputSearch.class_id : '',
        limit: 5
      }
      this.actionTopic(data)
    },
    deleteImageTopic (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus gambar ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.updateImage = ''
          this.ToastSuccess('Gambar telah dihapus.')
          this.deleteImage(id).then((response) => {
            this.topic()
          }).catch(() => {})
        }
      })
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.topic()
          }).catch(() => {})
        }
      })
    },
    btnEdit (id) {
      this.actionDetail(id)
    },
    // updateFile (el) {
    //   this.pathValue = el.target.value
    //   this.updateImage = el.target.files[0]
    //   const reader = new FileReader()
    //   reader.readAsDataURL(this.updateImage)
    //   reader.onload = () => {
    //     this.updateImage = reader.result
    //   }
    // },
    async handleImageEdit (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      this.uploadImageTopic(data).then(result => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgEdit.push(url)
      }).catch({})
    },
    async cancelEdit () {
      if (this.imgEdit) {
        const images = this.imgEdit
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/topicImage`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgEdit = []
      }
      this.hideModal()
    },
    async btnSave () {
      const editImg = this.imgEdit
      const dataImageEdit = editImg.filter((el) => {
        return this.getDetail.detail_topic.includes(el)
      })
      const detailImg = this.getDetail.image.split(',')
      const detailEditImg = detailImg.filter((el) => {
        return this.getDetail.detail_topic.includes(el)
      })
      const data = {
        id_topic: this.getDetail.id_topic,
        class_id: this.getDetail.class_id,
        category_id: this.getDetail.category_id,
        topic_name: this.getDetail.topic_name,
        detail_topic: this.getDetail.detail_topic,
        id_topic_updater: this.getId,
        image: dataImageEdit.concat(detailEditImg).join()
      }
      // if (!this.pathValue) {
      // } else {
      //   data.image = this.updateImage
      //   this.pathValue = ''
      // }
      // Data on db

      await this.actionEdit(data).then(async (response) => {
        if (this.getDetail.image || this.getDetail.image !== 'image') {
          const qtn = this.getDetail.image.split(',')
          const Imagefiltered = qtn.filter((el) => {
            return !this.getDetail.detail_topic.includes(el)
          })
          Imagefiltered.map(async (el) => {
            if (el) {
              const dataDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/topicImage`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        } else {}
        // handleImageEdit
        if (this.imgEdit) {
          const images = this.imgEdit
          const dataFiltered = images.filter((el) => {
            return !this.getDetail.detail_topic.includes(el)
          })
          dataFiltered.map(async (el) => {
            if (el) {
              const imgDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/topicImage`,
                method: 'DELETE',
                data: imgDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        }
        this.hideModal()
        this.topic()
        this.imgEdit = []
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    // uploadFile (el) {
    //   this.form.image = el.target.files[0]
    //   const reader = new FileReader()
    //   reader.readAsDataURL(this.form.image)
    //   reader.onload = () => {
    //     this.form.image = reader.result
    //   }
    // },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      this.uploadImageTopic(data).then(result => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgArr.push(url)
      }).catch(() => {})
    },
    async cancelInsert () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/topicImage`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgArr = []
      }
      this.hideModal()
    },
    async btnInsert () {
      const images = this.imgArr
      const dataInsert = images.filter((el) => {
        return this.form.detail_topic.includes(el)
      })
      const data = {
        class_id: this.form.class_id,
        category_id: this.form.category_id,
        topic_name: this.form.topic_name,
        detail_topic: this.form.detail_topic,
        image: dataInsert.join(),
        id_topic_creator: this.getId
      }

      await this.actionInsert(data).then(async (response) => {
        for (let i = 0; i < this.imgArr.length; i++) {
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.form.detail_topic.includes(el)
          })
          if (filtered[i]) {
            const dataDelete = {
              image: filtered[i]
            }
            await axios({
              url: `${this.serverURL}/v1/topicImage`,
              method: 'DELETE',
              data: dataDelete,
              headers: { token: this.getToken }
            })
              .then({})
              .catch({})
          }
        }
        this.imgArr = []
        this.hideModal()
        this.topic()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch(() => {
        this.AlertError('Harap isi semua kolom!')
      })
    },
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    // cancel () {
    //   this.$refs['modal-editTopic'].hide()
    //   this.updateImage = ''
    // },
    hideModal () {
      this.$refs['modal-editTopic'].hide()
      this.$refs['modal-addTopic'].hide()
      this.form = {
        class_id: '',
        category_id: '',
        topic_name: '',
        detail_topic: '',
        image: ''
      }
    }
  },
  watch: {
    getTopic () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    this.topic()
    this.allSubject()
    this.actAllClass()
    this.actAllCategory()
  }
}
</script>

<style scoped>
.htmlText >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
  height: auto;
  border-radius: 7px;
}
.htmlText >>> {
  white-space: pre-wrap;
}
select{
  background: url('../assets/img/polygon.png') no-repeat right white;
  background-size: 10px 8px;
  -webkit-appearance: none;
  background-position-x: 90%;
}
</style>
