<template>
    <div>
        <img class="img-fluid title mt-3 mx-3" src="../assets/img/logo2.png" alt="Eureka">
        <div class="container-fluid">
            <div class="row my-4">
                <div class="col-lg-4 col-md-5 col-sm-5">
                    <img class="img-responsive img-fluid frontTeam mt-5 mx-4" src="../assets/img/analyticsTeam.svg" alt="Login">
                </div>
                <div class="col-lg-2 col-md-1 col-sm-1"></div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="sub-title mb-3">Forgot Password</div>
                    <!-- Form -->
                    <form @submit.prevent="onSubmit()">
                        <input
                        v-model="ac_email"
                        type="email"
                        class="form-control my-4"
                        placeholder="Email" required
                        />
                        <input
                        v-model="ac_password"
                        type="password"
                        class="form-control my-3"
                        placeholder="Password" required
                        />
                        <button type="submit" class="btn reset-btn mt-3">Reset my Password</button>
                        <div class="text-center my-3">
                            <router-link to="/register" class="link">
                            Don't have an account yet? <u>Sign Up</u>
                            </router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import alert from '../helper/alert'
export default {
  mixins: [alert],
  data () {
    return {
      ac_email: '',
      ac_password: ''
    }
  },
  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgotPassword'
    }),
    onSubmit () {
      const data = {
        ac_email: this.ac_email,
        ac_password: this.ac_password
      }
      this.forgotPassword(data).then((response) => {
        this.$router.push('/login')
        this.ToastSuccess('Password changed !')
      }).catch((err) => {
        this.AlertError(err)
      })
    }
  }
}
</script>

<style scoped>
img.title{
    height: 90px;
    width: 200px;
}
.title{
    font-size: 38px;
    font-weight: bold;
}
.sub-title{
    font-weight: bold;
    font-size: 30px;
}
.reset-btn{
    background: #2CA3F5;
    width: 100%;
    height: 60px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 23px;
    border-radius: 10px;
}
.link{
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    font-size: 15px;
}
.link:hover{
    font-size: 16px;
}
input{
    border: 2px solid #000000;
    border-radius: 10px;
    font-weight: bold;
}
input::-webkit-input-placeholder{
    font-weight: lighter;
}
</style>
