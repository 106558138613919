<template>
    <div>
        <b-modal
        id="modal-editSelectionSNPMB"
        ref="modal-editSelectionSNPMB"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Edit Pilihan</h3>
            <h5 class="fw-bold">Pilihan</h5>
            <textarea v-model="getDetail.selection_text" class="inputInsert" cols="52" rows="3" placeholder="Inputkan pilihan"></textarea>
            <h5 class="fw-bold mt-3">Gambar</h5>
              <input
                @change="updateFile($event)"
                id = "updateImage"
                type="file"
                class="form-control fileBtn"
                accept="image/png, image/jpg, image/jpeg"
              />
            <div class="text-center mt-3">
              <button class="btn cancelBtn" @click="cancel()">Batalkan</button>
              <button class="btn editBtn" @click="btnSave()">Simpan</button>
            </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  data () {
    return {
      updateImage: '',
      pathValue: ''
    }
  },
  mixins: [alert],
  computed: {
    ...mapGetters({
      getDetail: 'selection/getDetail'
    })
  },
  methods: {
    ...mapActions({
      actionSelection: 'selection/actionSelection',
      actionEdit: 'selection/actionEdit',
      actionAnswer: 'answer/actionAnswer'
    }),
    updateFile (el) {
      this.pathValue = el.target.value
      this.updateImage = el.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.updateImage)
      reader.onload = () => {
        this.updateImage = reader.result
      }
    },
    btnSave () {
      const data = {
        id_selection: this.getDetail.id_selection,
        selection_text: this.getDetail.selection_text
      }
      if (!this.pathValue) {
      } else {
        data.image = this.updateImage
        this.pathValue = ''
      }

      this.actionEdit(data).then((response) => {
        this.hideModal()
        this.actionSelection()
        this.actionAnswer()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
      this.actionAnswer()
    },
    cancel () {
      this.$refs['modal-editSelectionSNPMB'].hide()
      this.updateImage = ''
    },
    hideModal () {
      this.$refs['modal-editSelectionSNPMB'].hide()
    }
  }
}
</script>
