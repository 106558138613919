import axios from 'axios'
const moduleShortAnswer = {
  namespaced: true,
  state: () => {
    return {
      listShortAnswer: [],
      pagination: {},
      detailShortAnswer: {},
      isError: false,
      errMessage: ''
    }
  },
  mutations: {
    mutationShortAnswer (state, payload) {
      state.listShortAnswer = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailShortAnswer = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    }
  },
  actions: {
    actionShortAnswer (context, data) {
      axios.get(`${context.rootState.setURL}/v1/shortAnswer`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationShortAnswer', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/shortAnswer/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/shortAnswer`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/shortAnswer/${data.id_short_answer}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/shortAnswer/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getShortAnswer (state) {
      return state.listShortAnswer
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailShortAnswer
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    }
  }
}

export default moduleShortAnswer
