<template>
  <div>
    <!-- Literasi -->
    <b-modal
      id="modal-totalLiterasi"
      ref="modal-totalLiterasi"
      size="lg"
      hide-header-close
      hide-footer
      hide-header
      centered
    >
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Literasi</h3>
        <div v-for="(item, i) in getAllTotalLiterasi" :key="i">
          <div class="mt-5 mb-2">
            <u class="fw-bold">{{ item.category_name }}</u>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Materi</th>
                <th scope="col">On-progress</th>
                <th scope="col">Approved</th>
                <th scope="col">Published</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody v-for="topics in item.topic" :key="topics.id_topic">
              <tr>
                <td>{{ topics.topic_name }}</td>
                <td>{{ topics.total[0].totalOnProgress }}</td>
                <td>{{ topics.total[0].totalApproved }}</td>
                <td>{{ topics.total[0].totalPublished }}</td>
                <td>{{ topics.total[0].amount }}</td>
              </tr>
            </tbody>
              <tr>
                <td><b>Total</b></td>
                <td>{{ item.total[0].totalOnProgress }}</td>
                <td>{{ item.total[0].totalApproved }}</td>
                <td>{{ item.total[0].totalPublished }}</td>
                <td>{{ item.total[0].totalPerCategory }}</td>
              </tr>
          </table>
        </div>
          <div class="fw-bold">Total Soal Literasi : {{getTotalAllQtn.totalLiterasi}}</div>
        <div class="text-center mt-3">
          <button @click="hideModal()" class="btn cancelBtn">Tutup</button>
        </div>
      </div>
    </b-modal>
    <!-- Numerasi -->
    <b-modal
      id="modal-totalNumerasi"
      ref="modal-totalNumerasi"
      size="lg"
      hide-header-close
      hide-footer
      hide-header
      centered
    >
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Numerasi</h3>
        <div v-for="(item, i) in getAllTotalNumerasi" :key="i">
          <div class="mt-5 mb-2">
            <u class="fw-bold">{{ item.category_name }}</u>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Materi</th>
                <th scope="col">On-progress</th>
                <th scope="col">Approved</th>
                <th scope="col">Published</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody v-for="topics in item.topic" :key="topics.id_topic">
              <tr>
                <td>{{ topics.topic_name }}</td>
                <td>{{ topics.total[0].totalOnProgress }}</td>
                <td>{{ topics.total[0].totalApproved }}</td>
                <td>{{ topics.total[0].totalPublished }}</td>
                <td>{{ topics.total[0].amount }}</td>
              </tr>
            </tbody>
            <tr>
              <td><b>Total</b></td>
              <td>{{ item.total[0].totalOnProgress }}</td>
              <td>{{ item.total[0].totalApproved }}</td>
              <td>{{ item.total[0].totalPublished }}</td>
              <td>{{ item.total[0].totalPerCategory }}</td>
            </tr>
          </table>
        </div>
          <div class="fw-bold">Total Soal Numerasi : {{getTotalAllQtn.totalNumerasi}}</div>
        <div class="text-center mt-3">
          <button @click="hideModal()" class="btn cancelBtn">Tutup</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      // data:
    }
  },
  computed: {
    ...mapGetters({
      getAllTotalLiterasi: 'question/getAllTotalLiterasi',
      getAllTotalNumerasi: 'question/getAllTotalNumerasi',
      getTotalAllQtn: 'question/getTotalAllQtn'
    })
  },
  methods: {
    hideModal () {
      this.$refs['modal-totalLiterasi'].hide()
      this.$refs['modal-totalNumerasi'].hide()
    }
  }
}
</script>
