<template>
    <div>
        <b-modal
        id="modal-addSelection"
        ref="modal-addSelection"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Pilihan</h3>
            <h5 class="fw-bold">Pilihan</h5>
            <textarea v-model="form.selection_text" class="inputInsert" cols="52" rows="3" placeholder="Inputkan pilihan"></textarea>
            <h5 class="fw-bold mt-3 mb-3">Gambar</h5>
                <input
                  @change="uploadFile($event)"
                  type="file"
                  class="form-control fileBtn"
                  accept="image/png, image/jpg, image/jpeg"
                />
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
                </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id'],
  mixins: [alert],
  data () {
    return {
      form: {
        selection_text: '',
        question_id: '',
        image: ''
      }
    }
  },
  methods: {
    ...mapActions({
      actionInsert: 'selection/actionInsert',
      actionSelection: 'selection/actionSelection'
    }),
    uploadFile (el) {
      this.form.image = el.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.form.image = reader.result
      }
    },
    btnInsert () {
      const data = {
        selection_text: this.form.selection_text,
        question_id: this.id,
        image: this.form.image
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.actionSelection()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    hideModal () {
      this.$refs['modal-addSelection'].hide()
      this.form = {
        selection_text: '',
        question_id: '',
        image: ''
      }
    }
  },
  mounted () {
    this.actionSelection()
  }
}
</script>
