import axios from 'axios'
const moduleSubject = {
  namespaced: true,
  state: () => {
    return {
      listSubject: [],
      pagination: {},
      detailSubject: {},
      isError: false,
      errMessage: '',
      allSubject: '' // no limit
    }
  },
  mutations: {
    mutationSubject (state, payload) {
      state.listSubject = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailSubject = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setAllSubject (state, payload) {
      state.allSubject = payload
    }
  },
  actions: {
    actionSubject (context, data) {
      axios.get(`${context.rootState.setURL}/v1/subject?page=${data.page}&subject_name=${data.subject_name}&limit=${data.limit}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationSubject', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    allSubject (context, data) {
      axios.get(`${context.rootState.setURL}/v1/subject`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllSubject', response.data.data)
      }).catch(() => {
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/subject/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/subject`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/subject/${data.id_subject}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/subject/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getSubject (state) {
      return state.listSubject
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailSubject
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    },
    getAllSubject (state) {
      return state.allSubject
    }
  }
}

export default moduleSubject
