<template>
    <div>
        <div class="container-fluid" style="height: 100%;">
            <div class="row my-3" style="height: 100%;">
                <div class="col-lg-2 col-md-3 col-sm-3">
                    <router-link to="/">
                        <img class="logo img-fluid mx-1 mb-3" src="../assets/img/logo2.png" alt="Eureka"><br><br>
                    </router-link>
                    <router-link :class="`text-dark ${$route.name === 'Home' ? 'active ' : ''}`" to="/">
                        <h5><b-icon class="icon" icon="house-door"></b-icon>Beranda</h5>
                    </router-link>
                    <router-link :class="`text-dark ${$route.name === 'Materi' ? 'active ' : ''}`" to="/materi">
                        <h5><b-icon class="icon" icon="journals"></b-icon>Materi</h5>
                    </router-link>
                    <router-link :class="`text-dark ${$route.name === 'Soal' ? 'active ' : ''}`" to="/soal">
                        <h5><b-icon class="icon" icon="journal-text"></b-icon>Soal</h5>
                    </router-link>
                    <!-- SNPMB -->
                    <router-link :class="`text-dark ${$route.name === 'SoalSNPMB' ? 'active ' : ''}`" to="/snpmb/soal">
                        <h5><b-icon class="icon" icon="book"></b-icon>SNPMB</h5>
                    </router-link>
                    <div v-if="getDetailAcc.ac_level === 1">
                        <router-link :class="`text-dark ${$route.name === 'Banner' ? 'active ' : ''}`" to="/banner">
                            <h5><b-icon class="icon" icon="images"></b-icon>Banner</h5>
                        </router-link>
                    </div>
                    <div v-else></div>
                    <router-link :class="`text-dark ${$route.name === 'Chart' ? 'active ' : ''}`" to="/chart">
                        <h5><b-icon class="icon" icon="bar-chart-line"></b-icon>Grafik</h5>
                    </router-link>
                    <!-- Logout -->
                    <router-link :class="`text-dark ${$route.name === 'Login' ? 'active ' : ''}`" to="/login">
                        <h5 @click="onLogout()" class="mb-3"><b-icon class="icon" icon="arrow-left-circle"></b-icon>Logout</h5>
                    </router-link>
                </div>
                <div class="col-lg-10 col-md-9 col-sm-9 blue" style="height: 100%;">
                    <div v-show="$route.name==='Home'"><componentBeranda /></div>
                    <div v-show="$route.name==='Materi'"><componentMateri /></div>
                    <div v-show="$route.name==='Soal'"><componentSoal /></div>
                    <div v-show="$route.name==='Subjek'"><componentSubject /></div>
                    <div v-show="$route.name==='Kelas'"><componentKelas /></div>
                    <div v-show="$route.name==='Kategori'"><componentKategori /></div>
                    <div v-show="$route.name==='Pembahasan'"><componentPembahasan /></div>
                    <div v-show="$route.name==='Group'"><componentGroup /></div>
                    <div v-show="$route.name==='Banner'"><componentBanner /></div>
                    <div v-show="$route.name==='Indicator'"><componentIndicator /></div>
                    <div v-show="$route.name==='Subtopic'"><componentSubtopic /></div>
                    <div v-show="$route.name==='Chart'"><componentChart /></div>
                    <div v-show="$route.name==='SoalSNPMB'"><componentSoalSNPMB /></div>
                    <div v-show="$route.name==='KategoriSNPMB'"><componentKategoriSNPMB /></div>
                    <div v-show="$route.name==='MateriSNPMB'"><componentMateriSNPMB /></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import componentBeranda from './Beranda.vue'
import componentMateri from './c-Materi.vue'
import componentSubject from './c-Subjek.vue'
import componentKelas from './c-Kelas.vue'
import componentKategori from './c-Category.vue'
import componentSoal from './c-Soal.vue'
import componentPembahasan from './c-Pembahasan.vue'
import componentGroup from './c-Group.vue'
import componentBanner from './c-Banner.vue'
import componentIndicator from './c-Indicator.vue'
import componentSubtopic from './c-Sub-materi.vue'
import componentChart from './Chart.vue'
import componentSoalSNPMB from './c-SNPMB-Soal.vue'
import componentKategoriSNPMB from './c-SNPMB-Kategori.vue'
import componentMateriSNPMB from './c-SNPMB-Materi.vue'
import alert from '../helper/alert'
export default {
  mixins: [alert],
  components: {
    componentBeranda,
    componentMateri,
    componentSubject,
    componentKelas,
    componentKategori,
    componentSoal,
    componentPembahasan,
    componentGroup,
    componentBanner,
    componentIndicator,
    componentSubtopic,
    componentChart,
    componentSoalSNPMB,
    componentKategoriSNPMB,
    componentMateriSNPMB
  },
  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      getDetailAcc: 'account/getDetailAcc'
    })
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      actionDetailAcc: 'account/actionDetailAcc'
    }),
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    onLogout () {
      this.logout().then(response => {
        if (response) {
          this.$router.push('/login')
          this.ToastSuccess('Selamat berjumpa kembali !')
        }
      })
    }
  }
}
</script>

<style scoped>
img.logo{
    height: 80px;
    width: 170px;
}
.blueImg{
    margin-top: 115px;
    width: 180px;
}
/* .roundImg{
    width: 60px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
} */
.blue{
    border-radius: 20px;
    /* background-color: rgba(44, 163, 245, 0.25); */
    background-color: rgba(67, 168, 236, 0.555);
}
.icon{
    margin-right: 6px;
}
.active h5{
    color: white;
    background-color: rgba(67, 168, 236, 0.555);
    padding: 5px 5px 5px 10px;
    border-radius: 20px;
}
h5{
    cursor: pointer;
    margin: 20px 0px 0px 10px;
}
</style>
