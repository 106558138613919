import axios from 'axios'
const moduleQuestionImage = {
  namespaced: true,
  state: () => {
    return {
      listQuestionImage: [],
      pagination: {},
      detailQuestionImage: {},
      isError: false,
      errMessage: '',
      listQuestionImageId: [],
      errImage: false
    }
  },
  mutations: {
    mutationQuestionImage (state, payload) {
      state.listQuestionImage = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailQuestionImage = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    mutationQuestionImageId (state, payload) {
      state.listQuestionImageId = payload
    },
    setErrImg (state, payload) {
      state.errImage = payload
    }
  },
  actions: {
    actionQuestionImage (context, data) {
      axios.get(`${context.rootState.setURL}/v1/question-image`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationQuestionImage', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
      })
    },
    actionQuestionImageId (context, id) {
      axios.get(`${context.rootState.setURL}/v1/question-image_id/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationQuestionImageId', response.data.data)
        context.commit('setErrImg', false)
      }).catch(() => {})
      context.commit('setErrImg', true)
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/question-image/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/question-image`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/question-image/${data.id}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEditQuestionID (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/question-imageID`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/question-image/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    },
    actionDeleteWithURL (context, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/question-imageURL`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    },
    actionDuplicateImage (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/question-image-duplicate`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    }
  },
  getters: {
    getQuestionImage (state) {
      return state.listQuestionImage
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailQuestionImage
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    },
    getQuestionImageId (state) {
      return state.listQuestionImageId
    },
    errImg (state) {
      return state.errImage
    }
  }
}

export default moduleQuestionImage
