<template>
  <div>
    <div class="container-fluid text-end">
      <button @click="$router.go(-1)" class="btn btn-back mt-4">
        <b-icon icon="arrow-left-circle-fill" style="margin-right: 7px;" ></b-icon>Kembali
      </button><br>
      <button @click="btnAddSubtopic()" v-b-modal.modal-addSubtopic class="btn btn-add mx-3" style="width: auto">Tambah Sub-materi</button>
    </div>
    <div class="bg-white rounded p-2 grey m-4" v-if="isError">Tambahkan sub-materi</div>
    <div v-else>
      <div v-for="(item, index) in getAll" :key="index" class="container-fluid">
        <div class="row mt-3 mb-5 bg-white content" style="margin: 0px;">
          <div class="row mb-5 pb-4">
            <div class="col-lg-4 grey">
              <div>Materi : </div>{{item.topic_name}}
            </div>
            <div class="col-lg-4 grey">
              <div>Kode : </div>{{item.code}}
            </div>
            <div class="col-lg-4 grey">
              <button @click="btnEdit(item.id)" v-b-modal.modal-editSubtopic class="btn btn-edit mt-2" style="width: 75px;">Edit</button><br>
              <button @click="btnDelete(item.id)" v-if="getDetailAcc.id_level === 1" class="btn btn-delete mt-2" style="width: 75px;">Hapus</button>
            </div>
            <div class="row">
              <div class="col-lg-9"><hr class="line"></div>
            </div>
            <h1 class="text-center my-3">{{item.name}}</h1>
            <div class="text-justify whitespace grey my-3 htmlText" v-html="item.detail"></div>
            <div class="grey mt-3 mb-2">Dibuat Oleh : {{item.name_creator}}</div>
            <div v-if="!item.name_updater" class="grey">Diperbaharui Oleh : -</div>
            <div v-else class="grey">Diperbaharui Oleh : {{item.name_updater}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Components -->
    <modalAddSubtopic :id="id" />
    <modalEditSubtopic :idEdit="idEdit"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalAddSubtopic from './m-addSubtopic.vue'
import modalEditSubtopic from './m-editSubtopic.vue'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
export default {
  data () {
    return {
      id: Number(this.$route.params.id),
      idEdit: Number(this.$route.params.id)
    }
  },
  mixins: [alert, mathJax],
  components: {
    modalAddSubtopic,
    modalEditSubtopic
  },
  computed: {
    ...mapGetters({
      isError: 'subtopic/isErrorAll',
      getAll: 'subtopic/getAll',
      getDetailAcc: 'account/getDetailAcc',
      getId: 'auth/getId'
    })
  },
  methods: {
    ...mapActions({
      actionGetSubtopic: 'subtopic/actionGetSubtopic',
      actionDetailAcc: 'account/actionDetail',
      actionDetail: 'subtopic/actionDetail',
      actionDelete: 'subtopic/actionDelete'
    }),
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    subtopic () {
      const data = {
        topic_id: this.id ? this.id : ''
      }
      this.actionGetSubtopic(data)
    },
    btnAddSubtopic () {
      this.id = Number(this.$route.params.id)
    },
    btnEdit (id) {
      this.actionDetail(id)
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.subtopic()
          }).catch(() => {})
        }
      })
    }
  },
  watch: {
    getAll () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    this.subtopic()
    this.detailAcc()
  }
}
</script>

<style scoped>
.htmlText >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
  height: auto;
  border-radius: 7px;
}
.htmlText >>> {
  white-space: pre-wrap;
}
</style>
