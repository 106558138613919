<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-2">
                <div class="col-lg-9 col-md-9 col-sm-9 col-9 mt-1">
                    <div v-if="getDetailAcc.id_level === 1">
                    <input class="search" v-model="form.ac_name" @keyup="btnSearch()" type="search" placeholder="Cari Nama ...">
                    </div>
                    <div v-else></div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <componentProfile></componentProfile>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-11 col-md-11 white mt-4 mb-3">
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-7 mt-5">
                            <h4 class="fw-bolder grey-font">Welcome back {{getName}}</h4>
                            <h5 class="grey-font mt-4">Have a nice day at work </h5>
                        </div>
                        <div class="col-lg-4">
                            <img class="img-beranda" src="../assets/img/laptopGuy.svg" alt="Eureka">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-if="getDetailAcc.id_level === 1">
                    <h1 class="mt-3 mx-3 text-white fw-bold">User</h1>
                    <div class="container-fluid mt-2 text-start">
                        <div class="row top-table bg-white overflow-auto" style="width: 100%;">
                            <div class="col-lg-5 col-md-5 col-sm-5 col-5">Nama</div>
                            <div class="col-lg-5 col-md-5 col-sm-5 col-5">Email</div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-2">Aksi</div>
                        </div>
                    </div>
                    <div class="container-fluid text-start mt-3">
                        <div class="row bg-white bottom overflow-auto">
                            <div v-if="isErrorAcc">{{errMessageAcc}}</div>
                            <div v-else>
                                <div v-for="(item, i) in getAccount" :key="i">
                                    <div class="row my-2">
                                        <div class="col-lg-5 col-md-5 col-sm-5 col-4">{{item.ac_name}}</div>
                                        <div class="col-lg-5 col-md-5 col-sm-5 col-5">{{item.ac_email}}</div>
                                        <div class="col-lg-2 col-md-2 col-sm-2 col-4">
                                          <div class="row">
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                            <!-- edit 1 -->
                                            <b-icon
                                            @click="btnEditToPending(item.ac_id)"
                                            v-if="item.is_active === 1" class="icon-check" icon="person-check-fill"></b-icon>
                                            <!-- edit 2 -->
                                            <b-icon
                                            @click="btnEditToConfirm(item.ac_id)"
                                            v-if="item.is_active === 2" class="icon-plus" icon="person-plus-fill"></b-icon>
                                            </div>
                                            <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                              <b-icon
                                            @click="btnDelete(item.ac_id)"
                                            class="icon-trash mx-3" icon="trash-fill"></b-icon>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isErrorAcc">
                      <b-pagination
                        align="right"
                        class="mt-3 mx-2 pagination"
                        @input="account()"
                        v-model="getPaginationAcc.page"
                        pills
                        :total-rows="getPaginationAcc.totalData"
                        :per-page="getPaginationAcc.limit"
                        size="sm"
                      >
                      </b-pagination>
                    </div>
                    <div v-else>
                      <b-pagination
                        align="right"
                        class="mt-3 pagination"
                        pills
                        total-rows="1"
                        per-page="1"
                        size="sm"
                      >
                      </b-pagination>
                    </div>
                </div>
                <div v-if="getDetailAcc.id_level === 2"></div>
            </div>
            <div class="container-fluid my-5 text-center">
              <!-- AN -->
              <div class="row" style="width: 100%">
                <h5 class="fw-bold text-black mb-4">Asesmen Nasional</h5>
                <div class="col-lg-2"></div>
                <!-- AN -->
                  <h5 class="col-lg-3 col-md-4 col-sm-4 col-5 custom bg-white">
                      <router-link class="text-dark" to="/subjek">Subjek</router-link>
                  </h5>
                  <h3 class="col-lg-2 col-md-4 col-sm-4 col-2 custom fw-bold"></h3>
                  <h5 class="col-lg-3 col-md-4 col-sm-4 col-5 custom bg-white">
                      <router-link class="text-dark" to="/kelas">Kelas</router-link>
                  </h5>
                  <div class="col-lg-2"></div>
              </div>
              <!--  -->
              <div class="row" style="width: 100%;">
                <div class="col-lg-2"></div>
                <h5 class="col-lg-3 col-md-4 col-sm-4 col-5 custom bg-white text-center mt-4">
                  <router-link class="text-dark" to="/kategori">Kategori</router-link>
                </h5>
                <h3 class="col-lg-2 col-md-4 col-sm-4 col-2 custom fw-bold"></h3>
                <h5 class="col-lg-3 col-md-4 col-sm-4 col-5 custom bg-white mt-4">
                    <router-link class="text-dark" to="/indikator">Indikator</router-link>
                </h5>
              </div>
              <!-- SNPMB -->
              <div class="row mt-5" style="width: 100%">
                <h5 class="fw-bold text-black mb-3">SNPMB</h5>
                <div class="col-lg-2"></div>
                  <h5 class="col-lg-3 col-md-4 col-sm-4 col-5 custom bg-white">
                      <router-link class="text-dark" to="/snpmb/kategori">Kategori</router-link>
                  </h5>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import componentProfile from './Profile.vue'
import alert from '../helper/alert'
import Swal from 'sweetalert2'
export default {
  components: {
    componentProfile
  },
  mixins: [alert],
  data () {
    return {
      form: {
        ac_name: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      getName: 'auth/getName',
      getAccount: 'account/getAccount',
      getPaginationAcc: 'account/getPaginationAcc',
      isErrorAcc: 'account/isErrorAcc',
      errMessageAcc: 'account/errMessageAcc',
      getDetailAcc: 'account/getDetailAcc',
      getId: 'auth/getId'
    })
  },
  methods: {
    ...mapActions({
      actionAccount: 'account/actionAccount',
      actionEdit: 'account/actionEdit',
      actionDetail: 'account/actionDetail',
      actionDelete: 'account/actionDelete'
    }),
    account () {
      const data = {
        page: this.getPaginationAcc.page ? this.getPaginationAcc.page : 1,
        ac_name: this.form.ac_name ? this.form.ac_name : ''
      }
      this.actionAccount(data)
    },
    btnSearch () {
      const data = {
        page: this.getPaginationAcc.page ? this.getPaginationAcc.page : 1,
        ac_name: this.form.ac_name ? this.form.ac_name : ''
      }
      this.actionAccount(data)
    },
    detail () {
      this.actionDetail(this.getId)
    },
    btnEditToPending (id) {
      const data = {
        ac_id: id,
        is_active: 2
      }
      this.actionEdit(data).then((response) => {
        this.ToastSuccess('Data berhasil diperbaharui')
        this.account()
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    btnEditToConfirm (id) {
      const data = {
        ac_id: id,
        is_active: 1
      }
      this.actionEdit(data).then((response) => {
        this.ToastSuccess('Data berhasil diperbaharui')
        this.account()
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.account()
          }).catch(() => {})
        }
      })
    }
  },
  mounted () {
    this.account()
    this.detail()
  }
}
</script>

<style scoped>
.text-dark{
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
}
.text-dark:hover{
    color: cornflowerblue !important;
}
.custom{
  height: 80px;
  border-radius: 10px;
  padding-top: 23px;
}
.pagination{
  margin-right: 35px;
  color: red;
}
.img-beranda{
  width: 290px;
  height: 220px;
}
@media screen and (max-width: 992px) {
  .img-beranda{
    display: none;
  }
}
.bottom{
    border-radius: 10px;
    width: 100%;
    height: 100%;
}
.icon-check{
    fill: rgb(58, 202, 58);
    cursor: pointer;
}
.icon-check:hover{
    fill: black;
}
.icon-plus{
    fill: rgb(35, 146, 219);
    cursor: pointer;
}
.icon-plus:hover{
    fill: black;
}
</style>
