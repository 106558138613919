<template>
    <div>
      <div class="container-fluid">
        <div class="row mt-2">
          <div class="col-lg-9 col-md-9 col-sm-9 col-9 mt-1">
            <input class="search" v-model="subject_name" @keyup="btnSearch()" type="search" placeholder="Cari Subjek ...">
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-3">
              <componentProfile></componentProfile>
          </div>
        </div>
        <div class="row">
          <h1 class="col-lg-3 col-md-3 col-sm-3 text-white fw-bold mt-3">Subjek</h1>
          <div class="col-lg-5 col-md-4 col-sm-5"></div>
          <div class="col-lg-3 col-md-4 col-sm-3 mt-3 text-end mx-3">
            <button v-b-modal.modal-add class="btn btn-add">
              <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Subjek
            </button>
          </div>
        </div>
        <div class="row">
            <div class="col-lg-11 white mt-2">
                <div class="row">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-7 col-md-7 mt-5">
                        <h4 class="fw-bolder grey-font">Semua Subjek</h4>
                        <h5 class="grey-font mt-4">Silahkan memilih subjek</h5>
                    </div>
                    <div class="col-lg-4 col-md-4 d-none d-xl-block">
                        <img class="img-subjek img-responsive" src="../assets/img/girl.svg" alt="Eureka">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid mt-5 pt-3 text-start ">
                <div class="row top-table bg-white" style="width: 100%;">
                    <div class="col-lg-2 col-md-2 col-sm-2 col-3">Subjek</div>
                    <div class="col-lg-2 col-md-3 col-sm-3 col-3">Kode</div>
                    <div class="col-lg-2 col-md-3 col-sm-3 col-3">Dibuat Oleh</div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-4">Diperbaharui Oleh</div>
                    <div class="col-lg-3 col-md-2 col-sm-2 col-2">Aksi</div>
                </div>
            </div>
            <div class="container-fluid text-start mt-3">
                <div class="row bg-white bottom " style="width: 100%;">
                    <div v-if="isError">{{errMessage}}</div>
                    <div v-else>
                        <div v-for="(item, i) in getSubject" :key="i">
                            <div class="row my-2">
                                <div class="col-lg-2 col-md-2 col-sm-3 col-3">{{item.subject_name}}</div>
                                <div class="col-lg-2 col-md-2 col-sm-3 col-3">{{item.code}}</div>
                                <div class="col-lg-2 col-md-3 col-sm-2 col-3">{{item.name_creator}}</div>
                                <div class="col-lg-3 col-md-2 col-sm-2 col-3" v-if="!item.name_updater">
                                    -
                                </div>
                                <div v-else class="col-lg-3 col-md-2 col-sm-2 col-2">
                                    {{item.name_updater}}
                                </div>
                                <div class="col-lg-3 col-md-5 col-sm-5">
                                  <button @click="btnEdit(item.id_subject)" v-b-modal.modal-edit class="px-3 btn btn-edit">Edit</button>
                                  <button @click="btnDelete(item.id_subject)" v-if="getDetailAcc.id_level === 1" class="px-2 btn mx-3 btn-delete">Hapus</button>
                                  <div v-if="getDetailAcc.id_level === 2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isError">
              <b-pagination
                align="right"
                class="mt-3 mx-2 pagination"
                @input="subject()"
                v-model="getPagination.page"
                pills
                :total-rows="getPagination.totalData"
                :per-page="getPagination.limit"
                size="sm"
              >
              </b-pagination>
            </div>
            <div v-else>
              <b-pagination
                align="right"
                class="mt-3 pagination"
                pills
                total-rows="1"
                per-page="1"
                size="sm"
              >
              </b-pagination>
            </div>
        </div>
      </div>
      <!-- Modal Edit -->
      <b-modal id="modal-edit"
      ref="modal-edit"
      hide-header-close hide-footer hide-header centered>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Edit Subjek</h3>
        <h5 class="fw-bold">Subjek</h5>
        <input class="inputText" v-model="getDetail.subject_name" type="text">
        <button class="btn cancelBtn" @click="hideModal()">Cancel</button>
        <button class="btn editBtn" @click="btnSave()">Save</button>
      </div>
      </b-modal>
      <!-- Modal Insert Data -->
      <b-modal id="modal-add"
      ref="modal-add"
      hide-header-close hide-footer hide-header centered>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Subjek</h3>
        <h5 class="fw-bold">Subjek</h5>
        <input class="inputText" v-model="form.subject_name" type="text" placeholder="Tambahkan Subjek">
        <button class="btn cancelBtn" @click="hideModal()">Cancel</button>
        <button class="btn editBtn" @click="btnInsert()">Save</button>
      </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import componentProfile from './Profile.vue'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
export default {
  data () {
    return {
      subject_name: '',
      form: {
        subject_name: ''
      }
    }
  },
  mixins: [alert],
  components: {
    componentProfile
  },
  computed: {
    ...mapGetters({
      getSubject: 'subject/getSubject',
      getPagination: 'subject/getPagination',
      isError: 'subject/isError',
      errMessage: 'subject/errMessage',
      getDetail: 'subject/getDetail',
      getId: 'auth/getId',
      getDetailAcc: 'account/getDetailAcc'
    })
  },
  methods: {
    ...mapActions({
      actionSubject: 'subject/actionSubject',
      actionDetail: 'subject/actionDetail',
      actionEdit: 'subject/actionEdit',
      actionDetailAcc: 'account/actionDetail',
      actionDelete: 'subject/actionDelete',
      actionInsert: 'subject/actionInsert'
    }),
    subject () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1,
        subject_name: this.subject_name ? this.subject_name : '',
        limit: 5
      }
      this.actionSubject(data)
    },
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    btnSearch () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1,
        subject_name: this.subject_name ? this.subject_name : '',
        limit: 5
      }
      this.actionSubject(data)
    },
    btnEdit (id) {
      this.actionDetail(id)
    },
    btnSave () {
      const data = {
        id_subject: this.getDetail.id_subject,
        subject_name: this.getDetail.subject_name,
        id_subject_updater: this.getId
      }
      this.actionEdit(data).then((response) => {
        this.ToastSuccess('Data berhasil diperbaharui.')
        this.hideModal()
        this.subject()
      }).catch(() => {})
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.subject()
          }).catch(() => {})
        }
      })
    },
    btnInsert () {
      const data = {
        subject_name: this.form.subject_name,
        id_subject_creator: this.getId
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.subject()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-edit'].hide()
      this.$refs['modal-add'].hide()
      this.form.subject_name = ''
    }
  },
  mounted () {
    this.subject()
  }
}
</script>

<style scoped>
.bottom{
    border-radius: 10px;
}
</style>
