import axios from 'axios'
const moduleQuestionSNPMB = {
  namespaced: true,
  state: () => {
    return {
      listQuestionSNPMB: [],
      pagination: {},
      detailQuestionSNPMB: {},
      isError: false,
      errMessage: '',
      allQuestionSNPMB: '',
      inputSearch: []
    }
  },
  mutations: {
    mutationQuestionSNPMB (state, payload) {
      state.listQuestionSNPMB = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailQuestionSNPMB = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setAllQuestionSNPMB (state, payload) {
      state.allQuestionSNPMB = payload
    },
    setInputSearch (state, payload) {
      state.inputSearch = payload
    }
  },
  actions: {
    actionQuestionSNPMB (context, data) {
      axios.get(`${context.rootState.setURL}/v1/snpmb/question?page=${data.page}&limit=${data.limit}&category_id=${data.category_id}&status=${data.status}&label=${data.label}&id_question_creator=${data.id_question_creator}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationQuestionSNPMB', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/snpmb/question/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/snpmb/question`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/snpmb/question/${data.id_question}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionInputSearch (context, data) {
      context.commit('setInputSearch', data)
    }
    // actionDelete (context, id) {
    //   return new Promise((resolve, reject) => {
    //     axios.delete(`${context.rootState.setURL}/v1/snpmb/question/${id}`,
    //       { headers: { token: context.rootState.auth.token } }).then((response) => {
    //       resolve(response)
    //     }).catch(() => {})
    //   })
    // }
  },
  getters: {
    getQuestionSNPMB (state) {
      return state.listQuestionSNPMB
    },
    getPaginationQuestionSNPMB (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailQuestionSNPMB
    },
    isErrorQuestionSNPMB (state) {
      return state.isError
    },
    errMessageQuestionSNPMB (state) {
      return state.errMessage
    },
    getAllQuestionSNPMB (state) {
      return state.allQuestionSNPMB
    },
    getInputSearch (state) {
      return state.inputSearch
    }
  }
}

export default moduleQuestionSNPMB
