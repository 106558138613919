import Swal from 'sweetalert2'
const alert = {
  data: () => {
    return {
      image: require('@/assets/img/delete.svg'),
      config: Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    }
  },
  methods: {
    ToastSuccess (message) {
      this.config.fire({
        icon: 'success',
        title: message
      })
    },
    ToastError (message) {
      this.config.fire({
        icon: 'error',
        title: message
      })
    },
    AlertError (message) {
      Swal.fire({
        icon: 'error',
        title: message
      })
    }
  }
}
export default alert
