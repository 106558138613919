import axios from 'axios'
const moduleAnswer = {
  namespaced: true,
  state: () => {
    return {
      listAnswer: [],
      pagination: {},
      detailAnswer: {},
      isError: false,
      errMessage: ''
    }
  },
  mutations: {
    mutationAnswer (state, payload) {
      state.listAnswer = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailAnswer = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    }
  },
  actions: {
    actionAnswer (context, data) {
      axios.get(`${context.rootState.setURL}/v1/answer`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationAnswer', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/answer/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/answer`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/answer/${data.id_answer}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/answer/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getAnswer (state) {
      return state.listAnswer
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailAnswer
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    }
  }
}

export default moduleAnswer
