import axios from 'axios'
const moduleStatementQuestion = {
  namespaced: true,
  state: () => {
    return {
      listStatementQuestion: [],
      detailStatementQuestion: {},
      isError: false,
      errMessage: ''
    }
  },
  mutations: {
    mutationStatementQuestion (state, payload) {
      state.listStatementQuestion = payload
    },
    setDetail (state, payload) {
      state.detailStatementQuestion = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    }
  },
  actions: {
    actionStatementQuestion (context, data) {
      axios.get(`${context.rootState.setURL}/v1/snpmb/statement-question`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationStatementQuestion', response.data.data)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/snpmb/statement-question/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data)
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/snpmb/statement-question`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/snpmb/statement-question/${data.id}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/snpmb/statement-question/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getStatementQuestion (state) {
      return state.listStatementQuestion
    },
    getDetail (state) {
      return state.detailStatementQuestion
    },
    isErrorStatementQuestion (state) {
      return state.isError
    },
    errMessageStatementQuestion (state) {
      return state.errMessage
    }
  }
}

export default moduleStatementQuestion
