<template>
    <div>
        <b-modal
        id="modal-addAnswerEssay"
        ref="modal-addAnswerEssay"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Jawaban</h3>
            <h5 class="fw-bold">Jawaban</h5>
            <textarea class="inputInsert" v-model="form.answer" cols="52" rows="5" placeholder="Inputkan jawaban"></textarea>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id'],
  mixins: [alert],
  data () {
    return {
      form: {
        answer: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      getPagination: 'question/getPagination',
      getDetail: 'question/getDetail',
      getInputSearch: 'question/getInputSearch'
    })
  },
  methods: {
    ...mapActions({
      actionEdit: 'question/actionEdit',
      actionQuestion: 'question/actionQuestion'
    }),
    btnInsert () {
      const data = {
        id_question: this.id,
        qt_id: this.getDetail.qt_id,
        question_text: this.getDetail.question_text,
        subject_id: this.getDetail.subject_id,
        class_id: this.getDetail.class_id,
        category_id: this.getDetail.category_id,
        topic_id: this.getDetail.topic_id,
        ql_id: this.getDetail.id_qtn_level,
        answer: this.form.answer
      }
      this.actionEdit(data).then((response) => {
        this.hideModal()
        this.question()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    question () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1,
        question_code: this.getInputSearch.question_code ? this.getInputSearch.question_code : '',
        subject_id: this.getInputSearch.subject_id ? this.getInputSearch.subject_id : '',
        category_id: this.getInputSearch.category_id ? this.getInputSearch.category_id : '',
        class_id: this.getInputSearch.class_id ? this.getInputSearch.class_id : '',
        topic_id: this.getInputSearch.topic_id ? this.getInputSearch.topic_id : '',
        ql_id: this.getInputSearch.ql_id ? this.getInputSearch.ql_id : '',
        id_question_creator: this.getInputSearch.id_question_creator ? this.getInputSearch.id_question_creator : '',
        status: this.getInputSearch.status ? this.getInputSearch.status : '',
        label: this.getInputSearch.label ? this.getInputSearch.label : ''
      }
      this.actionQuestion(data)
    },
    hideModal () {
      this.$refs['modal-addAnswerEssay'].hide()
      this.form = {
        answer: ''
      }
    }
  }
}
</script>
