<template>
    <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Literasi</h3>
        <div v-for="(item, i) in getAllTotalLiterasiByIndicator" :key="'literasi'+i">
          <div class="mt-5 mb-2">
            <u class="fw-bold">{{ item.category_name }}</u>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Materi</th>
                <th scope="col">Mengevaluasi dan Merefleksi</th>
                <th scope="col">Memahami</th>
                <th scope="col">Menemukan Informasi</th>
                <th scope="col">Pemahaman</th>
                <th scope="col">Penerapan</th>
                <th scope="col">Penalaran</th>
                <th scope="col">Default</th>
              </tr>
            </thead>
            <tbody v-for="topics in item.topic" :key="topics.id_topic">
              <tr>
                <td>{{ topics.topic_name }}</td>
                <td v-for="indicators in topics.indicator" :key="indicators.id">
                  {{indicators.total[0].total}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- ================================================================== -->
        <h3 class="text-center mt-5 mb-4 fw-bolder">Numerasi</h3>
        <div v-for="(item, i) in getAllTotalNumerasiByIndicator" :key="'numerasi'+i">
          <div class="mt-5 mb-2">
            <u class="fw-bold">{{ item.category_name }}</u>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Materi</th>
                <th scope="col">Mengevaluasi dan Merefleksi</th>
                <th scope="col">Memahami</th>
                <th scope="col">Menemukan Informasi</th>
                <th scope="col">Pemahaman</th>
                <th scope="col">Penerapan</th>
                <th scope="col">Penalaran</th>
                <th scope="col">Default</th>
              </tr>
            </thead>
            <tbody v-for="topics in item.topic" :key="topics.id_topic">
              <tr>
                <td>{{ topics.topic_name }}</td>
                <td v-for="indicators in topics.indicator" :key="indicators.id">
                  {{indicators.total[0].total}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getAllTotalLiterasi: 'question/getAllTotalLiterasi',
      getAllTotalNumerasi: 'question/getAllTotalNumerasi',
      getTotalAllQtn: 'question/getTotalAllQtn',
      getAllTotalLiterasiByIndicator: 'question/getAllTotalLiterasiByIndicator',
      getAllTotalNumerasiByIndicator: 'question/getAllTotalNumerasiByIndicator'
    })
  },
  methods: {
    ...mapActions({
      actionTotalByIndicator: 'question/actionTotalByIndicator'
    }),
    indicator () {
      this.actionTotalByIndicator()
    }
  },
  mounted () {
    this.indicator()
  }
}

</script>
