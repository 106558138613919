import axios from 'axios'
const moduleSelection = {
  namespaced: true,
  state: () => {
    return {
      listSelection: [],
      searchSelection: [],
      pagination: {},
      detailSelection: {},
      isError: false,
      errMessage: '',
      errorSearch: false,
      errMessageSearch: ''
    }
  },
  mutations: {
    mutationSelection (state, payload) {
      state.listSelection = payload
    },
    setSearchSelection (state, payload) {
      state.searchSelection = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailSelection = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setErrorSearch (state, payload) {
      state.errorSearch = payload
    },
    setErrMsgSearch (state, payload) {
      state.errMessageSearch = payload
    }
  },
  actions: {
    actionSelection (context, data) {
      axios.get(`${context.rootState.setURL}/v1/selection`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationSelection', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionSearchSelection (context, questionId) {
      axios.get(`${context.rootState.setURL}/v1/searchSelection?question_id=${questionId}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setSearchSelection', response.data.data)
        context.commit('setErrorSearch', false)
      }).catch(() => {
        context.commit('setErrorSearch', true)
        context.commit('setErrMsgSearch', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/selection/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/selection`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/selection/${data.id_selection}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/selection/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    },
    deleteImage (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${context.rootState.setURL}/v1/selectionImage/${id}`, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getSelection (state) {
      return state.listSelection
    },
    getSearchSelection (state) {
      return state.searchSelection
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailSelection
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    },
    searchError (state) {
      return state.errorSearch
    },
    searchErrMessage (state) {
      return state.errMessageSearch
    }
  }
}

export default moduleSelection
