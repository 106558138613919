<template>
    <div>
        <b-modal
        id="modal-duplicate"
        ref="modal-duplicate"
        size="lg"
        hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Soal</h3>
            <!-- Indikator -->
            <h5 class="fw-bold">Indikator</h5>
            <select
            v-model="getDetail.indicator_id"
            class="form-select form-select-sm mb-3 insertSelect" required
            >
              <option v-for="(el, i) in getIndicator" :key="i" :value="el.id">
                {{el.name}}
              </option>
            </select>
            <h5 class="fw-bold">Kelas</h5>
              <select
              v-model="getDetail.class_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">
                    {{el.class_name}}
                  </option>
              </select>
              <h5 class="fw-bold">Materi</h5>
              <select
              @change="subtopic"
              v-model="getDetail.topic_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option v-for="(el, i) in getAllTopic" :key="i" :value="el.id_topic">
                    {{el.topic_name}}
                  </option>
              </select>
              <!-- Subtopic -->
              <h5 class="fw-bold">Sub-materi</h5>
              <div v-if="isErrorSubtopic">
                <select v-model="getDetail.subtopic_id" class="form-select form-select-sm mb-3 insertSelect" required>
                  <option value="''">
                    Tambahkan sub-materi terlebih dahulu
                  </option>
                </select>
              </div>
              <div v-else>
                <select
                v-model="getDetail.subtopic_id"
                class="form-select form-select-sm mb-3 insertSelect" required
                >
                    <option value="" disabled>Pilih Sub-materi</option>
                    <option v-for="(el, i) in getSubtopic" :key="i" :value="el.id">
                      {{el.name}}
                    </option>
                </select>
              </div>
              <h5 class="fw-bold">Tipe Soal</h5>
              <select
              v-model="getDetail.qt_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="1">Esai</option>
                  <option value="2">Pilihan Berganda</option>
                  <option value="3">Jawaban Singkat</option>
              </select>
              <h5 class="fw-bold">Level Soal</h5>
              <select
              v-model="getDetail.id_qtn_level"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option v-for="(el, i) in getQtnLevel" :key="i" :value="el.id">
                    {{el.qtn_level_name}}
                  </option>
              </select>
              <!-- Label Soal -->
              <h5 class="fw-bold">Label Soal</h5>
              <select
              v-model="getDetail.label"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="tryout">Tryout</option>
                  <option value="exam">Exam</option>
                  <option value="daily_question">Daily Question</option>
              </select>
              <h5 class="fw-bold">Soal</h5>
              <!-- <textarea class="inputInsert" v-model="getDetail.question_text" cols="52" rows="5" placeholder="Inputkan soal"></textarea> -->
              <vue-editor :key="getDetail.id_qtn_level" useCustomImageHandler @image-added="handleImageAdded" v-model="getDetail.question_text"></vue-editor>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="cancel()">Batalkan</button>
                  <button class="btn editBtn" @click="btnSave()">Simpan</button>
                </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
import imgConvert from '../helper/base64'
import { VueEditor } from 'vue2-editor'
import axios from 'axios'
export default {
  data () {
    return {
      question_id: '',
      serverURL: process.env.VUE_APP_URL,
      imgArr: []
    }
  },
  components: {
    VueEditor
  },
  mixins: [alert, imgConvert],
  props: ['idParamsURL'],
  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      getAllSubject: 'subject/getAllSubject',
      getAllClass: 'class/getAllClass',
      getAllCategory: 'category/getAllCategory',
      getAllTopic: 'topic/getAllTopic',
      getPagination: 'question/getPagination',
      getDetail: 'question/getDetail',
      getQuestionImageId: 'questionImage/getQuestionImageId', // image for duplicate
      getQtnLevel: 'questionLevel/getQtnLevel',
      getToken: 'auth/getToken',
      errImg: 'questionImage/errImg',
      getSubtopic: 'subtopic/getSubtopic',
      isErrorSubtopic: 'subtopic/isError',
      getIndicator: 'indicator/getIndicator'
    })
  },
  methods: {
    ...mapActions({
      allSubject: 'subject/allSubject',
      actAllClass: 'class/actAllClass',
      actAllCategory: 'category/actAllCategory',
      actionAllTopic: 'topic/actionAllTopic',
      actionInsert: 'question/actionInsert',
      actionQuestionChild: 'question/actionQuestionChild',
      actionDuplicateImage: 'questionImage/actionDuplicateImage',
      actionQuestionImage: 'questionImage/actionQuestionImage',
      actionQtnLevel: 'questionLevel/actionQtnLevel',
      actionInsertParent: 'groupParent/actionInsert',
      actionInsertChild: 'groupChild/actionInsert',
      actionEdit: 'question/actionEdit',
      actionEditQuestionID: 'questionImage/actionEditQuestionID',
      actionSubtopic: 'subtopic/actionSubtopic',
      actionIndicator: 'indicator/actionIndicator'
    }),
    indicator () {
      const data = {
        page: '',
        name: '',
        limit: ''
      }
      this.actionIndicator(data)
    },
    subtopic () {
      const data = {
        topic_id: this.getDetail.topic_id ? this.getDetail.topic_id : ''
      }
      this.actionSubtopic(data)
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      var formData = new FormData()
      formData.append('image', file)
      formData.append('question_id', '0')

      axios({
        url: `${this.serverURL}/v1/question-image`,
        method: 'POST',
        data: formData,
        headers: { token: this.getToken }
      })
        .then(result => {
          const url = result.data.data.image // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
          this.imgArr.push(url)
        })
        .catch({})
    },
    async cancel () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/question-imageURL`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgArr = []
      }
      this.hideModal()
      this.subtopic()
    },
    question () {
      this.actionQuestionChild(this.idParamsURL)
    },
    btnSave () {
      const data = {
        qt_id: this.getDetail.qt_id,
        indicator_id: this.getDetail.indicator_id,
        question_text: this.getDetail.question_text,
        class_id: this.getDetail.class_id,
        topic_id: this.getDetail.topic_id,
        subtopic_id: this.getDetail.subtopic_id,
        ql_id: this.getDetail.id_qtn_level,
        label: this.getDetail.label,
        id_question_creator: this.getId
      }

      this.actionInsert(data).then(async (response) => {
        this.question()
        this.question_id = response.data[0].id_question // New question_id

        const dataParent = {
          question_id: this.idParamsURL
        }
        await this.actionInsertParent(dataParent).then((response) => { // Add parent id
          this.question()
          const dataChild = {
            question_id: this.question_id,
            parent_id: response.data[0].id
          }
          this.actionInsertChild(dataChild).then((response) => { // Add child id
            this.question()
          }).catch(() => {})
        }).catch(() => {})

        // Filter data from vue-editor
        for (let i = 0; i < this.imgArr.length; i++) {
          const data = {
            question_id: response.data[0].id_question,
            image: this.imgArr[i]
          }
          await this.actionEditQuestionID(data).then(async (response) => {}).catch({})
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.getDetail.question_text.includes(el)
          })
          if (filtered[i]) {
            const dataDelete = {
              image: filtered[i]
            }
            await axios({
              url: `${this.serverURL}/v1/question-imageURL`,
              method: 'DELETE',
              data: dataDelete,
              headers: { token: this.getToken }
            })
              .then({})
              .catch({})
          }
        }
        // Filter data from previous image
        if (this.getQuestionImageId && this.errImg === false) {
          const qtn = this.getQuestionImageId
          const duplicate = qtn.filter((el) => {
            return this.getDetail.question_text.includes(el.image)
          })
          const dataImage = []
          await duplicate.map(async (el) => {
            if (el.image) {
              dataImage.push({
                image: el.image,
                question_id: this.question_id
              })
            }
          })
          if (dataImage) {
            setTimeout(async () => {
              await this.actionDuplicateImage(dataImage).then(async (response) => { // Duplicate Image
                // Edit image on question
                let question = this.getDetail.question_text
                let qtnSplit = ''
                for (let i = 0; i < dataImage.length; i++) {
                  qtnSplit = question.split(duplicate[i].image).join(response.data[i])
                  const dataEdit = {
                    id_question: this.question_id,
                    qt_id: this.getDetail.qt_id,
                    indicator_id: this.getDetail.indicator_id,
                    question_text: qtnSplit,
                    class_id: this.getDetail.class_id,
                    topic_id: this.getDetail.topic_id,
                    subtopic_id: this.getDetail.subtopic_id,
                    ql_id: this.getDetail.id_qtn_level,
                    label: this.getDetail.label,
                    id_question_updater: this.getId,
                    status: this.getDetail.status
                  }
                  await this.actionEdit(dataEdit).then(() => {}).catch(() => {})
                  question = dataEdit.question_text
                }
              }).catch(() => {})
            }, 1000)
          }
        }
        this.actionQuestionImage()
        this.question()
        this.hideModal()
        this.subtopic()
        this.imgArr = []
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-duplicate'].hide()
    }
  },
  mounted () {
    this.question()
    this.allSubject()
    this.actAllClass()
    this.actAllCategory()
    this.actionAllTopic()
    this.actionQtnLevel()
    this.subtopic()
    this.indicator()
  }
}
</script>
