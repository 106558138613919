import axios from 'axios'
const moduleCategory = {
  namespaced: true,
  state: () => {
    return {
      listCategory: [],
      pagination: {},
      detailCategory: {},
      isError: false,
      errMessage: '',
      allCategory: '',
      categorySNPMB: []
    }
  },
  mutations: {
    mutationCategory (state, payload) {
      state.listCategory = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailCategory = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setAllCategory (state, payload) {
      state.allCategory = payload
    },
    setCategorySNPMB (state, payload) {
      state.categorySNPMB = payload
    }
  },
  actions: {
    actionCategory (context, data) {
      axios.get(`${context.rootState.setURL}/v1/category?page=${data.page}&category_name=${data.category_name}&limit=${data.limit}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationCategory', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionCategorySNPMB (context, data) {
      axios.get(`${context.rootState.setURL}/v1/category?product_type=2`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setCategorySNPMB', response.data.data)
      }).catch(() => {
      })
    },
    actAllCategory (context, data) {
      axios.get(`${context.rootState.setURL}/v1/category`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllCategory', response.data.data)
      }).catch(() => {
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/category/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/category`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/category/${data.id_category}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/category/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    },
    // Upload image to S3
    uploadImageCategory (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/category/image`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    }
  },
  getters: {
    getCategory (state) {
      return state.listCategory
    },
    getCategorySNPMB (state) {
      return state.categorySNPMB
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailCategory
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    },
    getAllCategory (state) {
      return state.allCategory
    }
  }
}

export default moduleCategory
