<template>
  <div>
    <b-modal
      id="modal-addBanner"
      ref="modal-addBanner"
      hide-header-close
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Banner</h3>
        <h5 class="fw-bold mt-3 mb-1">Deskripsi</h5>
        <textarea
          v-model="form.description"
          class="inputInsert"
          cols="25"
          rows="3"
          placeholder="Inputkan deskripsi"
        ></textarea>
        <h5 class="fw-bold mt-2 mb-1">Link</h5>
        <input v-model="form.target" class="inputInsert" placeholder="Inputkan link" type="text" />
        <h5 class="fw-bold mt-3 mb-2">Gambar</h5>
        <input
          @change="uploadFile($event)"
          type="file"
          class="form-control fileBtn"
          accept="image/png, image/jpg, image/jpeg"
        />
        <div class="text-center mt-3">
          <button @click="hideModal()" class="btn cancelBtn">Batalkan</button>
          <button @click="btnInsert()" class="btn editBtn">Simpan</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  mixins: [alert],
  data () {
    return {
      form: {
        description: '',
        target: '',
        image: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      getPagination: 'banner/getPagination'
    })
  },
  methods: {
    ...mapActions({
      actionBanner: 'banner/actionBanner',
      actionInsert: 'banner/actionInsert'
    }),
    banner () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1
      }
      this.actionBanner(data)
    },
    uploadFile (el) {
      this.form.image = el.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.form.image = reader.result
      }
    },
    btnInsert () {
      const data = {
        description: this.form.description,
        target: this.form.target,
        image: this.form.image
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.banner()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    hideModal () {
      this.$refs['modal-addBanner'].hide()
      this.form = {
        description: '',
        target: '',
        image: ''
      }
    }
  }
}
</script>
