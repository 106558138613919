<template>
    <div>
        <b-modal
        id="modal-addShortAnswer"
        ref="modal-addShortAnswer"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Jawaban</h3>
            <h5 class="fw-bold">Jawaban</h5>
            <input v-model="form.short_answer_text" class="inputInsert" placeholder="Inputkan Jawaban" />
            <h5 class="mt-3 fw-bold">Batas Bilangan Atas</h5>
            <input v-model="form.first_range" class="inputInsert" placeholder="Inputkan Batas Bilangan Atas" />
            <h5 class="mt-3 fw-bold">Batas Bilangan Bawah</h5>
            <input v-model="form.second_range" class="inputInsert" placeholder="Inputkan Batas Bilangan Bawah" />
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id'],
  mixins: [alert],
  data () {
    return {
      form: {
        short_answer_text: '',
        first_range: '',
        second_range: '',
        question_id: ''
      }
    }
  },
  methods: {
    ...mapActions({
      actionInsert: 'shortAnswer/actionInsert',
      actionShortAnswer: 'shortAnswer/actionShortAnswer'
    }),
    btnInsert () {
      const data = {
        short_answer_text: this.form.short_answer_text,
        question_id: this.id,
        first_range: this.form.first_range,
        second_range: this.form.second_range
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.actionShortAnswer()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    hideModal () {
      this.$refs['modal-addShortAnswer'].hide()
      this.form = {
        short_answer_text: '',
        first_range: '',
        second_range: '',
        question_id: ''
      }
    }
  },
  mounted () {
    this.actionShortAnswer()
  }
}
</script>
