<template>
  <div>
    <b-modal
      id="modal-addTopicSNPMB"
      ref="modal-addTopicSNPMB"
      size="lg"
      hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Materi</h3>
        <!-- Topic Name -->
        <h5 class="fw-bold">Topik</h5>
        <input class="inputInsert" v-model="form.topic_name" type="text" placeholder="Input judul materi">
        <!-- Detail Topic -->
        <h5 class="fw-bold mt-3">Detail</h5>
        <vue-editor :key="category_id" useCustomImageHandler @image-added="handleImageAdded" v-model="form.detail_topic"></vue-editor>
        <div class="text-center mt-3">
          <button class="btn cancelBtn" @click="cancelInsert()">Batalkan</button>
          <button class="btn editBtn" @click="btnInsert()">Simpan</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import imgConvert from '../helper/base64'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
export default {
  data () {
    return {
      category_id: '',
      form: {
        topic_name: '',
        detail_topic: ''
      },
      serverURL: process.env.VUE_APP_URL,
      imgArr: []
    }
  },
  mixins: [alert, mathJax, imgConvert],
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      getCategorySNPMB: 'category/getCategorySNPMB',
      getToken: 'auth/getToken',
      getId: 'auth/getId'
    })
  },
  methods: {
    ...mapActions({
      actionCategorySNPMB: 'category/actionCategorySNPMB',
      uploadImageTopic: 'topic/uploadImageTopic',
      actionInsert: 'topic/actionInsert',
      actionTopicSNPMB: 'topic/actionTopicSNPMB'
    }),
    topic () {
      const data = {
        category_id: this.category_id
      }
      this.actionTopicSNPMB(data)
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      this.uploadImageTopic(data).then(result => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgArr.push(url)
      }).catch(() => {})
    },
    async cancelInsert () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/topicImage`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgArr = []
      }
      this.hideModal()
    },
    async btnInsert () {
      const images = this.imgArr
      const dataInsert = images.filter((el) => {
        return this.form.detail_topic.includes(el)
      })
      const data = {
        category_id: this.category_id,
        topic_name: this.form.topic_name,
        detail_topic: this.form.detail_topic,
        image: dataInsert.join(),
        id_topic_creator: this.getId
      }
      console.log(data)

      await this.actionInsert(data).then(async () => {
        for (let i = 0; i < this.imgArr.length; i++) {
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.form.detail_topic.includes(el)
          })
          if (filtered[i]) {
            const dataDelete = {
              image: filtered[i]
            }
            await axios({
              url: `${this.serverURL}/v1/topicImage`,
              method: 'DELETE',
              data: dataDelete,
              headers: { token: this.getToken }
            })
              .then({})
              .catch({})
          }
        }
        this.imgArr = []
        this.hideModal()
        this.topic()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        console.log(err)
        this.AlertError('Harap isi semua kolom!!')
      })
    },
    hideModal () {
      this.$refs['modal-addTopicSNPMB'].hide()
      this.form = {
        category_id: '',
        topic_name: '',
        detail_topic: ''
      }
    }
  },
  mounted () {
    this.actionCategorySNPMB()
    this.category_id = this.$route.params.id
  }
}
</script>
