<template>
    <div>
        <componentNavbar />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import componentNavbar from '../components/Navbar.vue'
export default {
  components: {
    componentNavbar
  },
  computed: {
    ...mapGetters({
    })
  }
}
</script>
