<template>
    <div>
        <b-modal
        id="modal-addAnswerChild"
        ref="modal-addAnswerChild"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Jawaban</h3>
            <h5 class="fw-bold">Jawaban</h5>
            <select
              v-model="form.selection_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
              <option value="" disabled>Pilihan</option>
              <option v-for="(el, i) in getSearchSelection" :key="i" :value="el.id_selection">
                    {{el.selection_text}}
              </option>
            </select>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id'],
  mixins: [alert],
  data () {
    return {
      form: {
        selection_id: '',
        question_id: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      getSearchSelection: 'selection/getSearchSelection'
    })
  },
  methods: {
    ...mapActions({
      actionInsert: 'answer/actionInsert',
      actionAnswer: 'answer/actionAnswer',
      actionSearchSelection: 'selection/actionSearchSelection'
    }),
    btnInsert () {
      const data = {
        selection_id: Number(this.form.selection_id),
        question_id: Number(this.id)
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.actionAnswer()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    hideModal () {
      this.$refs['modal-addAnswerChild'].hide()
      this.form = {
        selection_id: '',
        question_id: ''
      }
    }
  },
  mounted () {
    this.actionAnswer()
  }
}
</script>
