<template>
    <div>
      <div class="container-fluid">
        <div class="row mt-2">
          <div class="col-lg-9 col-md-9 col-sm-9 col-9 mt-1">
            <input class="search" v-model="category_name" @keyup="btnSearch()" type="search" placeholder="Cari Kategori ...">
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-3">
              <componentProfile></componentProfile>
          </div>
        </div>
        <div class="row">
          <h1 class="col-lg-3 col-md-3 col-sm-3 text-white fw-bold mt-3">Kategori</h1>
          <div class="col-lg-5 col-md-4 col-sm-5"></div>
          <div class="col-lg-3 col-md-4 col-sm-3 mt-3 text-end mx-3">
            <button v-b-modal.modal-addCategory class="btn btn-add">
              <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Kategori
            </button>
          </div>
        </div>
        <div class="row">
            <div class="col-lg-11 white mt-2">
                <div class="row">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-7 col-md-7 mt-5">
                        <h4 class="fw-bolder grey-font">Semua Kategori</h4>
                        <h5 class="grey-font mt-4">Silahkan memilih Kategori</h5>
                    </div>
                    <div class="col-lg-4 col-md-4 d-none d-xl-block">
                        <img class="img-subjek img-responsive" src="../assets/img/girl.svg" alt="Eureka">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid mt-5 pt-3 text-start">
                <div class="row top-table bg-white" style="width: 100%;">
                    <div class="col-lg-3 col-md-2 col-sm-2 col-3">Kategori</div>
                    <div class="col-lg-1 col-md-2 col-sm-2 col-3">Kode</div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-1">Kelas</div>
                    <div class="col-lg-2 col-md-3 col-sm-3 col-3">Dibuat Oleh</div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-3">Diperbaharui Oleh</div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-2">Aksi</div>
                </div>
            </div>
            <div class="container-fluid text-start mt-3">
                <div class="row bg-white bottom" style="width: 100%;">
                    <div v-if="isError">{{errMessage}}</div>
                    <div v-else>
                        <div v-for="(item, i) in getCategory" :key="i">
                            <div class="row my-2">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-3">{{item.category_name}}</div>
                                <div class="col-lg-1 col-md-1 col-sm-1 col-1">{{item.code}}</div>
                                <div class="col-lg-1 col-md-1 col-sm-1 col-1">{{item.class_name}}</div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-2">{{item.name_creator}}</div>
                                <div class="col-lg-2 col-md-3 col-sm-3 col-3" v-if="!item.name_updater">
                                    -
                                </div>
                                <div v-else class="col-lg-2 col-md-2 col-sm-2 col-2">
                                    {{item.name_updater}}
                                </div>
                                <div class="col-lg-3 col-md-5 col-sm-5">
                                  <button @click="btnEdit(item.id_category)" v-b-modal.modal-editCategory class="px-3 btn btn-edit">Edit</button>
                                  <button @click="btnDelete(item.id_category)" class="px-2 btn mx-3 btn-delete">Hapus</button>
                                  <!-- <div v-if="getDetailAcc.id_level === 2"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isError">
              <b-pagination
                align="right"
                class="mt-3 mx-2 pagination"
                @input="category()"
                v-model="getPagination.page"
                pills
                :total-rows="getPagination.totalData"
                :per-page="getPagination.limit"
                size="sm"
              >
              </b-pagination>
            </div>
            <div v-else>
              <b-pagination
                align="right"
                class="mt-3 pagination"
                pills
                total-rows="1"
                per-page="1"
                size="sm"
              >
              </b-pagination>
            </div>
        </div>
      </div>
      <!-- Modal Edit Category -->
      <b-modal id="modal-editCategory"
      ref="modal-editCategory"
      hide-header-close hide-footer hide-header centered>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Edit Kategori</h3>
        <h5 class="fw-bold">Kategori</h5>
        <input class="inputText fw-bold" v-model="getDetail.category_name" type="text">
        <h5 class="fw-bold my-3">Subjek</h5>
        <select
          v-model="getDetail.subject_id"
          class="form-select form-select-sm my-1 insertSelect" required
          >
          <option value="" disabled>Pilihan</option>
          <option v-for="(el, i) in getSubject" :key="i" :value="el.id_subject">
                {{el.subject_name}}
          </option>
        </select>
        <button class="btn cancelBtn" @click="hideModal()">Cancel</button>
        <button class="btn editBtn" @click="btnSave()">Save</button>
      </div>
      </b-modal>
      <!-- Modal Insert Category -->
      <b-modal
      id="modal-addCategory"
      ref="modal-addCategory"
      hide-header-close hide-footer hide-header centered>
      <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Kategori</h3>
        <h5 class="fw-bold my-3">Subjek</h5>
        <select
          v-model="form.subject_id"
          class="form-select form-select-sm my-1 insertSelect" required
          >
          <option value="" disabled>Pilihan</option>
          <option v-for="(el, i) in getSubject" :key="i" :value="el.id_subject">
                {{el.subject_name}}
          </option>
        </select>
        <h5 class="fw-bold mt-3">Kategori</h5>
        <input class="inputText" v-model="form.category_name" type="text" placeholder="Tambahkan Kategori">
        <!-- Kelas -->
        <h5 class="fw-bold mt-3">Kelas</h5>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-2"
            v-model="form.class_id"
            :aria-describedby="ariaDescribedby"
            name="kelas"
          >
            <b-form-checkbox v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">Kelas {{el.class_name}}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <!--  -->
        <button class="btn cancelBtn" @click="hideModal()">Cancel</button>
        <button class="btn editBtn" @click="btnInsert()">Save</button>
      </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import componentProfile from './Profile.vue'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
export default {
  data () {
    return {
      category_name: '',
      form: {
        category_name: '',
        subject_id: '',
        class_id: []
      }
    }
  },
  mixins: [alert],
  components: {
    componentProfile
  },
  computed: {
    ...mapGetters({
      getCategory: 'category/getCategory',
      getPagination: 'category/getPagination',
      isError: 'category/isError',
      errMessage: 'category/errMessage',
      getId: 'auth/getId',
      getDetailAcc: 'account/getDetailAcc',
      getDetail: 'category/getDetail',
      getSubject: 'subject/getSubject',
      getAllClass: 'class/getAllClass'
    })
  },
  methods: {
    ...mapActions({
      actionCategory: 'category/actionCategory',
      actionDetailAcc: 'account/actionDetail',
      actionDelete: 'category/actionDelete',
      actionDetail: 'category/actionDetail',
      actionEdit: 'category/actionEdit',
      actionInsert: 'category/actionInsert',
      actionSubject: 'subject/actionSubject',
      actAllClass: 'class/actAllClass'
    }),
    category () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1,
        category_name: this.category_name ? this.category_name : '',
        limit: 5
      }
      this.actionCategory(data)
    },
    subject () {
      const data = {
        page: 1,
        subject_name: '',
        limit: ''
      }
      this.actionSubject(data)
    },
    class () {
      this.actAllClass()
    },
    btnSearch () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1,
        category_name: this.category_name ? this.category_name : '',
        limit: 5
      }
      this.actionCategory(data)
    },
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.category()
          }).catch(() => {})
        }
      })
    },
    btnEdit (id) {
      this.actionDetail(id)
    },
    btnSave () {
      const data = {
        id_category: this.getDetail.id_category,
        category_name: this.getDetail.category_name,
        subject_id: this.getDetail.subject_id,
        id_category_updater: this.getId
      }
      this.actionEdit(data).then((response) => {
        this.ToastSuccess('Data berhasil diperbaharui.')
        this.hideModal()
        this.category()
      }).catch(() => {})
    },
    btnInsert () {
      const data = {
        category_name: this.form.category_name,
        subject_id: this.form.subject_id,
        class_id: this.form.class_id.join(),
        id_category_creator: this.getId
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.category()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editCategory'].hide()
      this.$refs['modal-addCategory'].hide()
      this.form.category_name = ''
      this.form.subject_id = ''
      this.form.class_id = []
    }
  },
  mounted () {
    this.category()
  }
}
</script>

<style scoped>
.bottom{
    border-radius: 10px;
}
select{
  background: url('../assets/img/polygon.png') no-repeat right white;
  background-size: 10px 8px;
  -webkit-appearance: none;
  background-position-x: 90%;
  border: 2px solid rgba(0, 0, 0, 0.65);
  border-radius: 7px;
  padding: 5px 5px 5px 10px;
  width: 90%;
  margin: 5px 0px;
  height: 39px;
}
</style>
