import axios from 'axios'
const moduleFreeTrial = {
  namespaced: true,
  state: () => {
    return {
      listFreeTrial: [],
      isError: false,
      errMessage: ''
    }
  },
  mutations: {
    mutationFreeTrial (state, payload) {
      state.listFreeTrial = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    }
  },
  actions: {
    actionFreeTrial (context, id) {
      axios.get(`${context.rootState.setURL}/v1/free-trial/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationFreeTrial', response.data.data)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
      })
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/free-trial`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/free-trial/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getFreeTrial (state) {
      return state.listFreeTrial
    },
    isErrorFreeTrial (state) {
      return state.isError
    }
  }
}

export default moduleFreeTrial
