const mathJax = {
  data: () => {
    return {
    }
  },
  methods: {
    renderMathJax () {
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            displayMath: [['$$', '$$'], ['[', ']']],
            processEscapes: true,
            processEnvironments: true
          }
        })
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub
        ])
      }
    }
  }
}
export default mathJax
