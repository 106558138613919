<template>
    <div>
        <b-modal
        id="modal-addKeyword"
        ref="modal-addKeyword"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Keyword</h3>
            <h5 class="fw-bold">Keyword</h5>
            <textarea v-model="form.keyword_text" class="inputInsert" cols="52" rows="3" placeholder="Inputkan keyword"></textarea>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import alert from '../helper/alert'
export default {
  props: ['id'],
  mixins: [alert],
  data () {
    return {
      form: {
        keyword_text: '',
        question_id: ''
      }
    }
  },
  methods: {
    ...mapActions({
      actionInsert: 'keyword/actionInsert',
      actionKeyword: 'keyword/actionKeyword'
    }),
    btnInsert () {
      const data = {
        keyword_text: this.form.keyword_text,
        question_id: this.id
      }
      this.actionInsert(data).then((response) => {
        this.hideModal()
        this.actionKeyword()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    hideModal () {
      this.$refs['modal-addKeyword'].hide()
      this.form = {
        keyword_text: '',
        question_id: ''
      }
    }
  },
  mounted () {
    this.actionKeyword()
  }
}
</script>
