<template>
  <div>
    <b-modal
    id="modal-addSubtopic"
    ref="modal-addSubtopic"
    hide-header-close hide-footer hide-header centered no-close-on-backdrop>
    <div class="mx-4">
      <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Sub-materi</h3>
      <h5 class="fw-bold">Sub-materi</h5>
      <input v-model="form.name" class="inputInsert" placeholder="Inputkan sub-materi">
      <h5 class="fw-bold mt-3 mb-3">Detail</h5>
      <vue-editor :key="id" useCustomImageHandler @image-added="handleImageAdded" v-model="form.detail"></vue-editor>
      <div class="text-center mt-3">
        <button class="btn cancelBtn" @click="cancelInsert()">Batalkan</button>
        <button class="btn editBtn" @click="btnInsert()">Simpan</button>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import imgConvert from '../helper/base64'
// import Swal from 'sweetalert2'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      form: {
        name: '',
        detail: ''
      },
      serverURL: process.env.VUE_APP_URL,
      imgArr: []
    }
  },
  props: ['id'],
  mixins: [alert, mathJax, imgConvert],
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      getToken: 'auth/getToken'
    })
  },
  methods: {
    ...mapActions({
      actionInsert: 'subtopic/actionInsert',
      uploadImageSubtopic: 'subtopic/uploadImageSubtopic',
      actionGetSubtopic: 'subtopic/actionGetSubtopic'
    }),
    subtopic () {
      const data = {
        topic_id: this.id ? this.id : ''
      }
      this.actionGetSubtopic(data)
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      this.uploadImageSubtopic(data).then((result) => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgArr.push(url)
      }).catch(() => {})
    },
    async cancelInsert () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/subtopicImage`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgArr = []
      }
      this.hideModal()
    },
    async btnInsert () {
      const images = this.imgArr
      const dataInsert = images.filter((el) => {
        return this.form.detail.includes(el)
      })
      const data = {
        name: this.form.name,
        detail: this.form.detail,
        image: dataInsert.join(),
        topic_id: this.id,
        id_creator: this.getId
      }

      await this.actionInsert(data).then(async (response) => {
        for (let i = 0; i < this.imgArr.length; i++) {
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.form.detail.includes(el)
          })
          if (filtered[i]) {
            const dataDelete = {
              image: filtered[i]
            }
            await axios({
              url: `${this.serverURL}/v1/subtopicImage`,
              method: 'DELETE',
              data: dataDelete,
              headers: { token: this.getToken }
            })
              .then({})
              .catch({})
          }
        }
        this.imgArr = []
        this.hideModal()
        this.subtopic()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch(() => {
        this.AlertError('Harap isi semua kolom!')
      })
    },
    hideModal () {
      this.$refs['modal-addSubtopic'].hide()
      this.form = {
        name: '',
        detail: ''
      }
    }
  },
  mounted () {
    this.subtopic()
  }
}
</script>
