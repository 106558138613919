<template>
<div>
  <div v-if="loaded">
    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <!--  -->
    <div class="mt-5">
      <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData2"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    </div>
  </div>
  <div v-else>{{getAllTotalPerMonth}}</div>
</div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { mapActions, mapGetters } from 'vuex'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loaded: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    ...mapGetters({
      getAllTotalPerMonth: 'question/getAllTotalPerMonth'
    }),
    chartData () {
      return {
        labels: this.getAllTotalPerMonth.labelNumerasi,
        datasets: [
          {
            label: 'Numerasi',
            backgroundColor: 'black',
            data: this.getAllTotalPerMonth.dataNumerasi
          }
        ]
      }
    },
    chartData2 () {
      return {
        labels: this.getAllTotalPerMonth.labelLiterasi,
        datasets: [
          {
            label: 'Literasi',
            backgroundColor: 'black',
            data: this.getAllTotalPerMonth.dataLiterasi
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      actionTotalPerMonth: 'question/actionTotalPerMonth'
    }),
    total () {
      this.actionTotalPerMonth()
      this.loaded = true
    }
  },
  async mounted () {
    this.loaded = false
    await this.total()
  }
}
</script>
