<template>
  <div>
    <div class="container-fluid text-end">
      <button @click="$router.go(-1)" class="btn btn-back mt-4">
        <b-icon icon="arrow-left-circle-fill" style="margin-right: 7px;"></b-icon>Kembali
      </button><br>
      <button v-if="isError" v-b-modal.modal-addDiscussion class="btn btn-add mb-4">
        <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Pembahasan
      </button>
    </div>
    <div class="bg-white rounded p-2 grey m-4" v-if="isError">Tambahkan pembahasan.</div>
    <div v-else class="container-fluid pb-5" v-for="(item, i) in getDiscussion" :key="i">
      <div class="row bg-white content" style="margin: 0px;">
        <div class="row mb-5 pb-4">
          <div class="text-end">
              <button  @click="btnEdit(item.id_discussion)" v-b-modal.modal-editDiscussion class="btn btn-edit" style="width: 75px;">Edit</button><br>
              <button @click="btnDelete(item.id_discussion)" v-if="getDetailAcc.id_level === 1" class="btn btn-delete my-2" style="width: 75px;">Hapus</button><br>
              <div v-if="getDetailAcc.id_level === 2"></div>
          </div>
          <!-- <div v-if="item.image === 'image' || !item.image || isError"></div>
          <div v-else class="text-center">
              <img :src="`${item.image}`" class="img-fluid image my-2" /><br>
              <button @click="deleteImageDiscussion(item.id_discussion)" class="btn deleteImg">
              Hapus Gambar</button>
          </div> -->
          <div class="text-justify grey my-3">
            <div class="mx-1 htmlText" v-html="item.discussion_text"></div>
          </div>
          <div class="grey mt-3 mb-2">Dibuat Oleh : {{item.name_creator}}</div>
          <div v-if="!item.name_updater" class="grey">Diperbaharui Oleh : -</div>
          <div v-else class="grey">Diperbaharui Oleh : {{item.name_updater}}</div>
        </div>
      </div>
    </div>
    <!-- Modal Insert Discussion -->
    <b-modal
        id="modal-addDiscussion"
        ref="modal-addDiscussion"
        size="lg"
        hide-header-close hide-footer hide-header centered no-close-on-backdrop>
        <div class="mx-4">
          <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Pembahasan</h3>
            <h5 class="fw-bold">Pembahasan</h5>
            <!-- <textarea class="inputInsert" v-model="form.discussion_text" cols="52" rows="5" placeholder="Inputkan pembahasan"></textarea> -->
            <vue-editor useCustomImageHandler @image-added="handleImageAdded" v-model="form.discussion_text"></vue-editor>
            <!-- <h5 class="fw-bold mt-3">Gambar</h5>
              <input
                @change="uploadFile($event)"
                type="file"
                class="form-control fileBtn"
                accept="image/png, image/jpg, image/jpeg"
              /> -->
            <div class="text-center mt-3">
                <button class="btn cancelBtn" @click="cancelInsert()">Batalkan</button>
                <button class="btn editBtn" @click="btnInsert()">Simpan</button>
            </div>
        </div>
      </b-modal>
      <!-- Modal Edit Discussion -->
      <b-modal
        id="modal-editDiscussion"
        ref="modal-editDiscussion"
        size="lg"
        hide-header-close hide-footer hide-header centered no-close-on-backdrop>
        <div class="mx-4">
          <h3 class="text-center my-2 mb-4 fw-bolder">Edit Pembahasan</h3>
            <h5 class="fw-bold">Pembahasan</h5>
            <!-- <textarea class="inputInsert" v-model="getDetail.discussion_text" cols="52" rows="5" placeholder="Inputkan pembahasan"></textarea> -->
            <vue-editor useCustomImageHandler @image-added="handleImageEdit" v-model="getDetail.discussion_text"></vue-editor>
            <!-- <h5 class="fw-bold mt-3">Gambar</h5>
              <input
                id="uploadImage"
                @change="updateFile($event)"
                type="file"
                class="form-control fileBtn"
                accept="image/png, image/jpg, image/jpeg"
              /> -->
            <div class="text-center mt-3">
                <button class="btn cancelBtn" @click="cancelEdit()">Batalkan</button>
                <button class="btn editBtn" @click="btnSave()">Simpan</button>
            </div>
        </div>
      </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import { VueEditor } from 'vue2-editor'
import imgConvert from '../helper/base64'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
import axios from 'axios'
export default {
  mixins: [alert, mathJax, imgConvert],
  data () {
    return {
      serverURL: process.env.VUE_APP_URL,
      idSoal: '',
      form: {
        discussion_text: '',
        image: ''
      },
      // updateImage: '',
      pathValue: '',
      imgArr: [],
      imgEdit: []
    }
  },
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      getDetailAcc: 'account/getDetailAcc',
      getDiscussion: 'discussion/getDiscussion',
      getDetail: 'discussion/getDetail',
      isError: 'discussion/isError',
      errMessage: 'discussion/errMessage',
      getToken: 'auth/getToken'
    })
  },
  methods: {
    ...mapActions({
      actionDiscussion: 'discussion/actionDiscussion',
      actDetailDiscuss: 'discussion/actionDetail',
      actionInsert: 'discussion/actionInsert',
      actionEdit: 'discussion/actionEdit',
      actionDelete: 'discussion/actionDelete',
      deleteImage: 'discussion/deleteImage',
      uploadImage: 'discussion/uploadImage'
    }),
    discussion () {
      const data = {
        question_id: this.$route.params.idSoal
      }
      this.actionDiscussion(data)
    },
    // uploadFile (el) {
    //   this.form.image = el.target.files[0]
    //   const reader = new FileReader()
    //   reader.readAsDataURL(this.form.image)
    //   reader.onload = () => {
    //     this.form.image = reader.result
    //   }
    // },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      this.uploadImage(data).then(result => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgArr.push(url)
      }).catch(() => {})
    },
    async cancelInsert () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/discussionImage`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgArr = []
      }
      this.hideModal()
    },
    async btnInsert () {
      const images = this.imgArr
      const dataInsert = images.filter((el) => {
        return this.form.discussion_text.includes(el)
      })
      const data = {
        question_id: this.$route.params.idSoal,
        discussion_text: this.form.discussion_text,
        image: dataInsert.join(),
        id_discussion_creator: this.getId
      }

      await this.actionInsert(data).then(async (response) => {
        for (let i = 0; i < this.imgArr.length; i++) {
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.form.discussion_text.includes(el)
          })
          if (filtered[i]) {
            const dataDelete = {
              image: filtered[i]
            }
            await axios({
              url: `${this.serverURL}/v1/discussionImage`,
              method: 'DELETE',
              data: dataDelete,
              headers: { token: this.getToken }
            })
              .then({})
              .catch({})
          }
        }
        this.imgArr = []
        this.discussion()
        this.hideModal()
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    // updateFile (el) {
    //   this.pathValue = el.target.value
    //   this.updateImage = el.target.files[0]
    //   const reader = new FileReader()
    //   reader.readAsDataURL(this.updateImage)
    //   reader.onload = () => {
    //     this.updateImage = reader.result
    //   }
    // },
    async handleImageEdit (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      this.uploadImage(data).then(result => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgEdit.push(url)
      }).catch({})
    },
    btnSave () {
      const editImg = this.imgEdit
      const dataImageEdit = editImg.filter((el) => {
        return this.getDetail.discussion_text.includes(el)
      })
      const detailImg = this.getDetail.image.split(',')
      const detailEditImg = detailImg.filter((el) => {
        return this.getDetail.discussion_text.includes(el)
      })
      const data = {
        id_discussion: this.getDetail.id_discussion,
        discussion_text: this.getDetail.discussion_text,
        id_discussion_updater: this.getId,
        image: dataImageEdit.concat(detailEditImg).join()
      }
      // if (!this.pathValue) {
      // } else {
      //   data.image = this.updateImage
      // }

      this.actionEdit(data).then((response) => {
        if (this.getDetail.image || this.getDetail.image !== 'image') {
          const qtn = this.getDetail.image.split(',')
          const Imagefiltered = qtn.filter((el) => {
            return !this.getDetail.discussion_text.includes(el)
          })
          Imagefiltered.map(async (el) => {
            if (el) {
              const dataDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/discussionImage`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        } else {}
        // handleImageEdit
        if (this.imgEdit) {
          const images = this.imgEdit
          const dataFiltered = images.filter((el) => {
            return !this.getDetail.discussion_text.includes(el)
          })
          dataFiltered.map(async (el) => {
            if (el) {
              const imgDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/discussionImage`,
                method: 'DELETE',
                data: imgDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        }
        this.imgEdit = []
        this.discussion()
        this.hideModal()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    async cancelEdit () {
      if (this.imgEdit) {
        const images = this.imgEdit
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/discussionImage`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgEdit = []
      }
      this.hideModal()
    },
    btnEdit (id) {
      this.actDetailDiscuss(id)
    },
    deleteImageDiscussion (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus gambar ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          // this.updateImage = ''
          this.ToastSuccess('Gambar telah dihapus.')
          this.deleteImage(id).then((response) => {
            this.discussion()
          }).catch(() => {})
        }
      })
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.actionDelete(id).then((response) => {
            this.discussion()
          }).catch(() => {
          })
        }
      })
    },
    // cancel () {
    //   this.$refs['modal-editDiscussion'].hide()
    //   this.updateImage = ''
    // },
    hideModal () {
      this.$refs['modal-editDiscussion'].hide()
      this.$refs['modal-addDiscussion'].hide()
      this.form = {
        discussion_text: '',
        image: ''
      }
    }
  },
  watch: {
    getDiscussion () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    this.discussion()
    this.idSoal = this.$route.params.idSoal
  }
}
</script>

<style scoped>
.htmlText >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
  height: auto;
  border-radius: 7px;
}
.htmlText >>> {
  white-space: pre-wrap;
}
</style>
