import axios from 'axios'
const moduleAuth = {
  namespaced: true,
  state: () => {
    return {
      token: localStorage.getItem('token') || '',
      ac_name: localStorage.getItem('ac_name') || '',
      ac_id: localStorage.getItem('ac_id') || ''
    }
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload
    },
    setName (state, payload) {
      state.ac_name = payload
    },
    setId (state, payload) {
      state.ac_id = payload
    }
  },
  actions: {
    register (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/register`, data).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    login (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/login`, data).then((response) => {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('ac_name', response.data.ac_name)
          localStorage.setItem('ac_id', response.data.ac_id)
          context.commit('setToken', response.data.token)
          context.commit('setName', response.data.ac_name)
          context.commit('setId', response.data.ac_id)
          resolve(response)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    forgotPassword (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/forgotPassword`, data).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    logout (context) {
      return new Promise((resolve) => {
        localStorage.removeItem('token')
        localStorage.removeItem('ac_name')
        localStorage.removeItem('ac_id')
        context.commit('setToken', null)
        context.commit('setName', null)
        context.commit('setId', null)
        resolve(true)
      })
    }
  },
  getters: {
    getToken (state) {
      return state.token
    },
    getName (state) {
      return state.ac_name
    },
    getId (state) {
      return state.ac_id
    }
  }
}

export default moduleAuth
