<template>
    <div>
        <b-modal
          id="modal-editCategorySNPMB"
          ref="modal-editCategorySNPMB"
          size="lg"
          hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
        <div>
        </div>
        <div class="mx-4">
          <h3 class="text-center my-2 mb-4 fw-bolder">Edit Materi</h3>
          <h5 class="fw-bold">Materi</h5>
          <input type="text" class="inputInsert" v-model="getDetail.category_name">
          <h5 class="fw-bold mt-3">Detail</h5>
          <vue-editor :key="getDetail.id_category" useCustomImageHandler @image-added="handleImageEdit" v-model="getDetail.detail_category"></vue-editor>
          <div class="text-center mt-3">
            <button class="btn cancelBtn" @click="cancelEdit()">Batalkan</button>
            <button class="btn editBtn" @click="btnSave()">Simpan</button>
          </div>
      </div>
      </b-modal>
    </div>
</template>

<script>
import imgConvert from '../helper/base64'
import mathJax from '../helper/mathjax'
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  mixins: [alert, mathJax, imgConvert],
  data () {
    return {
      imgEdit: [],
      serverURL: process.env.VUE_APP_URL
    }
  },
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      getDetail: 'category/getDetail',
      getToken: 'auth/getToken',
      getId: 'auth/getId'
    })
  },
  methods: {
    ...mapActions({
      uploadImageCategory: 'category/uploadImageCategory',
      actionEdit: 'category/actionEdit',
      actionCategorySNPMB: 'category/actionCategorySNPMB'
    }),
    category () {
      this.actionCategorySNPMB()
    },
    async handleImageEdit (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      const data = {
        image: file
      }

      await this.uploadImageCategory(data).then(result => {
        const url = result.data // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
        this.imgEdit.push(url)
      }).catch()
    },
    async cancelEdit () {
      if (this.imgEdit) {
        const images = this.imgEdit
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/image/category`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgEdit = []
      }
      this.hideModal()
    },
    async btnSave () {
      const editImg = this.imgEdit
      const dataImageEdit = editImg.filter((el) => {
        return this.getDetail.detail_category.includes(el)
      })
      const detailImg = this.getDetail.image.split(',')

      const detailEditImg = detailImg.filter((el) => {
        return this.getDetail.detail_category.includes(el)
      })
      const data = {
        id_category: this.getDetail.id_category,
        category_name: this.getDetail.category_name,
        detail_category: this.getDetail.detail_category,
        id_category_updater: this.getId,
        image: dataImageEdit.concat(detailEditImg).join()
      }
      await this.actionEdit(data).then(async (response) => {
        if (this.getDetail.image || this.getDetail.image !== 'image') {
          const qtn = this.getDetail.image.split(',')
          const Imagefiltered = qtn.filter((el) => {
            return !this.getDetail.detail_category.includes(el)
          })
          Imagefiltered.map(async (el) => {
            if (el) {
              const dataDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/image/category`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        } else {}
        // handleImageEdit
        if (this.imgEdit) {
          const images = this.imgEdit
          const dataFiltered = images.filter((el) => {
            return !this.getDetail.detail_category.includes(el)
          })
          dataFiltered.map(async (el) => {
            if (el) {
              const imgDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/image/category`,
                method: 'DELETE',
                data: imgDelete,
                headers: { token: this.getToken }
              })
                .then({})
                .catch({})
            }
          })
        }
        this.imgEdit = []
        this.hideModal()
        this.category()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editCategorySNPMB'].hide()
    }
  },
  mounted () {
    this.category()
  }
}
</script>
