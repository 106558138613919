<template>
    <div>
        <div class="container-fluid">
            <!-- Search -->
            <div class="row mt-2">
                <div class="col-lg-9 col-md-9 col-sm-9 col-9 mt-1">
                    <input class="search" type="search" v-model="inputSearch.question_code" @keyup="question()" placeholder="Cari Kode Soal ...">
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <componentProfile></componentProfile>
                </div>
            </div>
            <!-- Insert Question -->
            <div class="row">
              <h1 class="col-lg-4 col-md-4 col-sm-4 col-4 text-white fw-bold mt-3">Soal</h1>
              <div class="col-lg-4 col-md-3 col-sm-1 col-1"></div>
              <div class="col-lg-3 col-md-4 col-sm-6 col-6 mx-4 mt-3 text-end">
                  <button v-b-modal.modal-addQuestion class="btn btn-add mt-3">
                  <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Soal
                  </button>
              </div>
            </div>
            <!-- Sorting -->
            <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                    <select
                    @change="question()"
                    v-model="inputSearch.subject_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Subjek</option>
                        <option v-for="(el, i) in getAllSubject" :key="i" :value="el.id_subject">
                          {{el.subject_name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                  <select
                    @change="question()"
                    v-model="inputSearch.class_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Kelas</option>
                        <option v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">
                          {{el.class_name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                  <select
                    @change="question()"
                    v-model="inputSearch.category_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Kategori</option>
                        <option v-for="(el, i) in getAllCategory" :key="i" :value="el.id_category">
                          {{el.category_name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                  <select
                    @change="question()"
                    v-model="inputSearch.topic_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Materi</option>
                        <option v-for="(el, i) in getAllTopic" :key="i" :value="el.id_topic">
                          {{el.topic_name}}
                        </option>
                    </select>
                </div>
                <!-- <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                  <select
                    @change="question()"
                    v-model="inputSearch.ql_id"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Level</option>
                        <option v-for="(el, i) in getQtnLevel" :key="i" :value="el.id">
                          {{el.qtn_level_name}}
                        </option>
                    </select>
                </div> -->
                <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                  <select
                    @change="question()"
                    v-model="inputSearch.id_question_creator"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Author</option>
                        <option v-for="(el, i) in getAllAccount" :key="i" :value="el.ac_id">
                          {{el.ac_name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                  <select
                    @change="question()"
                    v-model="inputSearch.status"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Status</option>
                        <option value="on-progress">On Progress</option>
                        <option value="approved">Approved</option>
                        <option value="published">Published</option>
                    </select>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4 col-4">
                  <select
                    @change="question()"
                    v-model="inputSearch.label"
                    class="form-select form-select-sm my-3 fw-bold"
                    >
                        <option value="">Pilih Label</option>
                        <option value="tryout">Tryout</option>
                        <option value="exam">Exam</option>
                        <option value="daily_question">Daily Question</option>
                        <option value="example_question">Example Question</option>
                    </select>
                </div>
            </div>
            <!-- End of Sorting -->
            <div class="row">
              <div class="col-lg-4">
                <button class="btn btn-discuss" style="width: 120px; height: 40px;" v-b-modal.modal-totalLiterasi>Literasi</button>
                <button class="btn btn-discuss m-2" style="width: 120px; height: 40px;" v-b-modal.modal-totalNumerasi>Numerasi</button>
                <!-- <div class="fw-bold">Total Soal Literasi :</div>
                <div class="fw-bold">Total Soal Numerasi :</div> -->
              </div>
            </div>
        </div>
        <div class="bg-white rounded p-2 grey m-4" v-if="isError">{{errMessage}}</div>
        <!-- Show Content -->
        <div v-else>
          <div v-for="(item, index) in getQuestion" :key="'question'+index" class="container-fluid">
              <!-- Grouping Questions -->
            <div class="row mt-3 mb-5 bg-white content" style="margin: 0px;">
                <div class="row mb-5 pb-4">
                  <div class="row col-lg-10">
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Kode Soal :</div> {{item.code}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Indikator :</div> {{item.indicator_name}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Subjek :</div> {{item.subject_name}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Kelas :</div> {{item.class_name}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Kategori :</div> {{item.category_name}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Materi :</div> {{item.topic_name}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Sub-materi :</div> {{item.subtopic_name}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Level :</div> {{item.qtn_level_name}}
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                        <div class="mb-2">Label :</div>
                        <div v-if="item.label === 'tryout'">Tryout</div>
                        <div v-if="item.label === 'exam'">Exam</div>
                        <div v-if="item.label === 'daily_question'">Daily Question</div>
                        <div v-if="item.label === 'example_question'">Example Question</div>
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Status :</div>
                          <div class="text-danger" v-if="item.status === 'on-progress'">On Progress</div>
                          <div class="text-success" v-if="item.status === 'approved'">Approved</div>
                          <div class="text-primary" v-if="item.status === 'published'">Published</div>
                      </div>
                      <div class="col-lg-2 col-md-2 grey">
                          <div class="mb-2">Fitur :</div>
                          <div class="text-primary" v-if="item.free_trial === 'true'">Free Trial</div>
                          <div class="text-success" v-if="item.free_trial === 'false'">Premium</div>
                      </div>
                  </div>
                  <div class="row col-lg-2">
                      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                          <button @click="btnEdit(item.id_question)" v-b-modal.modal-editSoal class="btn btn-edit my-2" style="width: 75px;">Edit</button><br>
                          <!-- <button @click="btnDelete(item.id_question)" v-if="getDetailAcc.id_level === 1" class="btn btn-delete" style="width: 75px;">Hapus</button> -->
                          <!-- Group -->
                          <!-- <a :href="$router.resolve({ name: 'Group', params: {id: item.id_question } }).href">
                            <button class="btn btn-delete" style="width: 75px;">Group</button>
                          </a> -->
                          <router-link :to="{ name: 'Group', params: {id: item.id_question } }"><button class="btn btn-delete" style="width: 75px;">Group</button></router-link>
                          <!-- Pembahasan -->
                          <!-- <br><a :href="$router.resolve({ name: 'Pembahasan', params: {idSoal: item.id_question } }).href">
                            <button class=" btn btn-discuss my-2" style="width: 120px;">Pembahasan</button>
                          </a> -->
                          <router-link :to="{ name: 'Pembahasan', params: {idSoal: item.id_question } }"><button class="my-2 btn btn-discuss" style="width: 120px;">Pembahasan</button></router-link>
                      </div>
                  </div>
                    <div class="row col-lg-10">
                        <hr class="line">
                    </div>
                    <!-- <h5 class="text-justify my-3 grey whitespace"><vue-mathjax :formula="item.question_text"></vue-mathjax></h5> -->
                    <h5 class="text-justify my-3 grey whitespace htmlText" v-html="item.question_text"></h5>
                    <div class="text-end my-2">
                      <!-- <button @click="addQuestionImage(item.id_question)" v-b-modal.modal-addQuestionImage style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                        <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Gambar
                      </button> -->
                    </div>
                    <!-- Image Content -->
                    <!-- <div v-for="(data, i) in getQuestionImage" :key="'questionImage'+i">
                      <div v-if="item.id_question === data.question_id">
                        <div v-if="isErrorQuestionImage"></div>
                        <div v-else>
                          <div class="text-center">
                              <img :src="`${data.image}`" class="img-fluid image my-2" /><br>
                              <button @click="btnDeleteQuestionImage(data.id)" class="btn deleteImg mb-4">
                                Hapus Gambar</button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- Selection (Multiple Choice) -->
                    <div v-if="item.qt_id === 2">
                      <div class="row mb-4">
                        <h5 class="col-lg-9 col-md-7 col-sm-5 col-5 mt-3 grey">Pilihan :</h5>
                        <div class="col-lg-3 col-md-5 col-sm-3 col-3 mt-2 text-end">
                          <button @click="addSelection(item.id_question)" v-b-modal.modal-addSelection style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                            <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Pilihan
                          </button>
                        </div>
                      </div>
                      <div v-if="isErrorSelection"></div>
                      <div v-else>
                        <div v-for="(el, i) in getSelection" :key="i" class="text-justify grey">
                          <div v-if="item.id_question === el.question_id" class="row">
                            <div class="col-lg-10 col-md-10 col-sm-9 col-9 whitespace">
                              <ul>
                              <li>
                                <vue-mathjax :formula="el.selection_text"></vue-mathjax>
                                <div v-if="!el.image || el.image === 'image'"></div>
                                <div v-else class="text-center">
                                  <img :src="`${el.image}`" class="img-fluid image my-2" /><br>
                                  <button @click="btnDeleteSelectionImage(el.id_selection)" class="btn deleteImg mb-4">
                                    Hapus Gambar</button>
                                </div>
                              </li>
                              </ul>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-3 col-1 text-end">
                              <b-icon @click="editSelection(el.id_selection)" v-b-modal.modal-editSelection class="pencilIcon" icon="pencil-square"></b-icon>
                              <b-icon @click="btnDeleteSelection(el.id_selection)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <h5 class="col-lg-8 col-md-8 col-sm-8 col-8 mt-3 grey">Jawaban :</h5>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-4 mt-2 text-end">
                          <button @click="addAnswer(item.id_question)" v-b-modal.modal-addAnswer style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                            <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Jawaban
                          </button>
                        </div>
                      </div>
                        <div v-if="isErrorAnswer"></div>
                        <!-- ---------------- -->
                        <div v-else>
                          <div v-for="(ans, i) in getAnswer" :key="'answer'+i">
                            <div v-if="item.id_question === ans.question_id" class="row">
                              <div class="col-lg-10 whitespace grey">
                              <ul>
                              <li>
                                <vue-mathjax :formula="ans.answer_text"></vue-mathjax>
                                <div v-if="!ans.image || ans.image === 'image'"></div>
                                <div v-else class="text-center">
                                  <img :src="`${ans.image}`" class="img-fluid image my-2" />
                                </div>
                              </li>
                              </ul>
                            </div>
                            <div class="col-lg-2 text-end">
                              <b-icon @click="btnDeleteAnswer(ans.id_answer)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                            </div>
                            </div>
                          </div>
                        </div>
                        <!-- ----------------------- -->
                    </div>
                    <!-- Keyword (Essay) -->
                    <div v-if="item.qt_id === 1">
                    <div class="row mb-4">
                        <h5 class="col-lg-8 mt-3 grey">Jawaban :</h5>
                        <div class="col-lg-4 mt-2 text-end">
                          <div v-if="!item.answer">
                              <button @click="addAnswerEssay(item.id_question)" v-b-modal.modal-addAnswerEssay style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                                <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Jawaban
                              </button>
                            </div>
                            <div v-else>
                              <button @click="editAnswerEssay(item.id_question)" v-b-modal.modal-editAnswerEssay style="border: 1px solid rgb(72, 167, 245); color: rgb(72, 167, 245);" class="btn btn-add">
                                <b-icon class="pencilIcon mx-2" icon="pencil-square"></b-icon>Edit Jawaban
                              </button>
                            </div>
                        </div>
                      </div>
                    <div class="grey whitespace my-3"><vue-mathjax :formula="item.answer"></vue-mathjax></div>
                      <div class="row mb-4">
                        <h5 class="col-lg-8 mt-3 grey">Keyword :</h5>
                        <div class="col-lg-4 mt-2 text-end">
                          <button @click="addKeyword(item.id_question)" v-b-modal.modal-addKeyword style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                            <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Keyword
                          </button>
                        </div>
                      </div>
                      <div v-if="isErrorKeyword"></div>
                      <div v-else>
                        <div v-for="(el, i) in getKeyword" :key="i" class="text-justify grey">
                          <div v-if="item.id_question === el.question_id" class="row">
                            <div class="col-lg-10 whitespace">
                              <ul>
                              <li><vue-mathjax :formula="el.keyword_text"></vue-mathjax></li>
                              </ul>
                            </div>
                            <div class="col-lg-2">
                              <b-icon @click="editKeyword(el.id_keyword)" v-b-modal.modal-editKeyword class="pencilIcon" icon="pencil-square"></b-icon>
                              <b-icon @click="btnDeleteKeyword(el.id_keyword)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Short Answer -->
                    <div v-if="item.qt_id === 3">
                      <div class="row mb-4">
                        <h5 class="col-lg-8 mt-3 grey">Jawaban :</h5>
                        <div class="col-lg-4 mt-2 text-end">
                          <button @click="addShortAnswer(item.id_question)" v-b-modal.modal-addShortAnswer style="border: 1px solid rgba(77, 198, 20, 0.6);" class="btn btn-add">
                            <b-icon class="icon-plus" icon="plus-circle-fill"></b-icon>Tambah Jawaban
                          </button>
                        </div>
                      </div>
                      <div v-if="isErrorShortAnswer"></div>
                      <div v-else>
                        <div v-for="(el, i) in getShortAnswer" :key="'shortAnswer'+i" class="text-justify grey">
                          <div v-if="item.id_question === el.question_id" class="row">
                            <div class="col-lg-10 whitespace">
                              <vue-mathjax :formula="el.short_answer_text"></vue-mathjax>
                            </div>
                            <div class="col-lg-2">
                              <b-icon @click="editShortAnswer(el.id_short_answer)" v-b-modal.modal-editShortAnswer class="pencilIcon" icon="pencil-square"></b-icon>
                              <b-icon @click="btnDeleteShortAnswer(el.id_short_answer)" class="icon-trash mx-3" icon="trash-fill"></b-icon>
                            </div>
                            <h5 class="grey my-4">Batas Bilangan Atas</h5>
                            <div>
                              <vue-mathjax :formula="el.first_range"></vue-mathjax>
                            </div>
                            <h5 class="grey my-4">Batas Bilangan Bawah</h5>
                            <div>
                              <vue-mathjax :formula="el.second_range"></vue-mathjax>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End -->
                    <div class="grey mt-3 mb-2">Dibuat Oleh : {{item.name_creator}}</div>
                    <div v-if="!item.name_updater" class="grey">Diperbaharui Oleh : -</div>
                    <div v-else class="grey">Diperbaharui Oleh : {{item.name_updater}}</div>
                </div>
            </div>
          </div>
        </div>
        <div v-if="!isError">
              <b-pagination
                align="right"
                class="mt-3 mx-2 pagination"
                @input="question()"
                v-model="getPagination.page"
                pills
                :total-rows="getPagination.totalData"
                :per-page="getPagination.limit"
                size="sm"
              >
              </b-pagination>
            </div>
            <div v-else>
              <b-pagination
                align="right"
                class="mt-3 pagination"
                pills
                total-rows="1"
                per-page="1"
                size="sm"
              >
              </b-pagination>
            </div>
        <!-- Modal Insert Question -->
        <b-modal
        id="modal-addQuestion"
        ref="modal-addQuestion"
        size="lg"
        hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Soal</h3>
            <!-- Indikator -->
            <h5 class="fw-bold">Indikator</h5>
            <select
            v-model="form.indicator_id"
            class="form-select form-select-sm mb-3 insertSelect" required
            >
              <option value="" disabled>Pilih Indikator</option>
              <option v-for="(el, i) in getIndicator" :key="i" :value="el.id">
                {{el.name}}
              </option>
            </select>
            <!-- Kelas -->
            <h5 class="fw-bold">Kelas</h5>
              <select
              v-model="form.class_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Kelas</option>
                  <option v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">
                    {{el.class_name}}
                  </option>
              </select>
              <h5 class="fw-bold">Materi</h5>
              <select
              @change="subtopic"
              v-model="form.topic_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Materi</option>
                  <option v-for="(el, i) in getAllTopic" :key="i" :value="el.id_topic">
                    {{el.topic_name}}
                  </option>
              </select>
              <!-- Subtopic -->
              <h5 class="fw-bold">Sub-materi</h5>
              <div v-if="isErrorSubtopic">
                <select class="form-select form-select-sm mb-3 insertSelect" required>
                  <option value="" disabled>
                    Tambahkan sub-materi terlebih dahulu
                  </option>
                </select>
              </div>
              <div v-else>
                <select
                v-model="form.subtopic_id"
                class="form-select form-select-sm mb-3 insertSelect" required
                >
                    <option value="" disabled>Pilih Sub-materi</option>
                    <option v-for="(el, i) in getSubtopic" :key="i" :value="el.id">
                      {{el.name}}
                    </option>
                </select>
              </div>
              <h5 class="fw-bold">Tipe Soal</h5>
              <select
              v-model="form.qt_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Tipe Soal</option>
                  <option value="1">Esai</option>
                  <option value="2">Pilihan Berganda</option>
                  <option value="3">Jawaban Singkat</option>
              </select>
              <h5 class="fw-bold">Level Soal</h5>
              <select
              v-model="form.ql_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Level</option>
                  <option v-for="(el, i) in getQtnLevel" :key="i" :value="el.id">
                    {{el.qtn_level_name}}
                  </option>
              </select>
              <!-- Label Soal -->
              <h5 class="fw-bold">Label Soal</h5>
              <select
              v-model="form.label"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Label</option>
                  <option value="tryout">Tryout</option>
                  <option value="exam">Exam</option>
                  <option value="daily_question">Daily Question</option>
                  <option value="example_question">Example Question</option>
              </select>
              <!-- Free Trial / Premium -->
              <h5 class="fw-bold">Fitur</h5>
              <input type="radio" id="free_trial" value="free_trial" v-model="feature" />
              <label for="free_trial" class="ms-2">Free trial</label> <br>

              <input type="radio" id="premium" value="premium" v-model="feature" />
              <label for="premium" class="ms-2">Premium</label>
              <!--  -->
              <h5 class="fw-bold mt-2">Soal</h5>
              <vue-editor :key="form.qt_id" useCustomImageHandler @image-added="handleImageAdded" v-model="form.question_text"></vue-editor>
              <!-- <textarea class="inputInsert" v-model="form.question_text" cols="52" rows="5" placeholder="Inputkan soal"></textarea> -->
              <div class="text-center mt-3">
                <button class="btn cancelBtn" @click="cancel()">Batalkan</button>
                <button class="btn editBtn" @click="btnInsert()">Simpan</button>
              </div>
        </div>
      </b-modal>
      <modalEditSoal :id="idEdit" />
      <modalAddSelection :id="idQuestion" />
      <modalEditSelection />
      <modelAddKeyword :id="idQuestionKeyword" />
      <modalEditKeyword />
      <modalAddAnswer :id="idQuestionAnswer" />
      <modalAddAnswerEssay :id="idQuestionEssay" />
      <modalEditAnswerEssay :id="idAnswerEssay" />
      <modalAddShortAnswer :id="idShortAnswer" />
      <modalEditShortAnswer :id="idShortAnswerEdit" />
      <modalTotal />
      <!-- <modalAddQuestionImage :id="idQuestionImage" /> -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import componentProfile from './Profile.vue'
import modalEditSoal from './m-editSoal.vue'
import modalAddSelection from './m-addSelection.vue'
import modalEditSelection from './m-editSelection.vue'
import modelAddKeyword from './m-addKeyword.vue'
import modalEditKeyword from './m-editKeyword.vue'
import modalAddAnswer from './m-addAnswer.vue'
import modalAddAnswerEssay from './m-addAnswerEssay.vue'
import modalEditAnswerEssay from './m-editAnswerEssay.vue'
import modalAddShortAnswer from './m-addShortAnswer.vue'
import modalEditShortAnswer from './m-editShortAnswer.vue'
import modalTotal from './m-total.vue'
// import modalAddQuestionImage from './m-addQuestionImage.vue'
import { VueEditor } from 'vue2-editor'
import Swal from 'sweetalert2'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
import imgConvert from '../helper/base64'
import axios from 'axios'
export default {
  data () {
    return {
      idQuestion: '',
      idQuestionKeyword: '',
      idQuestionAnswer: '',
      idQuestionEssay: '',
      idAnswerEssay: '',
      idShortAnswer: '',
      idShortAnswerEdit: '',
      idEdit: '',
      // idQuestionImage: '',
      inputSearch: {
        question_code: '',
        subject_id: '',
        category_id: '',
        class_id: '',
        topic_id: '',
        ql_id: '',
        id_question_creator: '',
        status: '',
        label: ''
      },
      form: {
        qt_id: '',
        question_text: '',
        indicator_id: '',
        class_id: '',
        topic_id: '',
        subtopic_id: '',
        ql_id: '',
        label: '',
        answer: ''
      },
      serverURL: process.env.VUE_APP_URL,
      htmlForEditor: '',
      imgArr: [],
      feature: ''
    }
  },
  mixins: [alert, mathJax, imgConvert],
  components: {
    componentProfile,
    modalEditSoal,
    modalAddSelection,
    modalEditSelection,
    modelAddKeyword,
    modalEditKeyword,
    modalAddAnswer,
    modalAddAnswerEssay,
    modalEditAnswerEssay,
    modalAddShortAnswer,
    modalEditShortAnswer,
    modalTotal,
    // modalAddQuestionImage,
    VueEditor
  },
  computed: {
    ...mapGetters({
      getQuestion: 'question/getQuestion',
      getPagination: 'question/getPagination',
      getId: 'auth/getId',
      getDetailAcc: 'account/getDetailAcc',
      isError: 'question/isError',
      errMessage: 'question/errMessage',
      getAllSubject: 'subject/getAllSubject',
      getAllClass: 'class/getAllClass',
      getAllCategory: 'category/getAllCategory',
      getAllTopic: 'topic/getAllTopic',
      getSelection: 'selection/getSelection',
      isErrorSelection: 'selection/isError',
      getKeyword: 'keyword/getKeyword',
      isErrorKeyword: 'keyword/isError',
      getAnswer: 'answer/getAnswer',
      isErrorAnswer: 'answer/isError',
      getShortAnswer: 'shortAnswer/getShortAnswer',
      isErrorShortAnswer: 'shortAnswer/isError',
      getQuestionImage: 'questionImage/getQuestionImage',
      isErrorQuestionImage: 'questionImage/isError',
      getQtnLevel: 'questionLevel/getQtnLevel',
      getGroupChild: 'groupChild/getGroupChild',
      getGroupParent: 'groupParent/getGroupParent',
      getAllAccount: 'account/getAllAccount',
      getInputSearch: 'question/getInputSearch',
      getToken: 'auth/getToken',
      getSubtopic: 'subtopic/getSubtopic',
      isErrorSubtopic: 'subtopic/isError',
      getIndicator: 'indicator/getIndicator',
      getFreeTrial: 'freeTrial/getFreeTrial'
    })
  },
  methods: {
    ...mapActions({
      actionDetailAcc: 'account/actionDetail',
      actionQuestion: 'question/actionQuestion',
      allSubject: 'subject/allSubject',
      actAllClass: 'class/actAllClass',
      actAllCategory: 'category/actAllCategory',
      actionAllTopic: 'topic/actionAllTopic',
      actionInsert: 'question/actionInsert',
      actionDetail: 'question/actionDetail',
      actionDelete: 'question/actionDelete',
      actionSelection: 'selection/actionSelection',
      actionDetailSelection: 'selection/actionDetail',
      actionDeleteSelection: 'selection/actionDelete',
      actionKeyword: 'keyword/actionKeyword',
      actionDetailKeyword: 'keyword/actionDetail',
      actionDeleteKeyword: 'keyword/actionDelete',
      actionAnswer: 'answer/actionAnswer',
      actionSearchSelection: 'selection/actionSearchSelection',
      actionDeleteAnswer: 'answer/actionDelete',
      actionShortAnswer: 'shortAnswer/actionShortAnswer',
      actionDetailShortAnswer: 'shortAnswer/actionDetail',
      actionDeleteShortAnswer: 'shortAnswer/actionDelete',
      actionQuestionImage: 'questionImage/actionQuestionImage',
      actionDeleteQuestionImage: 'questionImage/actionDelete',
      actionQuestionImageId: 'questionImage/actionQuestionImageId',
      actionQtnLevel: 'questionLevel/actionQtnLevel',
      actionDeleteSelectionImage: 'selection/deleteImage',
      actionGroupChild: 'groupChild/actionGroupChild',
      actionGroupParent: 'groupParent/actionGroupParent',
      actionAllAccount: 'account/actionAllAccount',
      actionInputSeacrh: 'question/actionInputSeacrh',
      actionEditQuestionID: 'questionImage/actionEditQuestionID',
      actionDeleteWithURL: 'questionImage/actionDeleteWithURL',
      actionAllTotal: 'question/actionAllTotal',
      actionSubtopic: 'subtopic/actionSubtopic',
      actionIndicator: 'indicator/actionIndicator',
      actionInsertFreeTrial: 'freeTrial/actionInsert',
      actionFreeTrial: 'freeTrial/actionFreeTrial'
    }),
    indicator () {
      const data = {
        page: '',
        name: '',
        limit: ''
      }
      this.actionIndicator(data)
    },
    subtopic () {
      const data = {
        topic_id: this.form.topic_id ? this.form.topic_id : ''
      }
      this.actionSubtopic(data)
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      var formData = new FormData()
      formData.append('image', file)
      formData.append('question_id', '0')

      axios({
        url: `${this.serverURL}/v1/question-image`,
        method: 'POST',
        data: formData,
        headers: { token: this.getToken }
      })
        .then(result => {
          const url = result.data.data.image // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
          this.imgArr.push(url)
        })
        .catch({})
    },
    async cancel () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/question-imageURL`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgArr = []
      } else {}
      this.hideModal()
      this.subtopic()
    },
    btnInsert () { // delete dari imgArr
      const data = {
        qt_id: this.form.qt_id,
        question_text: this.form.question_text,
        indicator_id: this.form.indicator_id,
        class_id: this.form.class_id,
        topic_id: this.form.topic_id,
        subtopic_id: this.form.subtopic_id,
        ql_id: this.form.ql_id,
        label: this.form.label,
        id_question_creator: this.getId
      }
      if (this.isErrorSubtopic) {
        data.subtopic_id = ''
      }
      this.actionInsert(data).then(async (response) => {
        // Edit question_id in image
        for (let i = 0; i < this.imgArr.length; i++) {
          const data = {
            question_id: response.data[0].id_question,
            image: this.imgArr[i]
          }
          await this.actionEditQuestionID(data).then(async (response) => {}).catch({})
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.form.question_text.includes(el)
          })
          if (filtered[i]) {
            const dataDelete = {
              image: filtered[i] // bandingin data yg ada di Arr dengan data yang ada di question form.question_text
            }
            await axios({
              url: `${this.serverURL}/v1/question-imageURL`,
              method: 'DELETE',
              data: dataDelete,
              headers: { token: this.getToken }
            })
              .then({})
              .catch({})
          }
        }
        if (this.feature === 'free_trial') {
          const dataFreeTrial = {
            item_id: response.data[0].id_question,
            type: 'question'
          }
          this.actionInsertFreeTrial(dataFreeTrial).then(async (response) => {}).catch(() => {})
        }
        this.question()
        this.hideModal()
        this.subtopic()
        this.imgArr = []
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    },
    search () {
      let data = []
      data = this.inputSearch
      this.actionInputSeacrh(data)
    },
    question () {
      const data = {
        page: this.getPagination.page ? this.getPagination.page : 1,
        question_code: this.getInputSearch.question_code ? this.getInputSearch.question_code : '',
        subject_id: this.getInputSearch.subject_id ? this.getInputSearch.subject_id : '',
        category_id: this.getInputSearch.category_id ? this.getInputSearch.category_id : '',
        class_id: this.getInputSearch.class_id ? this.getInputSearch.class_id : '',
        topic_id: this.getInputSearch.topic_id ? this.getInputSearch.topic_id : '',
        ql_id: this.getInputSearch.ql_id ? this.getInputSearch.ql_id : '',
        id_question_creator: this.getInputSearch.id_question_creator ? this.getInputSearch.id_question_creator : '',
        status: this.getInputSearch.status ? this.getInputSearch.status : '',
        label: this.getInputSearch.label ? this.getInputSearch.label : ''
      }
      this.actionQuestion(data)
    },
    btnDelete (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDelete(id).then((response) => {
            this.question()
          }).catch(() => {})
        }
      })
    },
    btnEdit (id) {
      this.actionDetail(id)
      this.idEdit = id
      this.actionQuestionImageId(id)
      this.actionFreeTrial(id)
      this.subtopic()
    },
    groupChild () {
      this.actionGroupChild()
    },
    selection () {
      this.actionSelection()
    },
    keyword () {
      this.actionKeyword()
    },
    shortAnswer () {
      this.actionShortAnswer()
    },
    answer () {
      this.actionAnswer()
    },
    questionImage () {
      this.actionQuestionImage()
    },
    totalQuestion () {
      // this.actionTotalAllQtn()
      this.actionAllTotal()
    },
    detailAcc () {
      this.actionDetailAcc(this.getId)
    },
    addSelection (id) {
      this.idQuestion = id
    },
    addShortAnswer (id) {
      this.idShortAnswer = id
    },
    addKeyword (id) {
      this.idQuestionKeyword = id
    },
    // addQuestionImage (id) {
    //   this.idQuestionImage = id
    // },
    addAnswer (id) {
      this.idQuestionAnswer = id
      this.actionSearchSelection(id)
    },
    addAnswerEssay (id) {
      this.idQuestionEssay = id
      this.actionDetail(id)
    },
    editSelection (id) {
      this.actionDetailSelection(id)
    },
    editKeyword (id) {
      this.actionDetailKeyword(id)
    },
    editAnswerEssay (id) {
      this.actionDetail(id)
      this.idAnswerEssay = id
    },
    editShortAnswer (id) {
      this.actionDetailShortAnswer(id)
      this.idShortAnswerEdit = id
    },
    btnDeleteSelection (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteSelection(id).then((response) => {
            this.answer()
            this.selection()
          }).catch(() => {})
          this.answer()
        }
      })
    },
    btnDeleteKeyword (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteKeyword(id).then((response) => {
            this.keyword()
          }).catch(() => {})
        }
      })
    },
    btnDeleteShortAnswer (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteShortAnswer(id).then((response) => {
            this.shortAnswer()
          }).catch(() => {})
        }
      })
    },
    // btnDeleteQuestionImage (id) {
    //   Swal.fire({
    //     title: 'Apakah anda yakin ingin menghapus gambar ini?',
    //     imageWidth: 100,
    //     imageHeight: 100,
    //     imageUrl: this.image,
    //     showCancelButton: true,
    //     confirmButtonColor: '#D51A1A',
    //     cancelButtonColor: '#4DAB14',
    //     confirmButtonText: 'Hapus',
    //     cancelButtonText: 'Batalkan'
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.ToastSuccess('Gambar anda telah dihapus.')
    //       this.actionDeleteQuestionImage(id).then((response) => {
    //         this.questionImage()
    //       }).catch(() => {})
    //     }
    //   })
    // },
    btnDeleteSelectionImage (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus gambar ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Gambar anda telah dihapus.')
          this.actionDeleteSelectionImage(id).then((response) => {
            this.selection()
            this.answer()
          }).catch(() => {})
        }
      })
    },
    btnDeleteAnswer (id) {
      Swal.fire({
        title: 'Apakah anda yakin ingin menghapus data ini?',
        imageWidth: 100,
        imageHeight: 100,
        imageUrl: this.image,
        showCancelButton: true,
        confirmButtonColor: '#D51A1A',
        cancelButtonColor: '#4DAB14',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan'
      }).then((result) => {
        if (result.isConfirmed) {
          this.ToastSuccess('Data anda telah dihapus.')
          this.actionDeleteAnswer(id).then((response) => {
            this.answer()
          }).catch(() => {})
        }
      })
    },
    hideModal () {
      this.$refs['modal-addQuestion'].hide()
      this.form = {
        qt_id: '',
        question_text: '',
        image: 'image',
        indicator_id: '',
        class_id: '',
        topic_id: '',
        subtopic_id: '',
        ql_id: '',
        label: '',
        answer: ''
      }
      this.feature = ''
    }
  },
  watch: {
    getQuestion () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getSelection () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getAnswer () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getShortAnswer () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    },
    getKeyword () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    this.question()
    this.allSubject()
    this.actAllClass()
    this.actAllCategory()
    this.actionAllTopic()
    this.selection()
    this.keyword()
    this.answer()
    this.shortAnswer()
    this.actionQtnLevel()
    this.groupChild()
    this.actionAllAccount()
    this.search()
    this.totalQuestion()
    this.subtopic()
    this.indicator()
  }
}
</script>

<style scoped>
.htmlText >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
  height: auto;
  border-radius: 7px;
}
.htmlText >>> {
  white-space: pre-wrap;
}
select{
  background: url('../assets/img/polygon.png') no-repeat right white;
  background-size: 10px 8px;
  -webkit-appearance: none;
  background-position-x: 90%;
}
.polygon{
  margin-top: 3px;
  width: 30px;
  height: 30px;
}
</style>
