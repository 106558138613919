import axios from 'axios'
const moduleBanner = {
  namespaced: true,
  state: () => {
    return {
      listBanner: [],
      pagination: {},
      detailBanner: {},
      isError: false
    }
  },
  mutations: {
    mutationBanner (state, payload) {
      state.listBanner = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailBanner = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    }
  },
  actions: {
    actionBanner (context, data) {
      axios.get(`${context.rootState.setURL}/v1/banner?page=${data.page}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationBanner', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/banner/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch({})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/banner`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/banner/${data.id}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/banner/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch({})
      })
    }
  },
  getters: {
    getBanner (state) {
      return state.listBanner
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailBanner
    },
    isError (state) {
      return state.isError
    }
  }
}

export default moduleBanner
