import axios from 'axios'
const moduleStatementAnswer = {
  namespaced: true,
  state: () => {
    return {
      listStatementAnswer: [],
      detailStatementAnswer: {},
      isError: false,
      errMessage: '',
      allStatementAnswer: '',
      isErrorAll: false,
      errMessageAll: ''
    }
  },
  mutations: {
    mutationStatementAnswer (state, payload) {
      state.listStatementAnswer = payload
    },
    setDetail (state, payload) {
      state.detailStatementAnswer = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setAllStatementAnswer (state, payload) {
      state.allStatementAnswer = payload
    },
    setIsErrorAll (state, payload) {
      state.isErrorAll = payload
    },
    setErrMessageAll (state, payload) {
      state.errMessageAll = payload
    }
  },
  actions: {
    actionStatementAnswer (context, data) {
      axios.get(`${context.rootState.setURL}/v1/snpmb/statement-answer?question_id=${data.id_question}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationStatementAnswer', response.data.data)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionAllStatementAnswer (context, data) {
      axios.get(`${context.rootState.setURL}/v1/snpmb/all-statement-answer`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllStatementAnswer', response.data.data)
        context.commit('setIsErrorAll', false)
      }).catch(() => {
        context.commit('setIsErrorAll', true)
        context.commit('setErrMessageAll', 'Data tidak ditemukan.')
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/snpmb/statement-answer/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data)
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/snpmb/statement-answer`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/snpmb/statement-answer/${data.id}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/snpmb/statement-answer/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getStatementAnswer (state) {
      return state.listStatementAnswer
    },
    getDetail (state) {
      return state.detailStatementAnswer
    },
    isErrorStatementAnswer (state) {
      return state.isError
    },
    errMessageStatementAnswer (state) {
      return state.errMessage
    },
    getAllStatementAnswer (state) {
      return state.allStatementAnswer
    },
    getIsErrorAll (state) {
      return state.isErrorAll
    },
    getErrMessageAll (state) {
      return state.errMessageAll
    }
  }
}

export default moduleStatementAnswer
