<template>
  <div>
    <div class="container-fluid text-end">
      <button @click="$router.go(-1)" class="btn btn-back mt-4">
        <b-icon icon="arrow-left-circle-fill" style="margin-right: 7px;" ></b-icon>Kembali
      </button><br>
      <button v-b-modal.modal-addTopicSNPMB class="btn btn-add mx-3" style="width: auto">Tambah Materi</button>
    </div>
    <div class="bg-white rounded p-2 grey m-4" v-if="isErrorSNPMB">{{errMsgSNPMB}}</div>
    <div v-else>
      <div v-for="(item, i) in getTopicSNPMB" :key="i" class="container-fluid">
        <div class="row mt-3 mb-5 bg-white content" style="margin: 0px;">
            <div class="row mb-5 pb-4">
              <div class="col-lg-2 col-md-3 col-sm-4 col-6 grey">
                  <div class="mb-2">Subjek :</div> {{item.subject_name}}
              </div>
              <div class="col-lg-3 col-md-3 col-sm-4 col-6 grey">
                  <div class="mb-2">Kategori :</div> {{item.category_name}}
              </div>
              <div class="col-lg-2 col-md-3 col-sm-4 col-6 grey">
                  <div class="mb-2">Kode :</div> {{item.code}}
              </div>
              <div class="col-lg-3 col-md-3 col-sm-4 col-6">
                  <button @click="btnEdit(item.id_topic)" v-b-modal.modal-editTopicSNPMB class="btn btn-edit mt-2" style="width: 75px;">Edit</button><br>
                  <!-- <button @click="btnDelete(item.id_topic)" v-if="getDetailAcc.id_level === 1" class="btn btn-delete mt-2" style="width: 75px;">Hapus</button> -->
                  <!-- <div v-if="getDetailAcc.id_level === 2"></div> -->
              </div>
              <div class="row">
                  <div class="col-lg-9"><hr class="line"></div>
              </div>
              <h1 class="text-center my-3">{{item.topic_name}}</h1>
              <div class="text-justify whitespace grey my-3 htmlText" v-html="item.detail_topic"></div>
              <div class="grey mt-3 mb-2">Dibuat Oleh : {{item.name_creator}}</div>
              <div v-if="!item.name_updater" class="grey">Diperbaharui Oleh : -</div>
              <div v-else class="grey">Diperbaharui Oleh : {{item.name_updater}}</div>
            </div>
        </div>
      </div>
    </div>
    <modalAddTopicSNPMB />
    <modalEditTopicSNPMB />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
import imgConvert from '../helper/base64'
import modalAddTopicSNPMB from '../components/m-addTopicSNPMB.vue'
import modalEditTopicSNPMB from '../components/m-editTopicSNPMB.vue'

export default {
  data () {
    return {
      category_id: this.$route.params.id
    }
  },
  components: {
    modalAddTopicSNPMB,
    modalEditTopicSNPMB
  },
  mixins: [alert, mathJax, imgConvert],
  computed: {
    ...mapGetters({
      getTopicSNPMB: 'topic/getTopicSNPMB',
      isErrorSNPMB: 'topic/isErrorSNPMB',
      errMsgSNPMB: 'topic/errMsgSNPMB'
    })
  },
  methods: {
    ...mapActions({
      actionTopicSNPMB: 'topic/actionTopicSNPMB',
      actionDetail: 'topic/actionDetail'
    }),
    topic () {
      const data = {
        category_id: this.category_id
      }
      this.actionTopicSNPMB(data)
    },
    btnEdit (id) {
      this.actionDetail(id)
    }
  },
  mounted () {
    this.topic()
  }
}
</script>

<style scoped>
.htmlText >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 370px;
  height: auto;
  border-radius: 7px;
}
.htmlText >>> {
  white-space: pre-wrap;
}
</style>
