import axios from 'axios'
const moduleGroupChild = {
  namespaced: true,
  state: () => {
    return {
      listGroupChild: [],
      pagination: {},
      detailGroupChild: {},
      isError: false,
      errMessage: ''
    }
  },
  mutations: {
    mutationGroupChild (state, payload) {
      state.listGroupChild = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailGroupChild = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    }
  },
  actions: {
    actionGroupChild (context, data) {
      axios.get(`${context.rootState.setURL}/v1/question-child`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationGroupChild', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/question-child/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/question-child`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/question-child/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getGroupChild (state) {
      return state.listGroupChild
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailGroupChild
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    }
  }
}

export default moduleGroupChild
