import axios from 'axios'
const moduleAccount = {
  namespaced: true,
  state: () => {
    return {
      listAccount: [],
      allListAccount: [],
      pagination: {},
      detailAccount: {},
      isError: false,
      errMessage: ''
    }
  },
  mutations: {
    mutationAccount (state, payload) {
      state.listAccount = payload
    },
    mutationAllAccount (state, payload) {
      state.allListAccount = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailAccount = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    }
  },
  actions: {
    actionAccount (context, data) {
      axios.get(`${context.rootState.setURL}/v1/account?page=${data.page}&ac_name=${data.ac_name}&limit=5`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationAccount', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionAllAccount (context) {
      axios.get(`${context.rootState.setURL}/v1/account`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationAllAccount', response.data.data)
      }).catch(() => {})
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/account/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/account/${data.ac_id}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/account/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getAccount (state) {
      return state.listAccount
    },
    getAllAccount (state) {
      return state.allListAccount
    },
    getPaginationAcc (state) {
      return state.pagination
    },
    getDetailAcc (state) {
      return state.detailAccount
    },
    isErrorAcc (state) {
      return state.isError
    },
    errMessageAcc (state) {
      return state.errMessage
    }
  }
}

export default moduleAccount
