import axios from 'axios'
const moduleSubtopic = {
  namespaced: true,
  state: () => {
    return {
      listSubtopic: [],
      pagination: {},
      detailSubtopic: {},
      isError: false,
      errMessage: '',
      listAll: [],
      isErrorAll: false
    }
  },
  mutations: {
    mutationSubtopic (state, payload) {
      state.listSubtopic = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailSubtopic = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setAll (state, payload) {
      state.listAll = payload
    },
    setIsErrorAll (state, payload) {
      state.isErrorAll = payload
    }
  },
  actions: {
    actionSubtopic (context, data) {
      axios.get(`${context.rootState.setURL}/v1/subtopic?topic_id=${data.topic_id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationSubtopic', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
      })
    },
    actionGetSubtopic (context, data) { // for topic_id
      axios.get(`${context.rootState.setURL}/v1/subtopic?topic_id=${data.topic_id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAll', response.data.data)
        context.commit('setIsErrorAll', false)
      }).catch(() => {
        context.commit('setIsErrorAll', true)
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/subtopic/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/subtopic`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    // Upload image to S3
    uploadImageSubtopic (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/subtopicImage`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/subtopic/${data.id}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/subtopic/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
    // deleteImage (context, id) {
    //   return new Promise((resolve, reject) => {
    //     axios.get(`${context.rootState.setURL}/v1/topicImage/${id}`, { headers: { token: context.rootState.auth.token } }).then((response) => {
    //       resolve(response)
    //     }).catch(() => {})
    //   })
    // },
    // deleteImageURL (context, data) {
    //   return new Promise((resolve, reject) => {
    //     axios.delete(`${context.rootState.setURL}/v1/topicImage`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
    //       resolve(response)
    //     }).catch(() => {})
    //   })
    // }
  },
  getters: {
    getSubtopic (state) {
      return state.listSubtopic
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailSubtopic
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    },
    getAll (state) {
      return state.listAll
    },
    isErrorAll (state) {
      return state.isErrorAll
    }
  }
}

export default moduleSubtopic
