import axios from 'axios'
const moduleClass = {
  namespaced: true,
  state: () => {
    return {
      listClass: [],
      pagination: {},
      detailClass: {},
      isError: false,
      errMessage: '',
      allClass: ''
    }
  },
  mutations: {
    mutationClass (state, payload) {
      state.listClass = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailClass = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setAllClass (state, payload) {
      state.allClass = payload
    }
  },
  actions: {
    actionClass (context, data) {
      axios.get(`${context.rootState.setURL}/v1/class?page=${data.page}&class_name=${data.class_name}&limit=${data.limit}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationClass', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actAllClass (context, data) {
      axios.get(`${context.rootState.setURL}/v1/class`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllClass', response.data.data)
      }).catch(() => {
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/class/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data[0])
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/class`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/class/${data.id_class}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/class/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    }
  },
  getters: {
    getClass (state) {
      return state.listClass
    },
    getPaginationClass (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailClass
    },
    isErrorClass (state) {
      return state.isError
    },
    errMessageClass (state) {
      return state.errMessage
    },
    getAllClass (state) {
      return state.allClass
    }
  }
}

export default moduleClass
