<template>
    <div>
      <!-- Edit Soal Child -->
      <b-modal
        id="modal-editChild"
        ref="modal-editChild"
        size="lg"
        hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Edit Soal</h3>
            <!-- Indikator -->
            <h5 class="fw-bold">Indikator</h5>
            <select
            v-model="getDetail.indicator_id"
            class="form-select form-select-sm mb-3 insertSelect" required
            >
              <option v-for="(el, i) in getIndicator" :key="i" :value="el.id">
                {{el.name}}
              </option>
            </select>
            <h5 class="fw-bold">Status</h5>
            <select
              v-model="getDetail.status"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                <option value="on-progress">On Progress</option>
                <option value="approved">Approved</option>
                <option value="published">Published</option>
            </select>
            <h5 class="fw-bold">Kelas</h5>
              <select
              v-model="getDetail.class_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option v-for="(el, i) in getAllClass" :key="i" :value="el.id_class">
                    {{el.class_name}}
                  </option>
              </select>
              <h5 class="fw-bold">Materi</h5>
              <select
              @change="subtopic"
              v-model="getDetail.topic_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option v-for="(el, i) in getAllTopic" :key="i" :value="el.id_topic">
                    {{el.topic_name}}
                  </option>
              </select>
              <!-- Subtopic -->
              <h5 class="fw-bold">Sub-materi</h5>
              <div v-if="isErrorSubtopic">
                <select v-model="getDetail.subtopic_id" class="form-select form-select-sm mb-3 insertSelect" required>
                  <option selected="selected" value="''">
                    Tambahkan sub-materi terlebih dahulu
                  </option>
                </select>
              </div>
              <div v-else>
                <select
                v-model="getDetail.subtopic_id"
                class="form-select form-select-sm mb-3 insertSelect" required
                >
                    <option value="" disabled>Pilih Sub-materi</option>
                    <option v-for="(el, i) in getSubtopic" :key="i" :value="el.id">
                      {{el.name}}
                    </option>
                </select>
              </div>
              <h5 class="fw-bold">Tipe Soal</h5>
              <select
              v-model="getDetail.qt_id"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="1">Esai</option>
                  <option value="2">Pilihan Berganda</option>
                  <option value="3">Jawaban Singkat</option>
              </select>
              <h5 class="fw-bold">Level Soal</h5>
              <select
              v-model="getDetail.id_qtn_level"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="" disabled>Pilih Level</option>
                  <option v-for="(el, i) in getQtnLevel" :key="i" :value="el.id">
                    {{el.qtn_level_name}}
                  </option>
              </select>
              <!-- Label Soal -->
              <h5 class="fw-bold">Label Soal</h5>
              <select
              v-model="getDetail.label"
              class="form-select form-select-sm mb-3 insertSelect" required
              >
                  <option value="tryout">Tryout</option>
                  <option value="exam">Exam</option>
                  <option value="daily_question">Daily Question</option>
              </select>
              <h5 class="fw-bold">Soal</h5>
              <!-- <textarea class="inputInsert" v-model="getDetail.question_text" cols="52" rows="5" placeholder="Inputkan soal"></textarea> -->
              <vue-editor :key="getDetail.qt_id" useCustomImageHandler @image-added="handleImageAdded" v-model="getDetail.question_text"></vue-editor>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="cancel()">Batalkan</button>
                  <button class="btn editBtn" @click="btnSave()">Simpan</button>
                </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
import imgConvert from '../helper/base64'
import axios from 'axios'
import { VueEditor } from 'vue2-editor'
export default {
  data () {
    return {
      serverURL: process.env.VUE_APP_URL,
      imgArr: []
    }
  },
  props: ['idQuestionEdit', 'idDetail'],
  mixins: [alert, imgConvert],
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      getId: 'auth/getId',
      getAllSubject: 'subject/getAllSubject',
      getAllClass: 'class/getAllClass',
      getAllCategory: 'category/getAllCategory',
      getAllTopic: 'topic/getAllTopic',
      getPagination: 'question/getPagination',
      getDetail: 'question/getDetail',
      getQtnLevel: 'questionLevel/getQtnLevel',
      getToken: 'auth/getToken',
      getQuestionImageId: 'questionImage/getQuestionImageId',
      errImg: 'questionImage/errImg',
      getSubtopic: 'subtopic/getSubtopic',
      isErrorSubtopic: 'subtopic/isError',
      getIndicator: 'indicator/getIndicator'
    })
  },
  methods: {
    ...mapActions({
      allSubject: 'subject/allSubject',
      actAllClass: 'class/actAllClass',
      actAllCategory: 'category/actAllCategory',
      actionAllTopic: 'topic/actionAllTopic',
      actionDetail: 'question/actionDetail',
      actionEdit: 'question/actionEdit',
      actionQtnLevel: 'questionLevel/actionQtnLevel',
      actionQuestionChild: 'question/actionQuestionChild',
      actionSubtopic: 'subtopic/actionSubtopic',
      actionIndicator: 'indicator/actionIndicator'
    }),
    indicator () {
      const data = {
        page: '',
        name: '',
        limit: ''
      }
      this.actionIndicator(data)
    },
    subtopic () {
      const data = {
        topic_id: this.getDetail.topic_id ? this.getDetail.topic_id : ''
      }
      this.actionSubtopic(data)
    },
    question () {
      this.actionQuestionChild(this.idQuestionEdit)
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      var formData = new FormData()
      formData.append('image', file)
      formData.append('question_id', this.idDetail)

      axios({
        url: `${this.serverURL}/v1/question-image`,
        method: 'POST',
        data: formData,
        headers: { token: this.getToken }
      })
        .then(result => {
          const url = result.data.data.image // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
          this.imgArr.push(url)
        })
        .catch({})
    },
    async cancel () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/question-imageURL`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then((response) => {})
            .catch({})
        }
        this.imgArr = []
      }
      this.hideModal()
      this.subtopic()
    },
    btnSave () {
      const data = {
        id_question: this.getDetail.id_question,
        qt_id: this.getDetail.qt_id,
        indicator_id: this.getDetail.indicator_id,
        question_text: this.getDetail.question_text,
        class_id: this.getDetail.class_id,
        topic_id: this.getDetail.topic_id,
        subtopic_id: this.getDetail.subtopic_id,
        ql_id: this.getDetail.id_qtn_level,
        label: this.getDetail.label,
        id_question_updater: this.getId,
        status: this.getDetail.status
      }

      this.actionEdit(data).then(async (response) => {
        if (this.getQuestionImageId && this.errImg === false) { // Get image from the previous question
          const qtn = this.getQuestionImageId
          const filtered = qtn.filter((el) => {
            return !this.getDetail.question_text.includes(el.image)
          })
          filtered.map(async (el) => {
            if (el.image) {
              const dataDelete = {
                image: el.image
              }
              await axios({
                url: `${this.serverURL}/v1/question-imageURL`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then((response) => {})
                .catch({})
            }
          })
        } else {}
        if (this.imgArr) { // new image from vue-editor / handleImageAdded
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.getDetail.question_text.includes(el)
          })
          filtered.map(async (el) => {
            if (el) {
              const dataDelete = {
                image: el
              }
              await axios({
                url: `${this.serverURL}/v1/question-imageURL`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then((response) => {})
                .catch({})
            }
          })
        }
        this.imgArr = []
        this.hideModal()
        this.subtopic()
        this.question()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editChild'].hide()
    }
  },
  mounted () {
    this.allSubject()
    this.actAllClass()
    this.actAllCategory()
    this.actionAllTopic()
    this.actionQtnLevel()
    this.subtopic()
    this.indicator()
  }
}
</script>
