<template>
  <div class="mx-4 mt-5">
    <select v-model="visibleComponent" class="form-select form-select-sm my-1 insertSelect" style="width: 150px;">
      <option value="1">Chart</option>
      <option value="2">Indikator</option>
      <option value="3">Level</option>
    </select>
    <!--  -->
    <Statistics v-if="visibleComponent == 1" />
    <ChildIndikator v-if="visibleComponent == 2" />
    <ChildLevel v-if="visibleComponent == 3" />
  </div>
</template>

<script>
import Statistics from './c-Statistics.vue'
import ChildIndikator from './c-indikator.vue'
import ChildLevel from './c-Level.vue'

export default {
  components: {
    Statistics,
    ChildIndikator,
    ChildLevel
  },
  data () {
    return {
      visibleComponent: 1
    }
  }
}
</script>
