<template>
    <div>
        <b-modal
        id="modal-editKeyword"
        ref="modal-editKeyword"
        hide-header-close hide-footer hide-header centered>
        <div class="mx-4">
            <h3 class="text-center my-2 mb-4 fw-bolder">Edit Keyword</h3>
            <h5 class="fw-bold">Keyword</h5>
            <textarea v-model="getDetail.keyword_text" class="inputInsert" cols="52" rows="3" placeholder="Inputkan keyword"></textarea>
              <div class="text-center mt-3">
                  <button class="btn cancelBtn" @click="hideModal()">Batalkan</button>
                  <button class="btn editBtn" @click="btnSave()">Simpan</button>
                </div>
        </div>
      </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
export default {
  data () {
    return {
    }
  },
  mixins: [alert],
  computed: {
    ...mapGetters({
      getDetail: 'keyword/getDetail'
    })
  },
  methods: {
    ...mapActions({
      actionKeyword: 'keyword/actionKeyword',
      actionEdit: 'keyword/actionEdit'
    }),
    btnSave () {
      const data = {
        id_keyword: this.getDetail.id_keyword,
        keyword_text: this.getDetail.keyword_text
      }

      this.actionEdit(data).then((response) => {
        this.hideModal()
        this.actionKeyword()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editKeyword'].hide()
    }
  }
}
</script>
