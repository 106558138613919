import axios from 'axios'
const moduleQuestion = {
  namespaced: true,
  state: () => {
    return {
      listQuestion: [],
      pagination: {},
      detailQuestion: {},
      isError: false,
      errMessage: '',
      totalData: 0,
      idQuestion: '',
      listQuestionChild: '',
      isErrorChild: false,
      inputSearch: [],
      totalAllQtn: 0,
      allTotalLiterasi: [],
      allTotalNumerasi: [],
      allTotalLiterasiByIndicator: [],
      allTotalNumerasiByIndicator: [],
      allTotalLiterasiByLevel: [],
      allTotalNumerasiByLevel: [],
      allTotalPerMonth: []
    }
  },
  mutations: {
    mutationQuestion (state, payload) {
      state.listQuestion = payload
    },
    setPagination (state, payload) {
      state.pagination = payload
    },
    setDetail (state, payload) {
      state.detailQuestion = payload
    },
    setIsError (state, payload) {
      state.isError = payload
    },
    setErrorMsg (state, payload) {
      state.errMessage = payload
    },
    setTotal (state, payload) {
      state.totalData = payload
    },
    setIdQuestion (state, payload) {
      state.idQuestion = payload
    },
    setQuestionChild (state, payload) {
      state.listQuestionChild = payload
    },
    setIsErrorChild (state, payload) {
      state.isErrorChild = payload
    },
    setInputSearch (state, payload) {
      state.inputSearch = payload
    },
    setTotalAllQtn (state, payload) {
      state.totalAllQtn = payload
    },
    setAllTotalLiterasi (state, payload) {
      state.allTotalLiterasi = payload
    },
    setAllTotalNumerasi (state, payload) {
      state.allTotalNumerasi = payload
    },
    setAllTotalLiterasiByIndicator (state, payload) {
      state.allTotalLiterasiByIndicator = payload
    },
    setAllTotalNumerasiByIndicator (state, payload) {
      state.allTotalNumerasiByIndicator = payload
    },
    setAllTotalLiterasiByLevel (state, payload) {
      state.allTotalLiterasiByLevel = payload
    },
    setAllTotalNumerasiByLevel (state, payload) {
      state.allTotalNumerasiByLevel = payload
    },
    setAllTotalPerMonth (state, payload) {
      state.allTotalPerMonth = payload
      window.dispatchEvent(new Event('resize'))
    }

  },
  actions: {
    actIdQuestion (context, data) {
      context.commit('setIdQuestion', data)
    },
    actionQuestion (context, data) {
      axios.get(`${context.rootState.setURL}/v1/questionParent?page=${data.page}&question_code=${data.question_code}&subject_id=${data.subject_id}&category_id=${data.category_id}&class_id=${data.class_id}&topic_id=${data.topic_id}&ql_id=${data.ql_id}&id_question_creator=${data.id_question_creator}&status=${data.status}&label=${data.label}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('mutationQuestion', response.data.data)
        context.commit('setPagination', response.data.pagination)
        context.commit('setTotal', response.data.pagination.totalData)
        context.commit('setIsError', false)
      }).catch(() => {
        context.commit('setIsError', true)
        context.commit('setErrorMsg', 'Data tidak ditemukan.')
        // console.clear()
      })
    },
    actionDetail (context, id) {
      axios.get(`${context.rootState.setURL}/v1/question/${id}`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setDetail', response.data.data)
      }).catch(() => {})
    },
    actionInsert (context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`${context.rootState.setURL}/v1/question`, data, { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionEdit (context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${context.rootState.setURL}/v1/question/${data.id_question}`, data,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response.data)
        }).catch((err) => {
          reject(err.response.data.message)
        })
      })
    },
    actionDelete (context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`${context.rootState.setURL}/v1/question/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          resolve(response)
        }).catch(() => {})
      })
    },
    actionQuestionChild (context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`${context.rootState.setURL}/v1/questionChild/${id}`,
          { headers: { token: context.rootState.auth.token } }).then((response) => {
          context.commit('setQuestionChild', response.data.data)
          context.commit('setIsErrorChild', false)
        }).catch(() => {
          context.commit('setIsErrorChild', true)
        })
      })
    },
    actionInputSeacrh (context, data) {
      context.commit('setInputSearch', data)
    },
    actionAllTotal (context) {
      axios.get(`${context.rootState.setURL}/v1/total`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllTotalLiterasi', response.data.data[1].category)
        context.commit('setAllTotalNumerasi', response.data.data[0].category)
        context.commit('setTotalAllQtn', response.data.data[0].categoryTotal[0])
      }).catch(() => {})
    },
    actionTotalByIndicator (context) {
      axios.get(`${context.rootState.setURL}/v1/total/indicator`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllTotalLiterasiByIndicator', response.data.data[1].category)
        context.commit('setAllTotalNumerasiByIndicator', response.data.data[0].category)
      }).catch(() => {})
    },
    actionTotalByLevel (context) {
      axios.get(`${context.rootState.setURL}/v1/total/level`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllTotalLiterasiByLevel', response.data.data[1].category)
        context.commit('setAllTotalNumerasiByLevel', response.data.data[0].category)
      }).catch(() => {})
    },
    actionTotalPerMonth (context) {
      axios.get(`${context.rootState.setURL}/v1/total/chart`,
        { headers: { token: context.rootState.auth.token } }).then((response) => {
        context.commit('setAllTotalPerMonth', response.data.data)
      }).catch(() => {})
    }
  },
  getters: {
    getQuestion (state) {
      return state.listQuestion
    },
    getPagination (state) {
      return state.pagination
    },
    getDetail (state) {
      return state.detailQuestion
    },
    isError (state) {
      return state.isError
    },
    errMessage (state) {
      return state.errMessage
    },
    getTotal (state) {
      return state.totalData
    },
    getIdQuestion (state) {
      return state.idQuestion
    },
    getQuestionChild (state) {
      return state.listQuestionChild
    },
    isErrorQuestionChild (state) {
      return state.isErrorChild
    },
    getInputSearch (state) {
      return state.inputSearch
    },
    getTotalAllQtn (state) {
      return state.totalAllQtn
    },
    getAllTotalLiterasi (state) {
      return state.allTotalLiterasi
    },
    getAllTotalNumerasi (state) {
      return state.allTotalNumerasi
    },
    getAllTotalLiterasiByIndicator (state) {
      return state.allTotalLiterasiByIndicator
    },
    getAllTotalNumerasiByIndicator (state) {
      return state.allTotalNumerasiByIndicator
    },
    getAllTotalLiterasiByLevel (state) {
      return state.allTotalLiterasiByLevel
    },
    getAllTotalNumerasiByLevel (state) {
      return state.allTotalNumerasiByLevel
    },
    getAllTotalPerMonth (state) {
      return state.allTotalPerMonth
    }
  }
}

export default moduleQuestion
