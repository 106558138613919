<template>
    <div>
    <b-modal
      id="modal-editSoalSNPMB"
      ref="modal-editSoalSNPMB"
      size="lg"
      hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
      <div class="mx-4">
          <h3 class="text-center my-2 mb-4 fw-bolder">Edit Soal</h3>
          <!-- Status -->
          <h5 class="fw-bold">Status</h5>
          <select
            v-model="getDetail.status"
            class="form-select form-select-sm mb-3 insertSelect" required
          >
              <option value="on-progress">On Progress</option>
              <option value="approved">Approved</option>
              <option value="published">Published</option>
          </select>
          <!-- Kategori -->
          <h5 class="fw-bold">Kategori</h5>
          <select
            v-model="getDetail.category_id"
            class="form-select form-select-sm mb-3 insertSelect" required>
            <option v-for="(el, i) in getCategorySNPMB" :key="i" :value="el.id_category">
              {{el.category_name}}
            </option>
          </select>
            <!-- Tipe Soal -->
            <h5 class="fw-bold">Tipe Soal</h5>
            <select
              v-model="getDetail.qt_id"
              class="form-select form-select-sm mb-3 insertSelect" required
            >
              <option value="2">Pilihan Berganda</option>
              <option value="5">Benar / Salah</option>
            </select>
            <!-- Label Soal -->
            <h5 class="fw-bold">Label Soal</h5>
            <select
            v-model="getDetail.label"
            class="form-select form-select-sm mb-3 insertSelect" required
            >
                <option value="tryout">Tryout</option>
                <option value="exam">Exam</option>
                <option value="daily_question">Daily Question</option>
                <option value="example_question">Example Question</option>
            </select>
            <!-- Free Trial / Premium -->
            <h5 class="fw-bold">Fitur</h5>
            <input type="radio" id="free_trial" value="true" v-model="getDetail.free_trial" />
            <label for="free_trial" class="ms-2">Free trial</label> <br>

            <input type="radio" id="premium" value="false" v-model="getDetail.free_trial" />
            <label for="premium" class="ms-2">Premium</label>
            <!--  -->
            <h5 class="fw-bold">Soal</h5>
            <vue-editor :key="getDetail.qt_id" useCustomImageHandler @image-added="handleImageAdded" v-model="getDetail.question_text"></vue-editor>
            <div class="text-center mt-3">
              <button class="btn cancelBtn" @click="cancel()">Batalkan</button>
              <button class="btn editBtn" @click="btnSave()">Simpan</button>
            </div>
      </div>
    </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import alert from '../helper/alert'
import imgConvert from '../helper/base64'
import { VueEditor } from 'vue2-editor'
import axios from 'axios'

export default {
  data () {
    return {
      serverURL: process.env.VUE_APP_URL,
      imgArr: []
    }
  },
  components: {
    VueEditor
  },
  props: ['id'],
  mixins: [alert, imgConvert],
  computed: {
    ...mapGetters({
      getDetail: 'questionSNPMB/getDetail',
      getCategorySNPMB: 'category/getCategorySNPMB',
      getToken: 'auth/getToken',
      getId: 'auth/getId',
      getQuestionImageId: 'questionImage/getQuestionImageId',
      errImg: 'questionImage/errImg',
      getPaginationQuestionSNPMB: 'questionSNPMB/getPaginationQuestionSNPMB',
      getInputSearch: 'questionSNPMB/getInputSearch',
      getFreeTrial: 'freeTrial/getFreeTrial'
    })
  },
  methods: {
    ...mapActions({
      actionCategorySNPMB: 'category/actionCategorySNPMB',
      actionEdit: 'questionSNPMB/actionEdit',
      actionQuestionSNPMB: 'questionSNPMB/actionQuestionSNPMB',
      actionQuestionImageId: 'questionImage/actionQuestionImageId',
      actionInsertFreeTrial: 'freeTrial/actionInsert',
      actionDeleteFreeTrial: 'freeTrial/actionDelete'
    }),
    question () {
      const data = {
        page: this.getPaginationQuestionSNPMB.page ? this.getPaginationQuestionSNPMB.page : 1,
        limit: 5,
        category_id: this.getInputSearch.category_id ? this.getInputSearch.category_id : '',
        status: this.getInputSearch.status ? this.getInputSearch.status : '',
        label: this.getInputSearch.label ? this.getInputSearch.label : '',
        id_question_creator: this.getInputSearch.id_question_creator ? this.getInputSearch.id_question_creator : ''
      }
      this.actionQuestionSNPMB(data)
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      var formData = new FormData()
      formData.append('image', file)
      formData.append('question_id', this.id)

      axios({
        url: `${this.serverURL}/v1/question-image`,
        method: 'POST',
        data: formData,
        headers: { token: this.getToken }
      })
        .then(result => {
          const url = result.data.data.image // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
          this.imgArr.push(url)
        })
        .catch({})
    },
    async cancel () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/question-imageURL`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then((response) => {})
            .catch({})
        }
        this.imgArr = []
      } else {}
      this.hideModal()
    },
    btnSave () {
      const data = {
        id_question: this.getDetail.id_question,
        qt_id: this.getDetail.qt_id,
        category_id: this.getDetail.category_id,
        question_text: this.getDetail.question_text,
        label: this.getDetail.label,
        id_question_updater: this.getId,
        status: this.getDetail.status
      }

      this.actionEdit(data).then(async (response) => {
        // * Free Trial
        if (this.getDetail.free_trial === true || this.getDetail.free_trial === 'true') {
          // ! cek data db free trial, jika false masukan ke db
          if (this.getFreeTrial.free_trial === false || this.getFreeTrial.free_trial === 'false') {
            const dataFreeTrial = {
              item_id: this.getFreeTrial.id_question,
              type: 'question'
            }
            await this.actionInsertFreeTrial(dataFreeTrial).then(() => {}).catch(() => {})
          }
        } else { // di edit false
          if (this.getFreeTrial.free_trial === true || this.getFreeTrial.free_trial === 'true') {
            // ! jika edit menjadi false tp di db sebelumnya free trial (true) maka hapus dari db
            await this.actionDeleteFreeTrial(this.getFreeTrial.id_question).then(() => {}).catch(() => {})
          }
        }
        if (this.getQuestionImageId && this.errImg === false) { // errImg
          const qtn = this.getQuestionImageId
          const filtered = qtn.filter((el) => {
            return !this.getDetail.question_text.includes(el.image)
          })
          filtered.map(async (el) => {
            if (el.image) {
              const dataDelete = {
                image: el.image // bandingin data yg ada di Arr dengan data yang ada di question form.question_text
              }
              await axios({
                url: `${this.serverURL}/v1/question-imageURL`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then((response) => {})
                .catch(() => {})
            }
          })
        } else {}
        if (this.imgArr) { // new image from vue-editor / handleImageAdded
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.getDetail.question_text.includes(el)
          })
          filtered.map(async (el) => {
            if (el) {
              const dataDelete = {
                image: el // bandingin data yg ada di Arr dengan data yang ada di question form.question_text
              }
              await axios({
                url: `${this.serverURL}/v1/question-imageURL`,
                method: 'DELETE',
                data: dataDelete,
                headers: { token: this.getToken }
              })
                .then((response) => {})
                .catch({})
            }
          })
        }
        this.imgArr = []
        this.hideModal()
        this.question()
        this.ToastSuccess('Data berhasil diperbaharui.')
      }).catch(() => {})
    },
    hideModal () {
      this.$refs['modal-editSoalSNPMB'].hide()
    }
  },
  mounted () {
    this.actionCategorySNPMB()
    this.question()
  }
}
</script>
