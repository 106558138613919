<template>
    <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Literasi</h3>
        <div v-for="(item, i) in getAllTotalLiterasiByLevel" :key="'literasi'+i">
          <div class="mt-5 mb-2">
            <u class="fw-bold">{{ item.category_name }}</u>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Materi</th>
                <th scope="col">Mudah</th>
                <th scope="col">Sedang</th>
                <th scope="col">Sulit</th>
              </tr>
            </thead>
            <tbody v-for="topics in item.topic" :key="topics.id_topic">
              <tr>
                <td>{{ topics.topic_name }}</td>
                <td v-for="levels in topics.level" :key="levels.id">
                  {{levels.total}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- ================================================================== -->
        <h3 class="text-center mt-5 mb-4 fw-bolder">Numerasi</h3>
        <div v-for="(item, i) in getAllTotalNumerasiByLevel" :key="'numerasi'+i">
          <div class="mt-5 mb-2">
            <u class="fw-bold">{{ item.category_name }}</u>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Materi</th>
                <th scope="col">Mudah</th>
                <th scope="col">Sedang</th>
                <th scope="col">Sulit</th>
              </tr>
            </thead>
            <tbody v-for="topics in item.topic" :key="topics.id_topic">
              <tr>
                <td>{{ topics.topic_name }}</td>
                <td v-for="levels in topics.level" :key="levels.id">
                  {{levels.total}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getAllTotalLiterasiByLevel: 'question/getAllTotalLiterasiByLevel',
      getAllTotalNumerasiByLevel: 'question/getAllTotalNumerasiByLevel'
    })
  },
  methods: {
    ...mapActions({
      actionTotalByLevel: 'question/actionTotalByLevel'
    }),
    level () {
      this.actionTotalByLevel()
    }
  },
  mounted () {
    this.level()
  }
}

</script>
