import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import Soal from '../views/Soal.vue'
import Materi from '../views/Materi.vue'
import Pembahasan from '../views/Pembahasan.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Subject from '../views/Subject.vue'
import Kelas from '../views/Kelas.vue'
import Kategori from '../views/Category.vue'
import Password from '../views/Forgot-Password.vue'
import Group from '../views/Group.vue'
import Banner from '../views/Banner.vue'
import Indicator from '../views/Indicator.vue'
import Subtopic from '../views/Sub-materi.vue'
import Chart from '../views/Chart.vue'
import SoalSNPMB from '../views/SNPMB-Soal.vue'
import KategoriSNPMB from '../views/SNPMB-Kategori.vue'
import MateriSNPMB from '../views/SNPMB-Materi.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: true } // for authentication
  },
  {
    path: '/soal',
    name: 'Soal',
    component: Soal,
    meta: { auth: true }
  },
  {
    path: '/snpmb/soal',
    name: 'SoalSNPMB',
    component: SoalSNPMB,
    meta: { auth: true }
  },
  {
    path: '/snpmb/kategori',
    name: 'KategoriSNPMB',
    component: KategoriSNPMB,
    meta: { auth: true }
  },
  {
    path: '/banner',
    name: 'Banner',
    component: Banner,
    meta: { auth: true }
  },
  {
    path: '/sub-materi/:id',
    name: 'Subtopic',
    component: Subtopic,
    meta: { auth: true }
  },
  {
    path: '/materi',
    name: 'Materi',
    component: Materi,
    meta: { auth: true }
  },
  {
    path: '/snpmb/materi/:id',
    name: 'MateriSNPMB',
    component: MateriSNPMB,
    meta: { auth: true }
  },
  {
    path: '/pembahasan/:idSoal',
    name: 'Pembahasan',
    component: Pembahasan,
    meta: { auth: true }
  },
  {
    path: '/subjek',
    name: 'Subjek',
    component: Subject,
    meta: { auth: true }
  },
  {
    path: '/kelas',
    name: 'Kelas',
    component: Kelas,
    meta: { auth: true }
  },
  {
    path: '/kategori',
    name: 'Kategori',
    component: Kategori,
    meta: { auth: true }
  },
  {
    path: '/indikator',
    name: 'Indicator',
    component: Indicator,
    meta: { auth: true }
  },
  {
    path: '/chart',
    name: 'Chart',
    component: Chart,
    meta: { auth: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { auth: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false }
  },
  {
    path: '/forgot-password',
    name: 'Password',
    component: Password,
    meta: { auth: false }
  },
  {
    path: '/group/:id',
    name: 'Group',
    component: Group,
    meta: { auth: true }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: { auth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].meta.auth === true) {
    if (store.getters['auth/getToken']) {
      next()
    } else {
      alert('Login required')
      next({
        path: '/login'
      })
    }
  } else {
    next()
  }
})

export default router
