<template>
  <b-modal
    id="modal-addQuestionSNPMB"
    ref="modal-addQuestionSNPMB"
    size="lg"
    hide-header-close hide-footer hide-header scrollable no-close-on-backdrop>
    <div class="mx-4">
        <h3 class="text-center my-2 mb-4 fw-bolder">Tambah Soal</h3>
        <!-- Kategori -->
        <h5 class="fw-bold">Kategori</h5>
        <select
          v-model="form.category_id"
          class="form-select form-select-sm mb-3 insertSelect" required
        >
          <option value="" disabled>Pilih Kategori</option>
          <option v-for="(el, i) in getCategorySNPMB" :key="i" :value="el.id_category">
            {{el.category_name}}
          </option>
        </select>
        <!-- Tipe Soal -->
        <h5 class="fw-bold">Tipe Soal</h5>
        <select
          v-model="form.qt_id"
          class="form-select form-select-sm mb-3 insertSelect" required
        >
            <option value="" disabled>Pilih Tipe Soal</option>
            <option value="2">Pilihan Berganda</option>
            <option value="5">Benar / Salah</option>
        </select>
        <!-- Label Soal -->
        <h5 class="fw-bold">Label Soal</h5>
        <select
        v-model="form.label"
        class="form-select form-select-sm mb-3 insertSelect" required
        >
            <option value="" disabled>Pilih Label</option>
            <option value="tryout">Tryout</option>
            <option value="exam">Exam</option>
            <option value="daily_question">Daily Question</option>
            <option value="example_question">Example Question</option>
        </select>
        <!-- Free Trial / Premium -->
        <h5 class="fw-bold">Fitur</h5>
        <input type="radio" id="free_trial" value="free_trial" v-model="feature" />
        <label for="free_trial" class="ms-2">Free trial</label> <br>

        <input type="radio" id="premium" value="premium" v-model="feature" />
        <label for="premium" class="ms-2">Premium</label>
        <!--  -->
        <h5 class="fw-bold mt-2">Soal</h5>
        <vue-editor :key="form.qt_id" useCustomImageHandler @image-added="handleImageAdded" v-model="form.question_text"></vue-editor>
        <div class="text-center mt-3">
          <button class="btn cancelBtn" @click="cancel()">Batalkan</button>
          <button class="btn editBtn" @click="btnInsert()">Simpan</button>
        </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
import axios from 'axios'
import alert from '../helper/alert'
import mathJax from '../helper/mathjax'
import imgConvert from '../helper/base64'

export default {
  data () {
    return {
      form: {
        qt_id: '',
        question_text: '',
        category_id: '',
        label: ''
      },
      feature: '',
      imgArr: [],
      serverURL: process.env.VUE_APP_URL
    }
  },
  mixins: [alert, mathJax, imgConvert],
  components: {
    VueEditor
  },
  computed: {
    ...mapGetters({
      getCategorySNPMB: 'category/getCategorySNPMB',
      getToken: 'auth/getToken',
      getId: 'auth/getId',
      getPaginationQuestionSNPMB: 'questionSNPMB/getPaginationQuestionSNPMB',
      getInputSearch: 'questionSNPMB/getInputSearch',
      getQuestionSNPMB: 'questionSNPMB/getQuestionSNPMB'
    })
  },
  methods: {
    ...mapActions({
      actionCategorySNPMB: 'category/actionCategorySNPMB',
      actionInsert: 'questionSNPMB/actionInsert',
      actionEditQuestionID: 'questionImage/actionEditQuestionID',
      actionQuestionSNPMB: 'questionSNPMB/actionQuestionSNPMB',
      actionInsertFreeTrial: 'freeTrial/actionInsert'
    }),
    question () {
      const data = {
        page: this.getPaginationQuestionSNPMB.page ? this.getPaginationQuestionSNPMB.page : 1,
        limit: 5,
        category_id: this.getInputSearch.category_id ? this.getInputSearch.category_id : '',
        status: this.getInputSearch.status ? this.getInputSearch.status : '',
        label: this.getInputSearch.label ? this.getInputSearch.label : '',
        id_question_creator: this.getInputSearch.id_question_creator ? this.getInputSearch.id_question_creator : ''
      }
      this.actionQuestionSNPMB(data)
    },
    hideModal () {
      this.$refs['modal-addQuestionSNPMB'].hide()
      this.form = {
        qt_id: '',
        question_text: '',
        category_id: '',
        label: ''
      }
      this.feature = ''
    },
    async handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      file = await this.getBase64(file)
      var formData = new FormData()
      formData.append('image', file)
      formData.append('question_id', '0')

      axios({
        url: `${this.serverURL}/v1/question-image`,
        method: 'POST',
        data: formData,
        headers: { token: this.getToken }
      })
        .then(result => {
          const url = result.data.data.image // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
          this.imgArr.push(url)
        })
        .catch({})
    },
    async cancel () {
      if (this.imgArr) {
        const images = this.imgArr
        for (let i = 0; i < images.length; i++) {
          const data = {
            image: images[i]
          }
          await axios({
            url: `${this.serverURL}/v1/question-imageURL`,
            method: 'DELETE',
            data: data,
            headers: { token: this.getToken }
          })
            .then({})
            .catch({})
        }
        this.imgArr = []
      } else {}
      this.hideModal()
      this.actionCategorySNPMB()
    },
    btnInsert () { // delete dari imgArr
      const data = {
        qt_id: this.form.qt_id,
        question_text: this.form.question_text,
        category_id: this.form.category_id,
        label: this.form.label,
        id_question_creator: this.getId
      }
      this.actionInsert(data).then(async (response) => {
        // Edit question_id in image
        for (let i = 0; i < this.imgArr.length; i++) {
          const data = {
            question_id: response.data[0].id_question,
            image: this.imgArr[i]
          }
          await this.actionEditQuestionID(data).then(async (response) => {}).catch({})
          const qtn = this.imgArr
          const filtered = qtn.filter((el) => {
            return !this.form.question_text.includes(el)
          })
          if (filtered[i]) {
            const dataDelete = {
              image: filtered[i] // bandingin data yg ada di Arr dengan data yang ada di question form.question_text
            }
            await axios({
              url: `${this.serverURL}/v1/question-imageURL`,
              method: 'DELETE',
              data: dataDelete,
              headers: { token: this.getToken }
            })
              .then({})
              .catch({})
          }
        }
        if (this.feature === 'free_trial') {
          const dataFreeTrial = {
            item_id: response.data[0].id_question,
            type: 'question'
          }
          this.actionInsertFreeTrial(dataFreeTrial).then(async (response) => {}).catch(() => {})
        }
        this.question()
        this.hideModal()
        this.imgArr = []
        this.ToastSuccess('Data berhasil ditambahkan.')
      }).catch((err) => {
        this.AlertError(err)
      })
    }
  },
  watch: {
    getQuestionSNPMB () {
      this.$nextTick().then(() => {
        this.renderMathJax()
      })
    }
  },
  mounted () {
    this.actionCategorySNPMB()
  }
}
</script>
